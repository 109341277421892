import { Form, PageHeader } from 'antd'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import Loader from '../../components/Loader'
import FormBlock from '../../components/forms/FormBlock'
import FormWrap from '../../components/forms/FormWrap'
import { getProductType, updateProductType } from '../../network/ProductTypes'
import { ProductTypesForm } from './ProductTypes'
import { useAuth } from '../../hooks/useAuth'

export default function EditProductType({ history }) {
  const { user } = useAuth()
  const { productTypeId } = useParams()
  const [form] = Form.useForm()

  const { data, isLoading, isError } = useQuery(
    ['getProductType', productTypeId],
    getProductType,
    {
      onSuccess: (data) => {
        form.setFieldsValue(data)
      },
      refetchOnWindowFocus: false,
    }
  )

  return (
    <>
      <PageHeader
        className={'site-page-header'}
        title={'Edit Product Family'}
        onBack={() => history.push('/product-types')}
        style={{ backgroundColor: '#fff' }}
      />
      {isLoading ? (
        <Loader />
      ) : isError ? (
        <Loader isError={true} />
      ) : (
        <FormWrap
          id={productTypeId}
          title={'update_product_types'}
          request={updateProductType}
          cancelText={'Cancel'}
          okText={'Save'}
          data={data}
          onSuccess={() => history.push('/product-types')}
          onCancel={() => history.push('/product-types')}
          cleanBeforeSending={(oldData) => {
            if (oldData.image) {
              let main = oldData.image.fileList
                ? oldData.image?.fileList
                : oldData.image
              if (!Array.isArray(main)) {
                main = [main]
              }
              const newPic = main.map((file) =>
                file.response ? file.response : file
              )

              oldData.image = newPic.length ? newPic[0] : {}
            } else {
              oldData.image = {}
            }
            return oldData
          }}
          invalidate={['getProductTypes']}
        >
          <div className="section-from-vertical">
            <FormBlock form={form} formTemplate={ProductTypesForm} />
          </div>
        </FormWrap>
      )}
    </>
  )
}
