import { Spin } from 'antd'

const Loader = ({ style }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 200,
      ...style,
    }}
  >
    <Spin size="large" />
  </div>
)

export default Loader
