import { Form, PageHeader } from 'antd'
import FormBlock from '../../components/forms/FormBlock'
import {
  createProductTag,
  getProductTag,
  updateProductTag,
} from '../../network/Tags'
import FormWrap from '../../components/forms/FormWrap'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import Loader from '../../components/Loader'
import { useAuth } from '../../hooks/useAuth'

export default function EditProductTag({ history }) {
  const { user } = useAuth()
  let { tagId } = useParams()
  const [form] = Form.useForm()

  const { data, isLoading } = useQuery(
    ['getProductTag', { id: tagId }],
    getProductTag,
    {
      onSuccess: (data) => {
        form.setFieldsValue(data)
      },
      refetchOnWindowFocus: false,
    }
  )

  const ProductTagForm = {
    title: 'Tag',
    key: [],
    sections: [
      {
        fields: [
          {
            label: 'Name',
            isRequired: true,
            key: ['name', 'en'],
            pos: {
              col: 1,
              row: 1,
              size: 1,
            },
          },
          // Display only if the store is not a franchise
          ...(!user.franchiseStore
            ? [
                {
                  label: 'Name (Chinese)',
                  isRequired: true,
                  key: ['name', 'zh'],
                  pos: {
                    col: 1,
                    row: 2,
                    size: 1,
                  },
                },
              ]
            : []),
          {
            label: 'Family',
            isRequired: true,
            key: ['genre'],
            pos: {
              col: 1,
              row: 3,
              size: 1,
            },
          },
        ],
      },
    ],
  }

  return (
    <>
      <PageHeader
        className={'site-page-header'}
        title={'Edit Tag'}
        subTitle={'Edit a tag'}
        onBack={() => history.push('/product-tags')}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <FormWrap
          title="edit_tag"
          id={tagId}
          form={form}
          data={data}
          request={updateProductTag}
          cancelText={'Cancel'}
          okText={'Save'}
          onSuccess={() => history.push('/product-tags')}
          onCancel={() => history.push('/product-tags')}
        >
          <div className="section-from-vertical">
            <FormBlock form={form} formTemplate={ProductTagForm} />
          </div>
        </FormWrap>
      )}
    </>
  )
}
