import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Card, Space, Statistic, Table, Typography } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'

import TableFilters from './TableFilters'
import Error from '../Error'
import useSearchParams from '../../hooks/useQuery'

const { Title } = Typography

const TableBlock = ({
  columns,
  filters,
  request,
  name,
  stats,
  statsRequest,
  summary,
  otherParams,
  otherFilters,
  rowSelection,
  components,
  infinite,
  onRow,
  className,
  elemId,
  pageId,
  pageSize,
}) => {
  let history = useHistory()
  let location = useLocation()
  const params = useSearchParams()

  const [range, setRange] = useState([0, pageSize - 1 || 9])
  const [sort, setSort] = useState(['createdAt', 'DESC'])
  const [filter, setFilter] = useState({})
  const [tabFilter, setTabFilter] = useState({})
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const requestKeys = [
    name,
    {
      sort,
      range: infinite ? null : range,
      filter: { ...otherFilters, ...filter },
      tabFilter,
      ...otherParams,
    },
  ]

  const customers = useQuery(requestKeys, request)

  // To store the list after getting data
  var dataList = []
  if (customers.data) {
    if (customers.data.purchaseOrderItems) {
      dataList = customers.data.purchaseOrderItems
    } else {
      dataList = customers.data.list
    }
  }

  const handleTableChange = (pagination, filters, sorter) => {
    //formating the sort to match the API
    let currentSort =
      sorter.order === undefined
        ? ['createdAt', 'DESC']
        : [sorter.columnKey, sorter.order === 'descend' ? 'DESC' : 'ASC']

    //checking if sorter are same as previous to reset the page to the first one
    const size = pageSize || 10
    if (
      currentSort.length === sort.length &&
      currentSort[0] === sort[0] &&
      currentSort[1] === sort[1]
    ) {
      setRange([
        pagination.current * size - size,
        pagination.current * size - 1,
      ])
    } else {
      setSort(currentSort)
      setRange([0, size - 1])
    }

    history.push({
      search: `?page=${pagination.current}`,
    })
  }

  // useEffect(() => {
  //   params.set('page', String(range[0] / (pageSize || 10) + 1));
  // }, [params, pageSize, range]);

  useEffect(() => {
    const currentPage = params.get('page')
    if (currentPage) {
      setRange([
        (currentPage - 1) * (pageSize || 10),
        currentPage * (pageSize || 10) - 1,
      ])
    }
  }, [])

  return (
    <div style={{ backgroundColor: '#fff', height: 'fit-content' }}>
      {filters && (
        <TableFilters
          selectedRowKeys={selectedRowKeys}
          requestKeys={requestKeys}
          resetSelected={() => setSelectedRowKeys([])}
          elemId={elemId}
          config={filters(
            history,
            selectedRowKeys.length ? selectedRowKeys : false,
            customers.isLoading,
            pageId,
            customers.data?.list,
            location
          )}
          dataList={dataList}
          onFiltersChange={(filters) => {
            setFilter(filters)
            setRange([0, pageSize - 1])
          }}
          onTabChange={(e) => {
            setTabFilter(e)
            setRange([0, pageSize - 1])
          }}
        />
      )}
      {stats && (
        <Stats
          statSections={stats()}
          statsRequest={statsRequest}
          name={name}
          filter={filter}
        />
      )}
      {customers.isError ? (
        <Error retry={() => customers.refetch()} />
      ) : (
        <Table
          style={{
            padding: '0px 24px',
          }}
          className={'tableWrap ' + className ? className : ''}
          // dataSource={customers.data?.list}
          dataSource={dataList}
          rowKey="id"
          onRow={onRow}
          rowSelection={
            rowSelection
              ? {
                  type: 'checkbox',
                  selectedRowKeys,
                  onChange: (selectedRowKeys) =>
                    setSelectedRowKeys(selectedRowKeys),
                  getCheckboxProps: rowSelection,
                }
              : false
          }
          components={components}
          columns={columns(history, requestKeys, pageId)}
          loading={customers.isLoading}
          onChange={handleTableChange}
          pagination={
            infinite
              ? {
                  hideOnSinglePage: true,
                  pageSize: customers?.data?.list?.length,
                }
              : {
                  total: customers.data?.range,
                  pageSize: pageSize || 10,
                  showSizeChanger: false,
                  current: range[0] / (pageSize || 10) + 1,
                }
          }
          summary={summary}
        />
      )}
    </div>
  )
}

const Stats = ({ statSections, statsRequest, name, filter }) => {
  const stats = useQuery([name + 'Stats', { filter }], statsRequest)

  return (
    <Space
      style={{
        padding: '5px 24px',
        marginBottom: 7,
      }}
      size={32}
    >
      {statSections.map((section) => (
        <Space direction="vertical" size={0}>
          {section.title && (
            <Title style={{ marginBottom: 7 }} level={5}>
              {section.title}
            </Title>
          )}
          {section.isWrap ? (
            <Card>
              {section.stats.map((stat) => (
                <Statistic
                  title={stat.label}
                  value={stats.isLoading ? '' : stat.getValue(stats.data)}
                  loading={stats.isLoading}
                  //coloring the color depending the value
                  valueStyle={
                    stats.isLoading
                      ? {}
                      : {
                          color:
                            parseFloat(stat.getValue(stats.data)) > 90
                              ? '#3f8600'
                              : parseFloat(stat.getValue(stats.data)) > 51
                              ? '#FAAD14'
                              : '#FF4D4F',
                        }
                  }
                  suffix={stat.suffix}
                  precision={2}
                />
              ))}
            </Card>
          ) : (
            <Space size={32}>
              {section.stats.map((stat) => (
                <Statistic
                  title={stat.label}
                  value={stats.isLoading ? '' : stat.getValue(stats.data)}
                  loading={stats.isLoading}
                  suffix={stat.suffix}
                  precision={0}
                />
              ))}
            </Space>
          )}
        </Space>
      ))}
    </Space>
  )
}

export default TableBlock
