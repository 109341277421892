import { Badge, Button, PageHeader } from 'antd'
import moment from 'moment'

import TableBlock from '../../components/tables/TableBlock'
import { getCompanyOrders } from '../../network/B2B'
import { ROLE_PERMISSIONS } from '../../constants/common'
import { useAuth } from '../../hooks/useAuth'

const CompaniesOrders = () => {
  const { user } = useAuth()
  const permissions = ROLE_PERMISSIONS[user.role]
    ? ROLE_PERMISSIONS[user.role]
    : []

  const filters = (history) => ({
    fields: [
      // {
      //   type: 'text',
      //   label: 'Company',
      //   key: 'company',
      //   // noRegex: true,
      // },
    ],
    actions: [
      {
        label: '+ Create an order',
        isActive: permissions.includes('new_companies_orders'),
        action: () =>
          history.push(`/companies/orders/create/${user.currentStore}`),
      },
    ],
  })

  const columns = (history) => [
    {
      title: 'Order ID',
      key: 'orderId',
      dataIndex: 'orderID',
    },
    {
      title: 'Company',
      key: 'company',
      dataIndex: 'company',
      render: (e) => e.name,
      sorter: true,
    },
    {
      title: 'Date',
      key: 'date',
      render: (e) => moment(e.createdAt).format('YYYY-MM-DD'),
      sorter: true,
    },
    {
      title: 'Scanned',
      render: (e) =>
        `${
          e.customers.filter(
            (c) => c.scanStatus === 'success' || c.scanStatus === 'warning'
          ).length
        }/${e.customers.length}`,
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (e) => {
        const statuses = {
          complete: <Badge status="success" text="Complete" />,
          pending: <Badge status="error" text="Pending" />,
        }

        return statuses[e]
      },
      sorter: true,
    },
    {
      title: '',
      key: 'action',
      width: 25,
      render: (e) => {
        return (
          <Button
            onClick={() => history.push(`/companies/orders/${e.id}`)}
            type="primary"
            disabled={
              permissions.includes('edit_companies_orders') ? false : true
            }
          >
            Edit
          </Button>
        )
      },
    },
  ]
  return (
    <>
      <PageHeader className="site-page-header" title="Companies Orders" />
      <TableBlock
        columns={columns}
        filters={filters}
        name={'getCompaniesOrders'}
        request={getCompanyOrders}
        infinite
        otherFilters={
          user.franchiseStore || !permissions.includes('can-see-franchises')
            ? { store: user.currentStore }
            : {}
        }
      />
    </>
  )
}

export default CompaniesOrders
