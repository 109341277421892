export const ProductsMediaForm = {
  title: 'Media',
  key: [],
  sections: [
    {
      title: 'Main picture',
      columns: 1,
      fields: [
        {
          //   label: 'Add more pictures or videos',
          type: 'upload',
          label: '375*513px (1:1.368 ratio)',
          requestURI: '/files/product-main-picture',
          storageURI: '/storage/product-main-picture',
          key: ['mainPicture'],
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
      ],
    },
    {
      title: 'Other pictures',
      columns: 2,
      fields: [
        {
          //   label: 'Add more pictures or videos',
          type: 'multiupload',
          label: '375*513px (1:1.368 ratio)',
          requestURI: '/files/product-other-picture',
          storageURI: '/storage/product-other-picture',
          key: ['otherPictures'],
          pos: {
            col: 1,
            row: 2,
            size: 1,
          },
        },
      ],
    },
  ],
}

export const ProductsOptionsForm = {
  title: 'Options',
  sections: [
    {
      canAdd: true,
      key: ['options'],
      addSuffix: 'number',
      columns: 4,
      noDivider: true,
      title: 'Option',
      deleteBtn: { label: 'Delete', style: 'title' },
      addBtn: { label: 'Add option', style: 'primary' },
      fields: [
        {
          label: 'Option English Name',
          key: ['name', 'en'],
          isRequired: true,
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
        {
          label: 'Option Chinese Name',
          key: ['name', 'zh'],
          hideIfFranchiseStore: true,
          isRequired: true,
          pos: {
            col: 2,
            row: 1,
            size: 1,
          },
        },
      ],
      sections: [
        {
          canAdd: true,
          key: ['subOptions'],
          columns: 4,
          noDivider: true,
          deleteBtn: {
            label: 'Delete',
            style: 'line',
          },
          isRequired: true,
          addBtn: { label: 'Add sub-option  ', style: 'dashed' },
          fields: [
            {
              key: ['name', 'en'],
              placeholder: 'Sub option English name',
              isRequired: true,
              pos: {
                col: 1,
                row: 1,
                size: 1,
              },
            },
            {
              key: ['name', 'zh'],
              placeholder: 'Sub option Chinese name',
              hideIfFranchiseStore: true,
              isRequired: true,
              pos: {
                col: 2,
                row: 1,
                size: 1,
              },
            },
            {
              key: ['ref'],
              placeholder: 'Ref ID',
              isRequired: true,
              pos: {
                col: 3,
                row: 1,
                size: 1,
              },
            },
          ],
        },
      ],
    },
  ],
}

export const ProductsPublicationForm = {
  key: [],
  sections: [
    {
      columns: 3,
      fields: [
        {
          label: 'Publication',
          type: 'select',
          key: ['status'],
          options: [
            { key: 'published', label: 'Published' },
            { key: 'unpublished', label: 'Unpublished' },
            // { key: 'company', label: 'Company' },
            { key: 'discontinued', label: 'Discontinued' },
          ],
          isRequired: true,
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
        {
          label: 'Target Audience: ',
          type: 'select',
          key: ['targetAudience'],
          options: [
            { key: 'public', label: 'Public' },
            { key: 'company', label: 'Company' },
          ],
          isRequired: true,
          pos: {
            col: 2,
            row: 1,
            size: 1,
          },
        },
      ],
    },
  ],
}
