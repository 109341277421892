import React, { useState, useEffect } from 'react'
import { Form, PageHeader, Checkbox, Button, Card, message } from 'antd'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import Error from '../../components/Error'
import Loader from '../../components/Loader'
import { ROLE_PERMISSIONS } from '../../constants/common'
import { useAuth } from '../../hooks/useAuth'
import { getStoreDetails, updateStore } from '../../network/Stores'

export default function EditProductFranchises({ history }) {
  const { user } = useAuth()
  const permissions = ROLE_PERMISSIONS[user.role]
    ? ROLE_PERMISSIONS[user.role]
    : []

  const [form] = Form.useForm()
  let { storeId } = useParams()
  const storeDetailsQuery = useQuery(
    ['getStoreDetails', { id: storeId }],
    getStoreDetails
  )

  const [updatedProducts, setUpdatedProducts] = useState({})
  const [originalProducts, setOriginalProducts] = useState([])

  useEffect(() => {
    if (storeDetailsQuery.data && storeDetailsQuery.data.products) {
      setOriginalProducts([...storeDetailsQuery.data.products])

      form.setFieldsValue(
        storeDetailsQuery.data.products.reduce((acc, product) => {
          acc[product.key] = product.display
          return acc
        }, {})
      )
    }
  }, [storeDetailsQuery.data, form])

  const handleCheckboxChange = (productKey, checked) => {
    setUpdatedProducts({
      ...updatedProducts,
      [productKey]: checked,
    })
  }

  const handleSaveChanges = async () => {
    const updatedProductsArray = originalProducts.map((product) => ({
      key: product.key,
      name: product.name,
      display: updatedProducts[product.key] ?? product.display,
    }))

    try {
      await updateStoreProducts(storeId, updatedProductsArray)
      message.success('Distributor product updated !')
    } catch (error) {
      message.error(
        error.response?.data?.debugLog?.message ||
          error.response?.data?.message ||
          'An error occurs'
      )
    }
  }

  const updateStoreProducts = async (storeId, products) => {
    try {
      const payload = {
        storeId,
        products: products.map((product) => ({
          key: product.key,
          name: product.name,
          display: product.display,
        })),
      }

      await updateStore(payload, storeId)
      history.push('/product-franchises')
    } catch (error) {
      throw new Error('Error updating distributor products')
    }
  }

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Edit distributor products"
        onBack={() => history.push('/product-franchises')}
      />
      {storeDetailsQuery.isError ? (
        <Error retry={() => storeDetailsQuery.refetch()} />
      ) : storeDetailsQuery.isLoading ? (
        <Loader />
      ) : (
        <Card title="">
          <Form style={{ margin: '0px 14px' }} form={form} layout="vertical">
            {storeDetailsQuery.data.products.map((product) => (
              <Form.Item
                key={product.key}
                name={product.key}
                valuePropName="checked"
              >
                <Checkbox
                  onChange={(e) =>
                    handleCheckboxChange(product.key, e.target.checked)
                  }
                >
                  {product.name}
                </Checkbox>
              </Form.Item>
            ))}
            <Form.Item>
              <Button type="primary" onClick={handleSaveChanges}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </Card>
      )}
    </>
  )
}
