import { Form, PageHeader, message } from 'antd'

import FormWrap from '../../components/forms/FormWrap'
import FormBlock from '../../components/forms/FormBlock'
import { getCompanyProducts, createUniform } from '../../network/B2B'
import { useParams } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'

const CreateUniforms = ({ history }) => {
  const [form] = Form.useForm()
  const { user } = useAuth()
  const { storeId } = useParams()

  const handleSubmit = async (values) => {
    const payload = {
      ...values,
      store: storeId,
    }
    try {
      await createUniform(payload)
      history.push('/uniforms')
    } catch (error) {
      message.error(
        'An error occurred while creating the uniform. Please try again.'
      )
    }
  }

  const UniformDescriptionForm = {
    title: 'Description',
    key: [],
    sections: [
      {
        columns: 4,
        fields: [
          {
            label: 'Name',
            isRequired: true,
            key: ['name'],
            pos: {
              col: 1,
              row: 1,
              size: 1,
            },
          },
          {
            label: 'store',
            isRequired: true,
            isHiding: (values, name) => true,
            key: ['store'],
            pos: {
              col: 2,
              row: 2,
              size: 1,
            },
          },
        ],
      },
    ],
  }

  const ProductsForm = {
    title: 'Products',
    sections: [
      {
        canAdd: true,
        key: ['products'],
        // addSuffix: 'number',
        columns: 4,
        noDivider: true,
        // title: 'Product',
        deleteBtn: { label: 'Delete', style: 'line' },
        addBtn: { label: 'Add product', style: 'primary' },
        fields: [
          {
            label: 'Product',
            type: 'select',
            isRequired: true,
            key: ['product'],
            requestOption: async ({ queryKey }) => {
              const currentStore = user.currentStore
              const filter = { store: currentStore }

              return getCompanyProducts({
                queryKey: [
                  'getCompanyProducts',
                  {
                    filter,
                    sort: {},
                    range: {},
                    tabFilter: {},
                  },
                ],
              })
            },
            pos: {
              col: 1,
              row: 1,
              size: 1,
            },
          },
        ],
      },
    ],
  }

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Create uniform"
        onBack={() => history.push('/uniforms')}
        style={{ backgroundColor: '#fff' }}
      />
      <FormWrap
        title="create_uniforms"
        request={handleSubmit}
        cancelText={'Cancel'}
        okText={'Save'}
        onSuccess={() => history.push('/uniforms')}
        invalidate={['getUniforms']}
      >
        <div className="section-from-vertical">
          <FormBlock form={form} formTemplate={UniformDescriptionForm} />
          <FormBlock form={form} formTemplate={ProductsForm} />
        </div>
      </FormWrap>
    </>
  )
}

export default CreateUniforms
