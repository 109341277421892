const currencySymbols = {
  usd: '$',
  eur: '€',
  gbp: '£',
  cny: '¥',
  jpy: '¥',
  aud: '$',
  cad: '$',
  chf: '₣',
  hkd: '$',
  nzd: '$',
}

export const getCurrencySymbol = (currency) => {
  return currencySymbols[currency.toLowerCase()] || '¥'
}
