import { PageHeader, Button } from 'antd'
import { useQueryClient } from 'react-query'

import { getUsers } from '../../network/Users'
import TableBlock from '../../components/tables/TableBlock'
import { useAuth } from '../../hooks/useAuth'

export default function Members() {
  const { user } = useAuth()
  const isFranchise = user.franchiseStore
  const queryClient = useQueryClient()
  queryClient.invalidateQueries('getMemberDetails')

  const filters = (history) => ({
    fields: [
      {
        type: 'text',
        label: 'Name',
        key: 'name',
      },
    ],
    actions: [
      {
        label: '+ New user',
        isActive: true,
        action: () => history.push('/members/create'),
      },
    ],
  })

  const columns = (history) => [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Stores',
      key: 'stores',
      render: (stores) => {
        const displayStore = stores.stores
        if (displayStore && displayStore.length > 0) {
          return displayStore.map((storeRelation) => {
            const storeName = storeRelation.store?.name?.en || 'Unknown Store'
            const storeFranchise = storeRelation.store?.franchise
              ? '(Franchise)'
              : ''
            return (
              <div key={storeRelation._id}>
                {storeName} {!user.franchiseStore ? storeFranchise : ''}{' '}
              </div>
            )
          })
        }
        return 'No stores'
      },
    },
    {
      title: 'Roles',
      key: 'stores',
      render: (stores) => {
        const displayRole = stores.stores
        if (displayRole && displayRole.length > 0) {
          return displayRole.map((storeRelation) => {
            const role = storeRelation.role || 'Unknown Role'
            return <div key={storeRelation._id}>{role}</div>
          })
        }
        return 'No roles'
      },
    },
    {
      title: '',
      key: 'action',
      width: 25,
      render: (e) => {
        return (
          <Button
            onClick={() => history.push(`members/${e.id}`)}
            type="primary"
          >
            Edit
          </Button>
        )
      },
    },
  ]

  return (
    <>
      <PageHeader className="site-page-header" title="Team member" />
      <TableBlock
        columns={columns}
        filters={filters}
        name={'getUsers'}
        request={getUsers}
        infinite
        otherFilters={
          isFranchise
            ? {
                $and: [
                  { 'stores.store': user.currentStore },
                  { 'stores.store': { $ne: '6139b3bce793b880bd7c74d0' } }, // Remove the main BO store from the list
                ],
              }
            : {}
        }
      />
    </>
  )
}
