import { Button, Form, PageHeader } from 'antd'
import cloneDeep from 'lodash.clonedeep'
import { useEffect, useMemo, useState } from 'react'

import FormBlock from '../../components/forms/FormBlock'
import FormWrap from '../../components/forms/FormWrap'
import { formOptions } from '../../forms/blocks'
import {
  ProductsMediaForm,
  ProductsOptionsForm,
  ProductsPublicationForm,
} from '../../forms/Products'
import { createProduct } from '../../network/Products'
import { useParams } from 'react-router-dom'
import { getProductCategoriesBasic } from '../../network/API'
import { getProductTags } from '../../network/Tags'
import { useAuth } from '../../hooks/useAuth'
import { getCurrency } from '../../network/Stores'
import { getCurrencySymbol } from '../../utils/currencyUtils'

export const Details = ({ form }) => {
  const options = useMemo(() => {
    const options = cloneDeep(formOptions)
    delete options.collection
    delete options.suit_2_pieces
    delete options.suit_3_pieces
    delete options.default
    return options
  }, [])

  const DetailItem = ({ _, fieldIndex, remove }) => {
    const [index, setIndex] = useState(0)
    const [key, setKey] = useState('vest')
    const [template, setTemplate] = useState(null)

    useEffect(() => {
      const tpl = cloneDeep(options[key].forms[0].form[0])
      for (let i in tpl.sections) {
        for (let j in tpl.sections[i].fields) {
          tpl.sections[i].fields[j].key.splice(0, 0, key)
          tpl.sections[i].fields[j].key.splice(0, 0, fieldIndex)
        }
      }

      setTemplate(tpl)
    }, [fieldIndex, key])

    useEffect(() => {
      setKey(Object.keys(options)[index])
    }, [index])

    useEffect(() => {
      const detail = form.getFieldValue(['details', fieldIndex])
      if (detail) {
        const detailKeys = Object.keys(detail)
        const index = Object.keys(options).findIndex(
          (key) => detailKeys.includes(key) && !!detail[key]
        )
        setIndex(index)
        setKey(Object.keys(options)[index])
      }
    }, [fieldIndex])

    return (
      <>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(6, 1fr)',
            width: 'fit-content',
            columnGap: 12,
            rowGap: 12,
          }}
        >
          {/* According to the selected options, render different forms */}
          {Object.keys(options).map((key, i) => (
            <div
              key={i}
              className={`order-btn big ${index === i ? 'selected' : ''}`}
              onClick={() => {
                form.setFieldValue(['details', fieldIndex], {})
                setIndex(i)
                setKey(Object.keys(options)[index])
              }}
            >
              {options[key].name}
            </div>
          ))}
          <Button
            type="ghost"
            onClick={() => remove(fieldIndex)}
            style={{
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 5,
              color: '#9e1313',
              border: '1px solid #d1abab',
              '&:hover': {
                background: '#d1abab',
              },
            }}
          >
            Delete
          </Button>
        </div>
        {template && (
          <FormBlock
            form={form}
            formTemplate={template || options[key].forms[0].form[0]}
          />
        )}
      </>
    )
  }

  return (
    <Form.List name="details">
      {(fields, { add, remove }) => (
        <div className="section-from-vertical">
          {fields.map((field, fieldIndex) => {
            return (
              <DetailItem
                key={fieldIndex}
                form={form}
                field={field}
                fieldIndex={fieldIndex}
                remove={remove}
              />
            )
          })}
          <Button
            type="primary"
            style={{
              maxWidth: 200,
            }}
            onClick={() => {
              add({
                vest: options.vest.forms[0].form[0],
              })
            }}
          >
            Add Category
          </Button>
        </div>
      )}
    </Form.List>
  )
}

const CreateProduct = ({ history }) => {
  const [form] = Form.useForm()
  const { storeId } = useParams()
  const { user } = useAuth()

  const [currency, setCurrency] = useState('')
  const [currencySymbol, setCurrencySymbol] = useState('¥')

  useEffect(() => {
    const fetchCurrency = async () => {
      const currencyValue = await getCurrency(storeId)
      setCurrency(currencyValue.currency)

      const symbol = getCurrencySymbol(currencyValue.currency)
      setCurrencySymbol(symbol)
    }

    fetchCurrency()
  }, [storeId])

  const ProductsDescriptionForm = {
    title: 'Description',
    key: [],
    sections: [
      {
        columns: 2,
        fields: [
          {
            label: 'Product Name',
            placeholder: 'Name',
            isRequired: true,
            key: ['name', 'en'],
            pos: {
              col: 1,
              row: 1,
              size: 1,
            },
          },
          // Display only if the store is not a franchise
          ...(!user.franchiseStore
            ? [
                {
                  label: 'Product Name (中文)',
                  placeholder: 'Chinese name',
                  isRequired: true,
                  key: ['name', 'zh'],
                  pos: {
                    col: 1,
                    row: 2,
                    size: 1,
                  },
                },
              ]
            : []),
          {
            label: 'SKU',
            placeholder: 'Ref ID',
            isRequired: true,
            key: ['sku'],
            pos: {
              col: 1,
              row: 3,
              size: 1,
            },
          },
          {
            label: 'Collection',
            type: 'select',
            isRequired: true,
            key: ['category'],
            requestOption: async ({ queryKey }) => {
              const currentStore = user.currentStore
              const filter = { store: currentStore }

              return getProductCategoriesBasic({
                queryKey: [
                  'getProductCategoriesBasic',
                  {
                    filter,
                    sort: {},
                    range: {},
                    tabFilter: {},
                  },
                ],
              })
            },

            pos: {
              col: 1,
              row: 4,
              size: 1,
            },
          },
          {
            label: 'Tag',
            type: 'select',
            key: ['tag'],
            isRequired: false,
            requestOption: async ({ queryKey }) => {
              const currentStore = user.currentStore
              const filter = { store: currentStore }

              return getProductTags({
                queryKey: [
                  'getProductTags',
                  {
                    filter,
                    sort: {},
                    range: {},
                    tabFilter: {},
                  },
                ],
              })
            },
            pos: {
              col: 1,
              row: 5,
              size: 1,
            },
          },
          {
            label: `Retail price (${currencySymbol})`,
            isRequired: true,
            key: ['retailPrice'],
            pos: {
              col: 1,
              row: 6,
              size: 1,
            },
          },
          {
            label: 'Delivery time (days)',
            isRequired: true,
            key: ['deliveryTime'],
            pos: {
              col: 1,
              row: 7,
              size: 1,
            },
          },
          {
            label: 'Description',
            type: 'text',
            isRequired: true,
            key: ['description', 'en'],
            pos: {
              col: 1,
              row: 8,
              size: 1,
            },
          },
          // Display only if the store is not a franchise
          ...(!user.franchiseStore
            ? [
                {
                  label: 'Description (中文)',
                  type: 'text',
                  isRequired: true,
                  key: ['description', 'zh'],
                  pos: {
                    col: 1,
                    row: 9,
                    size: 1,
                  },
                },
                {
                  label: 'Show for mix-and-match',
                  type: 'checkbox',
                  isRequired: false,
                  key: ['mixAndMatch'],
                  default: false,
                  pos: {
                    col: 1,
                    row: 10,
                    size: 1,
                  },
                },
              ]
            : []),
        ],
      },
    ],
  }

  return (
    <>
      <PageHeader
        className="site-page-header"
        title={'Create product'}
        onBack={() => history.push('/products')}
        style={{ backgroundColor: '#fff' }}
      />
      <FormWrap
        form={form}
        title="create_client"
        request={createProduct}
        cancelText={'Cancel'}
        okText={'Save'}
        onSuccess={() => history.push('/products')}
        cleanBeforeSending={(oldData) => {
          oldData.store = storeId
          if (oldData.mainPicture) {
            let main = oldData.mainPicture.fileList
              ? oldData.mainPicture?.fileList
              : oldData.mainPicture

            if (!Array.isArray(main)) {
              main = [main]
            }
            const newPic = main.map((file) =>
              file.response ? file.response : file
            )

            oldData.mainPicture = newPic.length ? newPic[0] : {}
          } else {
            oldData.mainPicture = {}
          }

          if (oldData.otherPictures) {
            const otherPic = oldData.otherPictures.fileList
              ? oldData.otherPictures?.fileList
              : oldData.otherPictures
            oldData.otherPictures = otherPic.map((file) =>
              file.response ? file.response : file
            )
          } else {
            oldData.otherPictures = []
          }

          return oldData
        }}
        // For debugging form data change
        onValuesChange={(changedValues, allValues) => {
          // console.log(allValues)
        }}
        invalidate={['getProducts']}
      >
        <div className="section-from-vertical">
          <FormBlock form={form} formTemplate={ProductsDescriptionForm} />
          <Details form={form} />
          {!user.franchiseStore && (
            <FormBlock form={form} formTemplate={ProductsMediaForm} />
          )}
          <FormBlock form={form} formTemplate={ProductsOptionsForm} />
          <FormBlock.Basic formTemplate={ProductsPublicationForm} />
        </div>
      </FormWrap>
    </>
  )
}

export default CreateProduct
