import { INSTANCELIST, INSTANCE } from './API.js'

export const getProductPrices = () =>
  new Promise((resolve, reject) => {
    INSTANCELIST({
      method: 'get',
      url: `/product-prices`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(resolve)
      .catch(reject)
  })

export const getProductPrice = (type, storeId) =>
  new Promise((resolve, reject) => {
    INSTANCELIST({
      method: 'get',
      url: `/product-prices/${type}/${storeId}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(resolve)
      .catch(reject)
  })

export const updateProductPrices = (storeId, form) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'put',
      url: `/product-prices/store/${storeId}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: form,
    })
      .then(resolve)
      .catch(reject)
  })
