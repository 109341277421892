import { INSTANCE, INSTANCELIST } from './API'

export const getProductTags = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const { sort, range, filter, tabFilter } = queryKey[1]
    INSTANCELIST({
      method: 'get',
      url: '/product-tags',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      params: {
        sort: JSON.stringify(sort),
        range: JSON.stringify(range),
        filter: JSON.stringify(filter),
        ...tabFilter,
      },
    })
      .then(resolve)
      .catch(reject)
  })

export const getProductTag = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const [, id] = queryKey
    INSTANCE({
      method: 'get',
      url: `/product-tags/${id.id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(resolve)
      .catch(reject)
  })

export const createProductTag = (form) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'post',
      url: '/product-tags',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: form,
    })
      .then(resolve)
      .catch(reject)
  })

export const updateProductTag = (form, id) => {
  return new Promise((resolve, reject) => {
    INSTANCE({
      method: 'put',
      url: `/product-tags/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: form,
    })
      .then(resolve)
      .catch(reject)
  })
}
