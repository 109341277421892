import { Form, PageHeader, message } from 'antd'

import FormWrap from '../../components/forms/FormWrap'
import FormBlock from '../../components/forms/FormBlock'
import {
  getCompanies,
  getCompanyTheme,
  getUniforms,
  createCompanyOrder,
} from '../../network/B2B'
import { useAuth } from '../../hooks/useAuth'
import { useParams } from 'react-router-dom'

const CreateOrder = ({ history }) => {
  const [form] = Form.useForm()
  const { user } = useAuth()
  const { storeId } = useParams()

  const handleSubmit = async (values) => {
    const payload = {
      ...values,
      store: storeId,
    }
    try {
      await createCompanyOrder(payload)
      history.push('/companies/orders')
    } catch (error) {
      message.error(
        'An error occurred while creating the company order. Please try again.'
      )
    }
  }

  const OrderDescriptionForm = {
    title: 'Description',
    key: [],
    sections: [
      {
        columns: 4,
        fields: [
          {
            label: 'Company',
            type: 'select',
            isRequired: true,
            key: ['company'],
            requestOption: async ({ queryKey }) => {
              const currentStore = user.currentStore
              const filter = { store: currentStore }

              return getCompanies({
                queryKey: [
                  'getCompanies',
                  {
                    filter,
                    sort: {},
                    range: {},
                    tabFilter: {},
                  },
                ],
              })
            },
            pos: {
              col: 1,
              row: 1,
              size: 1,
            },
          },
        ],
      },
    ],
  }
  const UniformsForm = {
    title: 'Uniforms',
    sections: [
      {
        canAdd: true,
        key: ['uniforms'],
        addSuffix: 'number',
        columns: 4,
        // noDivider: true,
        title: 'Option',
        deleteBtn: { label: 'Delete', style: 'line' },
        addBtn: { label: 'Add uniform', style: 'primary' },
        fields: [
          {
            label: 'Uniform',
            type: 'select',
            isRequired: true,
            key: ['uniform'],
            requestOption: async ({ queryKey }) => {
              const currentStore = user.currentStore
              const filter = { store: currentStore }

              return getUniforms({
                queryKey: [
                  'getUniforms',
                  {
                    filter,
                    sort: {},
                    range: {},
                    tabFilter: {},
                  },
                ],
              })
            },
            pos: {
              col: 1,
              row: 1,
              size: 1,
            },
          },
          {
            label: 'Theme collection',
            type: 'select',
            isRequired: true,
            key: ['theme'],
            conditionalFilter: (form) => {
              let company = form.getFieldValue(['company'])
              return { company: company ? company : null }
            },
            requestOption: getCompanyTheme,
            onChange: (name, form) => {
              let uniforms = form.getFieldValue(['uniforms'])
              uniforms[name].role = undefined
              form.setFieldsValue({ uniforms })
            },
            pos: {
              col: 2,
              row: 1,
              size: 1,
            },
          },
          {
            label: 'Position',
            type: 'select',
            isRequired: true,
            key: ['role'],
            keySearch: 'getthemeSelect',
            conditionalFilter: (form) => {
              let company = form.getFieldValue(['company'])
              return { company: company ? company : null }
            },
            requestOption: getCompanyTheme,
            optionParser: (options, fieldKey, form) => {
              let theme = form.getFieldValue(['uniforms', fieldKey, 'theme'])
              if (!theme) return []
              const matching = options?.find((e) => e.id === theme)

              return matching?.roles?.map((e) => ({
                id: e,
                name: e,
              }))
            },
            pos: {
              col: 3,
              row: 1,
              size: 1,
            },
          },
        ],
      },
    ],
  }

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Create company order"
        onBack={() => history.push('/companies/orders')}
        style={{ backgroundColor: '#fff' }}
      />
      <FormWrap
        form={form}
        title="create_company"
        request={handleSubmit}
        cancelText={'Cancel'}
        okText={'Save'}
        onSuccess={() => history.push('/companies/orders')}
        invalidate={['getCompanies']}
      >
        <div className="section-from-vertical">
          <FormBlock form={form} formTemplate={OrderDescriptionForm} />
          <FormBlock form={form} formTemplate={UniformsForm} />
        </div>
      </FormWrap>
    </>
  )
}

export default CreateOrder
