import { Badge, Button, PageHeader } from 'antd'
import { useQueryClient } from 'react-query'
import React, { useState } from 'react'
import TableBlock from '../../components/tables/TableBlock'
import { getProductCategoriesBasic } from '../../network/API'
import { getProducts } from '../../network/Products'
import { ROLE_PERMISSIONS } from '../../constants/common'
import { useAuth } from '../../hooks/useAuth'
import { getCurrencySymbol } from '../../utils/currencyUtils'

const Products = () => {
  const queryClient = useQueryClient()
  queryClient.invalidateQueries('getProductDetails')

  const { user } = useAuth()
  const permissions = ROLE_PERMISSIONS[user.role]
    ? ROLE_PERMISSIONS[user.role]
    : []

  const [currency, setCurrency] = useState('')

  const filters = (history) => ({
    fields: [
      {
        type: 'text',
        label: 'SKU',
        key: 'sku',
      },
      {
        type: 'select',
        label: 'Collection',
        default: '',
        key: 'category',
        options: getProductCategoriesBasic,
      },
      {
        type: 'text',
        label: 'Name (english)',
        key: 'name.en',
      },
      {
        type: 'select',
        label: 'Publication',
        default: '',
        key: 'status',
        options: [
          { key: 'published', label: 'Published' },
          { key: 'unpublished', label: 'Unpublished' },
          { key: 'discontinued', label: 'Discontinued' },
        ],
      },
      {
        type: 'select',
        label: 'Target audience',
        default: '',
        key: 'targetAudience',
        options: [
          { key: 'company', label: 'Company' },
          { key: 'public', label: 'Public' },
        ],
      },
    ],
    actions: [
      {
        label: '+ Create product',
        isActive: permissions.includes('new_products'),
        action: () => history.push(`/products/create/${user.currentStore}`),
      },
    ],
  })

  const columns = (history, keyRequest) => {
    const franchiseStore = user.franchiseStore
    return [
      {
        title: 'SKU',
        key: 'name',
        dataIndex: 'sku',
      },
      {
        title: 'Name (English)',
        key: 'name.en',
        dataIndex: 'name',
        render: (name) => name?.en,
        sorter: true,
      },
      ...(franchiseStore
        ? []
        : [
            {
              title: 'Name (中文)',
              key: 'name.zh',
              dataIndex: 'name',
              render: (name) => name?.zh,
            },
          ]),
      {
        title: 'Collection',
        key: 'category',
        dataIndex: 'category',
        render: (category) => category?.name?.en || category?.name?.en,
        sorter: true,
      },
      {
        title: `Retail price (${currency})`,
        key: 'name',
        sorter: true,
        render: (e) => {
          let symbol = ''
          if (user.franchise === false) {
            symbol = getCurrencySymbol(e.store.currency)
          }

          if (user.franchiseStore) {
            const currencyTotalDue = {
              cny: 'RMB',
              eur: 'EUR',
              usd: 'USD',
              gbp: 'GBP',
              jpy: 'JPY',
              aud: 'AUD',
              cad: 'CAD',
              chf: 'CHF',
              hkd: 'HKD',
              nzd: 'NZD',
            }
            setCurrency(currencyTotalDue[e.store.currency] || '')
          }

          return <div>{`${e.retailPrice} ${symbol}`}</div>
        },
      },
      {
        title: 'Delivery days',
        key: 'name',
        dataIndex: 'deliveryTime',
        sorter: true,
      },
      {
        title: 'Published',
        key: 'name',
        dataIndex: 'status',
        render: (status) => {
          const statuses = {
            published: { text: 'Published', color: 'green' },
            unpublished: { text: 'Unpublished', color: 'red' },
            discontinued: { text: 'Discontinued', color: 'black' },
          }

          return (
            <Badge
              color={statuses[status].color}
              text={statuses[status].text}
            />
          )
        },
        sorter: true,
      },
      {
        title: '',
        key: 'action',
        width: 25,
        fixed: 'right',
        render: (e) => {
          return (
            <Button
              onClick={() =>
                history.push(`products/${e.id}/${user.currentStore}`)
              }
              disabled={permissions.includes('edit_products') ? false : true}
              type="primary"
            >
              Edit
            </Button>
          )
        },
      },
    ]
  }

  return (
    <>
      <PageHeader className="site-page-header" title="Products" />
      <TableBlock
        columns={columns}
        filters={filters}
        name={'getProducts'}
        request={getProducts}
        otherFilters={
          user.franchiseStore || !permissions.includes('can-see-franchises')
            ? { store: user.currentStore }
            : {}
        } // Filter the product if the store is franchised
      />
    </>
  )
}

export default Products
