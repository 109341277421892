export const MembersDescriptionForm = {
  key: [],
  sections: [
    {
      columns: 3,
      fields: [
        {
          label: 'Name',
          key: ['name'],
          isRequired: true,
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
        {
          label: 'Email',
          key: ['email'],
          rules: [
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
          ],
          isRequired: true,
          pos: {
            col: 1,
            row: 3,
            size: 1,
          },
        },
        {
          label: 'Password',
          key: ['password'],
          placeholder: '********',
          //   isRequired: true,
          pos: {
            col: 1,
            row: 4,
            size: 1,
          },
        },
      ],
    },
  ],
}
