import { INSTANCELIST, INSTANCE } from './API.js'
export const createStore = (form) =>
  new Promise((resolve, reject) => {
    INSTANCELIST({
      method: 'post',
      url: '/stores',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: form,
    })
      .then(resolve)
      .catch(reject)
  })

export const getStoreDetails = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const { id } = queryKey[1]
    INSTANCE({
      method: 'get',
      url: `/stores/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(resolve)
      .catch(reject)
  })

export const updateStore = (form, id) =>
  new Promise((resolve, reject) => {
    console.log('form:', form, 'id', id)
    INSTANCE({
      method: 'put',
      url: `/stores/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: form,
    })
      .then(resolve)
      .catch(reject)
  })

export const getCurrency = (storeId) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'get',
      url: `/stores/${storeId}/currency`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(resolve)
      .catch(reject)
  })
