import { PageHeader } from 'antd'
import FormBlock from '../../components/forms/FormBlock'
import FormWrap from '../../components/forms/FormWrap'
import { createProductTag } from '../../network/Tags'
import { useParams } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'

export default function CreateProductTag({ history }) {
  const { user } = useAuth()
  const { storeId } = useParams()

  const ProductTagForm = {
    title: 'Tag',
    key: [],
    sections: [
      {
        fields: [
          {
            label: 'Name',
            isRequired: true,
            key: ['name', 'en'],
            pos: {
              col: 1,
              row: 1,
              size: 1,
            },
          },
          // Display only if the store is not a franchise
          ...(!user.franchiseStore
            ? [
                {
                  label: 'Name (Chinese)',
                  isRequired: true,
                  key: ['name', 'zh'],
                  pos: {
                    col: 1,
                    row: 2,
                    size: 1,
                  },
                },
              ]
            : []),
          {
            label: 'Family',
            isRequired: true,
            key: ['genre'],
            pos: {
              col: 1,
              row: 3,
              size: 1,
            },
          },
        ],
      },
    ],
  }

  return (
    <>
      <PageHeader
        className={'site-page-header'}
        title={'Create Tag'}
        subTitle={'Create a new tag'}
        onBack={() => history.push('/product-tags')}
      />
      <FormWrap
        title="create_tag"
        request={createProductTag}
        cancelText={'Cancel'}
        okText={'Save'}
        onSuccess={() => history.push('/product-tags')}
        cleanBeforeSending={(oldData) => {
          oldData.store = storeId
          return oldData
        }}
      >
        <div className="section-from-vertical">
          <FormBlock formTemplate={ProductTagForm} />
        </div>
      </FormWrap>
    </>
  )
}
