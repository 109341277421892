import { UploadOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Collapse,
  DatePicker,
  Dropdown,
  Image,
  Input,
  InputNumber,
  Menu,
  Radio,
  Select,
  Space,
  Form as StyleForm,
  Tag,
  Typography,
  Upload,
} from 'antd'
import { FieldArray } from 'formik'
import get from 'lodash.get'
import moment from 'moment'
import React, { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import CollapsePanel from '../../components/CollapsePanel'
import { SERVER, upload } from '../../network/API'
import { update3Dlook } from '../../network/Clients'

const { Title, Text } = Typography
const { Panel } = Collapse

const CustomField = React.memo(
  ({ name, error, field, value, handleChange, placeholder, bodyMeasures }) => {
    const itemProps = {
      label: field.label,
      // label: `${field.label} | ${name}`,
      validateStatus: error ? 'error' : null,
      help: error,
      // id: `${name}`,
      className: field.className,
    }

    const uploadProps = {
      name: 'file',
      action: `${SERVER.baseURL}${field.requestURI}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }

    const mutation = useMutation((values) => upload(values.file, values.uri), {
      onSuccess: (data) => {
        handleChange({
          target: {
            name,
            value: value ? [...value, data] : [data],
          },
        })
      },
    })

    const fileList = () =>
      value && value.length
        ? value?.map((e, id) => ({
            uid: `${id}`,
            test: 'ever',
            name: e.name,
            status: 'done',
            url: `${SERVER.baseURL}${field.storageURI}/${e.uri}`,
          }))
        : []

    const getAge = (birthdate) => {
      if (!birthdate) return ''

      return `${moment().diff(birthdate, 'years', false)} years old`
    }

    switch (field.type) {
      case 'multiupload':
        return (
          <div id={name}>
            <StyleForm.Item id="test" {...itemProps}>
              <Upload
                {...uploadProps}
                listType="picture-card"
                fileList={fileList()}
                showUploadList={{
                  showPreviewIcon: false,
                }}
                customRequest={(e) =>
                  mutation.mutate({ file: e.file, uri: field.requestURI })
                }
                onRemove={(e) => {
                  let list = [...value]
                  list.splice(parseInt(e.uid), 1)
                  handleChange({
                    target: {
                      name,
                      value: list,
                    },
                  })
                }}
              >
                {!(field.multiple === false && fileList().length > 0) && (
                  <div>
                    <UploadOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                )}
              </Upload>
            </StyleForm.Item>
          </div>
        )

      case 'upload':
        // Upload only 1 image
        return (
          <div id={name}>
            <StyleForm.Item {...itemProps}>
              <Upload
                {...uploadProps}
                listType="picture-card"
                fileList={fileList()}
                showUploadList={{
                  showPreviewIcon: false,
                }}
                customRequest={(e) =>
                  mutation.mutate({ file: e.file, uri: field.requestURI })
                }
                onRemove={(e) => {
                  handleChange({
                    target: {
                      name,
                      value: null,
                    },
                  })
                }}
                multiple={false}
                maxCount={1}
              >
                {value ? null : (
                  <div>
                    <UploadOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                )}
              </Upload>
            </StyleForm.Item>
          </div>
        )

      case 'basicRadio':
        return (
          <div id={name}>
            <StyleForm.Item {...itemProps}>
              {/* <Radio.Group
              // options={[
              //   { label: 'Apple', value: 'Apple' },
              //   { label: 'Pear', value: 'Pear' },
              //   { label: 'Orange', value: 'Orange' },
              // ]}
              onChange={() => {}}
              value={''}
              optionType="button"
              buttonStyle="solid"
            /> */}

              <Radio.Group
                name={name}
                optionType={field.radioType}
                buttonStyle="solid"
                disabled={field.isInactive}
                onChange={(e) => {
                  handleChange(e)
                  if (field.onChange && field.onChangeRef) {
                    let nameArray = name.split('.')
                    nameArray.pop()
                    let path = [...nameArray, ...field.onChangeRef].join('.')
                    handleChange({
                      target: {
                        name: path,
                        value: field.onChange(e.target.value),
                      },
                    })
                  }
                }}
                value={value}
                options={field.options.map((e) => ({
                  label: e.label,
                  value: e.key,
                }))}
              />
            </StyleForm.Item>
          </div>
        )

      case 'select':
        return (
          <div id={name}>
            <StyleForm.Item {...itemProps}>
              <Select
                name={name}
                style={{ width: '50%' }}
                onChange={(e) => {
                  handleChange({
                    target: {
                      name,
                      value: e,
                    },
                  })
                  if (field.onChange && field.onChangeRef) {
                    let nameArray = name.split('.')
                    nameArray.pop()
                    let path = [...nameArray, ...field.onChangeRef].join('.')
                    handleChange({
                      target: {
                        name: path,
                        value: field.onChange(e),
                      },
                    })
                  }
                }}
                value={value}
              >
                {field.options.map((e) => (
                  <Select.Option key={e.key} value={e.key}>
                    {e.label}
                  </Select.Option>
                ))}
              </Select>
            </StyleForm.Item>
          </div>
        )

      case 'multiSelect':
        return (
          <div id={name}>
            <StyleForm.Item {...itemProps}>
              <Select
                mode="multiple"
                name={name}
                style={{ width: '50%' }}
                onChange={(e) =>
                  handleChange({
                    target: {
                      name,
                      value: e,
                    },
                  })
                }
                value={value || []}
              >
                {field.options.map((e) => (
                  <Select.Option key={e.key} value={e.key}>
                    {e.label}
                  </Select.Option>
                ))}
              </Select>
            </StyleForm.Item>
          </div>
        )

      case 'number':
        return (
          <div id={name}>
            <StyleForm.Item {...itemProps}>
              <InputNumber
                min={field.min || 0}
                max={field.max}
                type="tel"
                placeholder={placeholder}
                name={name}
                style={{ width: '50%' }}
                onChange={(e) =>
                  handleChange({
                    target: {
                      name,
                      value: e,
                    },
                  })
                }
                value={value}
                disabled={field.isInactive}
              />
            </StyleForm.Item>
          </div>
        )

      case 'text':
        return (
          <div id={name}>
            <StyleForm.Item {...itemProps}>
              <Input.TextArea
                name={name}
                rows={4}
                autoSize={{ minRows: 4, maxRows: 6 }}
                placeholder={placeholder}
                style={{ width: '50%' }}
                onChange={handleChange}
                value={value}
                disabled={field.isInactive}
              />
            </StyleForm.Item>
          </div>
        )

      //
      case 'birthday':
        return (
          <div id={name}>
            <Space style={{ flexWrap: 'wrap' }}>
              <StyleForm.Item {...itemProps} label="Birth year">
                <DatePicker
                  value={value}
                  onChange={(e) => {
                    handleChange({
                      target: {
                        name,
                        value: e || undefined,
                      },
                    })
                  }}
                  style={{ width: 262 }}
                  disabled={field.isInactive}
                  defaultValue={field.default}
                  format={'YYYY'}
                  picker="year"
                />
              </StyleForm.Item>
              <StyleForm.Item {...itemProps} label="Birth month">
                <DatePicker
                  value={value}
                  onChange={(e) => {
                    handleChange({
                      target: {
                        name,
                        value: e || undefined,
                      },
                    })
                  }}
                  style={{ width: 262 }}
                  disabled={field.isInactive}
                  defaultValue={field.default}
                  format={'MMMM'}
                  picker="month"
                />
              </StyleForm.Item>
              <StyleForm.Item {...itemProps} label="Birth day">
                <DatePicker
                  value={value}
                  onChange={(e) => {
                    handleChange({
                      target: {
                        name,
                        value: e || undefined,
                      },
                    })
                  }}
                  style={{ width: 262 }}
                  disabled={field.isInactive}
                  defaultValue={field.default}
                  format={'dddd DD'}
                />
              </StyleForm.Item>
              <StyleForm.Item {...itemProps} label="Age">
                <Input
                  value={getAge(value)}
                  style={{ width: 262 }}
                  disabled={true}
                />
              </StyleForm.Item>
            </Space>
          </div>
        )
      case 'date':
        return (
          <div id={name}>
            <Space style={{ flexWrap: 'wrap' }}>
              <StyleForm.Item {...itemProps} label="Date">
                <DatePicker
                  value={value ? moment(value) : null}
                  onChange={(e) => {
                    handleChange({
                      target: {
                        name,
                        value: e ? e : undefined,
                      },
                    })
                  }}
                  style={{ width: 262 }}
                  disabled={field.isInactive}
                  defaultValue={field.default ? moment(field.default) : null}
                  format={'DD-MM-YYYY'}
                  picker="date"
                />
              </StyleForm.Item>
            </Space>
          </div>
        )

      case '3dlook':
        return <TDLook bodyMeasures={bodyMeasures} />

      case 'addRef':
        return (
          <AddRefInput
            name={name}
            itemProps={itemProps}
            options={field.options}
            value={value}
            onChange={(val) =>
              handleChange({
                target: {
                  name,
                  value: val,
                },
              })
            }
          />
        )

      default:
        return (
          <div id={name}>
            <StyleForm.Item {...itemProps}>
              <Input
                name={name}
                type="text"
                placeholder={placeholder}
                style={{ width: '50%' }}
                onChange={handleChange}
                value={value}
                disabled={field.isInactive}
              />
            </StyleForm.Item>
          </div>
        )
    }
  }
)

const AddRefInput = ({ name, itemProps, value, onChange, options }) => {
  const [inputValue, setInputValue] = useState('')
  const menu = (
    <Menu>
      {options?.map((option) => (
        <Menu.Item onClick={() => onChange([...(value || []), option.key])}>
          {option.label}
        </Menu.Item>
      ))}
    </Menu>
  )

  const getLabel = (key) => {
    const opt = options.find((e) => e.key === key)

    if (opt) {
      return opt.label
    } else {
      return key
    }
  }

  return (
    <>
      <div id={name}>
        <StyleForm.Item {...itemProps}>
          {/*TODO: Refactored this into menu props*/}
          <Dropdown overlay={menu} trigger={['click']}>
            <Input
              name={name}
              type="text"
              style={{ width: '50%' }}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  onChange([...(value || []), inputValue])
                  setInputValue('')
                }
              }}
            />
          </Dropdown>
        </StyleForm.Item>
      </div>

      {(value || []).map((val, idx) => (
        <Tag
          closable
          onClose={(e) => {
            e.preventDefault()
            const newValue = [...value]
            newValue.splice(idx, 1)

            onChange(newValue)
          }}
        >
          {getLabel(val)}
        </Tag>
      ))}
    </>
  )
}

const FormWrap = ({ form, children }) => {
  if (form.isCollapse) {
    return (
      <Collapse /*defaultActiveKey={['1']} onChange={onChange}*/>
        <Panel header={form?.title} key="1">
          {children}
        </Panel>
      </Collapse>
    )
  }
  return <Card title={form?.title}>{children}</Card>
}

const FormContent = ({
  forms,
  handleChange,
  path,
  values,
  errors,
  allDisabled,
  hideErrors,
  haveOldValue,
  isEdition,
}) => {
  return (
    <StyleForm layout="vertical" style={{ width: '100%' }}>
      <Space direction="vertical" style={{ width: '100%' }}>
        {forms?.map((formGroup) =>
          formGroup.form?.map((form, index) => (
            <FormWrap key={index} form={form}>
              {/* <Card key={index} title={form?.title}> */}
              {form.sections?.map((section, i) =>
                section.canAdd ? (
                  <>
                    {/* <p>{`${path}.${
                      formGroup.key ? formGroup.key + '.' : ''
                    }${section.key.join('.')}`}</p> */}
                    <FieldArray
                      key={i}
                      name={`${path}.${
                        formGroup.key ? formGroup.key + '.' : ''
                      }${section.key.join('.')}`}
                    >
                      {({ remove, push }) => {
                        return (
                          <>
                            {/* <p>{`${path}.${
                            formGroup.key ? formGroup.key + '.' : ''
                          }${section.key.join('.')}`}</p> */}
                            {get(
                              values,
                              `${path}.${
                                formGroup.key ? formGroup.key + '.' : ''
                              }${section.key.join('.')}`,
                              []
                            )?.map((listItem, itemIndex) => (
                              <>
                                <Space direction="horizontal" align="center">
                                  <Title level={4}>
                                    {`${section.display} ${itemIndex + 1}`}
                                  </Title>
                                  <Button
                                    onClick={() => remove(itemIndex)}
                                    type="link"
                                  >
                                    Delete
                                  </Button>
                                </Space>
                                <FormFields
                                  section={section}
                                  formGroup={formGroup}
                                  path={path}
                                  arrayKey={`${section.key.join(
                                    '.'
                                  )}.${itemIndex}.`}
                                  values={values}
                                  errors={errors}
                                  hideErrors={hideErrors}
                                  handleChange={handleChange}
                                  allDisabled={allDisabled}
                                  haveOldValue={haveOldValue}
                                  isEdition={isEdition}
                                />
                              </>
                            ))}
                            <Button
                              type="primary"
                              onClick={() => {
                                if (section.key.join('.') === 'extraShirts') {
                                  const extraShirts = {}

                                  for (let field of section.fields) {
                                    extraShirts[field.key.join('.')] =
                                      field.default
                                  }
                                  push(extraShirts)
                                } else {
                                  push(section.default || {})
                                }
                              }}
                            >
                              <span>
                                Add{' '}
                                {NormalizedSectionName(section?.display)
                                  ? `${NormalizedSectionName(
                                      section?.display
                                    )} `
                                  : ''}
                                {get(
                                  values,
                                  `${path}.${
                                    formGroup.key ? formGroup.key + '.' : ''
                                  }${section.key.join('.')}`,
                                  []
                                )?.length + 1}
                              </span>
                            </Button>
                          </>
                        )
                      }}
                    </FieldArray>
                  </>
                ) : (
                  <FormFields
                    haveOldValue={haveOldValue}
                    key={i}
                    section={section}
                    formGroup={formGroup}
                    path={path}
                    values={values}
                    errors={errors}
                    hideErrors={hideErrors}
                    handleChange={handleChange}
                    allDisabled={allDisabled}
                    isEdition={isEdition}
                  />
                )
              )}
              {/* </Card> */}
            </FormWrap>
          ))
        )}
      </Space>
    </StyleForm>
  )
}

const NormalizedSectionName = (name) => {
  if (typeof name === 'string') {
    if (name.startsWith('Extra ')) {
      return name.substring(6, name.length).toLowerCase()
    }
  }
  return name
}

const FormFields = ({
  section,
  formGroup,
  path,
  values,
  errors,
  hideErrors,
  handleChange,
  allDisabled,
  arrayKey = '',
  haveOldValue,
  isEdition,
}) => {
  return (
    <Space size={0} direction="vertical" style={{ width: '100%' }}>
      {section?.fields?.map((field) => {
        const name = `${path ? path + '.' : ''}${
          formGroup.key ? formGroup.key + '.' : ''
        }${arrayKey}${field.key.join('.')}`

        // Hide the old fields in the create order
        if (haveOldValue && field.isOldField) {
          return null
        }

        // Hide the old fields in the edition if the value of the old field are null
        if (isEdition && field.isOldField) {
          const fieldValue = get(values, name, '')
          if (!fieldValue) {
            return null
          }
        }

        if (field.isHiding) {
          let getPath = `${path ? path + '.' : ''}${
            formGroup.key ? formGroup.key + '.' : ''
          }`
          if (arrayKey) {
            getPath = getPath + arrayKey
          }
          const isHided = field.isHiding(values, getPath)

          if (isHided) return null
        }

        return !field.foldable ? (
          <CustomField
            key={field.key.join('.')}
            error={hideErrors ? null : get(errors, name, null)}
            name={`${name}`}
            // required={
            //   field.isConditionalRequired
            //     ? field.isConditionalRequired(values, name)
            //     : !!field.required
            // }
            handleChange={handleChange}
            placeholder={field.placeholder}
            field={{
              ...field,
              isInactive: allDisabled ? true : field.isInactive,
            }}
            value={get(values, name, field.default)}
            bodyMeasures={
              field.type === '3dlook'
                ? get(values, 'bodyMeasures', {})
                : undefined
            }
          />
        ) : (
          <CollapsePanel
            key={field.key.join('.')}
            label={field.label}
            collapse={!(hideErrors ? null : get(errors, name, null))}
          >
            <CustomField
              key={field.key.join('.')}
              error={hideErrors ? null : get(errors, name, null)}
              name={`${name}`}
              // required={
              //   field.isConditionalRequired
              //     ? field.isConditionalRequired(values, name)
              //     : !!field.required
              // }
              handleChange={handleChange}
              placeholder={field.placeholder}
              field={{
                ...field,
                isInactive: allDisabled ? true : field.isInactive,
              }}
              value={get(values, name, field.default)}
              bodyMeasures={
                field.type === '3dlook'
                  ? get(values, 'bodyMeasures', {})
                  : undefined
              }
            />
          </CollapsePanel>
        )
      })}
    </Space>
  )
}

// const resizeFile = (file) =>
//   new Promise((resolve) => {
//     Resizer.imageFileResizer(
//       file,
//       300,
//       300,
//       'JPEG',
//       100,
//       0,
//       (uri) => {
//         resolve(uri);
//       },
//       'base64'
//     );
//   });

const TDLook = ({ bodyMeasures }) => {
  let { userId } = useParams()
  const [frontPic, setFrontPic] = useState()
  const [sidePic, setSidePic] = useState()
  // const [loading, setLoading] = useState(false)
  const queryClient = useQueryClient()
  // const [value, setValue] = useState<File | null>(null)

  const getBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  const handleFiles = async (event, type) => {
    const file = event.target.files[0]
    if (!file) return
    // if (file.size >= fileSize * 1024 * 1024) {
    //     return
    // }

    if (type === 'front') {
      setFrontPic({ url: await getBase64(file), file })
    } else {
      setSidePic({ url: await getBase64(file), file })
    }
  }

  const mutation = useMutation(
    () =>
      update3Dlook(
        userId,
        frontPic.file,
        sidePic.file,
        bodyMeasures.height,
        bodyMeasures.weight
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('3DLooks')
        setFrontPic()
        setSidePic()
      },
    }
  )

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      <Space>
        <Space direction="vertical">
          <Text strong>Front picture</Text>
          <Button
            type="primary"
            icon={<UploadOutlined />}
            style={{ position: 'relative' }}
            disabled={mutation.isLoading}
          >
            Select picture
            <input
              style={{
                cursor: 'pointer',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                fontSize: 0,
                backgroundColor: 'transparent',
                border: 'none',
                opacity: 0,
                zIndex: 1,
              }}
              type="file"
              name="file"
              accept="image/*"
              onChange={(e) => handleFiles(e, 'front')}
              onClick={(event) => {
                event.target.value = null
              }}
            />
          </Button>
        </Space>
        <Image
          width={100}
          height={100}
          src={frontPic ? frontPic?.url : 'error'}
          fallback="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
        />
        <Space direction="vertical">
          <Text strong>Side picture</Text>
          <Button
            type="primary"
            icon={<UploadOutlined />}
            style={{ position: 'relative' }}
            disabled={mutation.isLoading}
          >
            Select picture
            <input
              style={{
                cursor: 'pointer',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                fontSize: 0,
                backgroundColor: 'transparent',
                border: 'none',
                opacity: 0,
                zIndex: 1,
              }}
              type="file"
              name="file"
              accept="image/*"
              onChange={(e) => handleFiles(e, 'side')}
              onClick={(event) => {
                event.target.value = null
              }}
            />
          </Button>
        </Space>
        <Image
          width={100}
          height={100}
          src={sidePic ? sidePic?.url : 'error'}
          fallback="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
        />
      </Space>

      <Space>
        <Button
          onClick={mutation.mutate}
          loading={mutation.isLoading}
          disabled={!frontPic || !sidePic}
          type="primary"
        >
          Get new 3D Scan measurements
        </Button>

        <Button
          onClick={() => {
            queryClient.invalidateQueries('3DLooks')
            setFrontPic()
            setSidePic()
          }}
          disabled={mutation.isLoading}
        >
          Refresh
        </Button>
      </Space>
    </div>
  )
}

export default FormContent
