import { AdjustmentForm } from './Adjustment'
import { BeltDetailsForm, BeltMeasureForm } from './Belt'
import { CoatDetailsForm, CoatMeasureForm } from './Coat'
import {
  JacketDetailsForm,
  JacketMeasureForm,
  JacketSuitDetailsForm,
} from './Jacket'
import { JeanDetailsForm, JeansMeasureForm } from './Jean'
import { PantDetailsForm, PantMeasureForm, PantSuitDetailsForm } from './Pant'
import { AddShirtsForm, ShirtDetailsForm, ShirtMeasureForm } from './Shirt'
import { ShoesDetailsForm, ShoesMeasureForm } from './Shoes'
import { SkirtDetailsForm, SkirtMeasureForm } from './Skirt'
import { SunglassesDetailsForm, SunglassesMeasureForm } from './Sunglasses'
import { VestDetailsForm, VestMeasureForm, VestSuitDetailsForm } from './Vest'
import { CashmereDetailsForm, CashmereMeasureForm } from './Cashmere'
import { TwoHundredSDetailsForm, TwoHundredSMeasureForm } from './TwoHundredS'

import get from 'lodash.get'
import { roundHalf } from '../../utils/utils'
import {
  AddressForm,
  PaymentForm,
  ReciepForm,
  OrderCommentForm,
  EstimateDeliveryDateForm,
} from './Payment'

export const Shoes = [ShoesDetailsForm]
export const Belt = [BeltDetailsForm]
export const Sunglasses = [SunglassesDetailsForm]
export const Skirt = [SkirtDetailsForm]
export const Coat = [CoatDetailsForm]
export const Jean = [JeanDetailsForm]
export const Shirt = [ShirtDetailsForm, AddShirtsForm]
export const Pant = [PantDetailsForm]
export const PantSuit = [PantSuitDetailsForm]
export const Vest = [VestDetailsForm]
export const VestSuit = [VestSuitDetailsForm]
export const Jacket = [JacketDetailsForm]
export const JacketSuit = [JacketSuitDetailsForm]
export const Cashmere = [CashmereDetailsForm]
export const TwoHundredS = [TwoHundredSDetailsForm]

export const Adjustment = [AdjustmentForm]

export const measureShoes = [ShoesMeasureForm]
export const measureBelt = [BeltMeasureForm]
export const measureSunglasses = [SunglassesMeasureForm]
export const measureSkirt = [SkirtMeasureForm]
export const measureCoat = [CoatMeasureForm]
export const measureJean = [JeansMeasureForm]
export const measureShirt = [ShirtMeasureForm]
export const measurePant = [PantMeasureForm]
export const measureVest = [VestMeasureForm]
export const measureJacket = [JacketMeasureForm]
export const mesureCashmere = [CashmereMeasureForm]
export const mesureTwoHundredS = [TwoHundredSMeasureForm]

export const Payment = [
  // EstimateDeliveryDateForm,
  OrderCommentForm,
  AddressForm,
  ReciepForm,
  PaymentForm,
]

const JacketDefault = {
  orderCustomJacketBlazer: {
    suitStyle: '2_button_single_breasted',
    lapelStyle: 'notch',
    lapelWidth: '7',
    vents: 'side_vents',
    chestPocket: 'straigth_2.5_standard',
    ticketPocket: 'no',
    lowerPocket: 'straight_slim_double_bessom',
    liningCraft: 'full_lining',
    lapelButtonHolePosition: 'left',
    lapelButtonHoleSize: 'handmade_milanese',
    insideChestPocketFabric: 'same_as_piping',
    addMonogram: 'yes',
    monogramFont: 'monotype_corsiva_6mm',
    meltonRef: 'FN010031',
    sleeveButtonHole: '4_button_hole',
    sleeveButtonHoleStyle: 'separate',
    sleeveButtonHoleColor: 'no',
    sleeveButtonHoleColorWhich: '4th',
    monogramColor: 'gold_120',
    contrastColor: 'no',
    sleeveLiningRef: 'same_as_piping',
    canvaCraft: 'premium',
    fitPreference: 'standard_fit',

    contrastPositionMulti: ['collar'],
    lapelButtonHoleColorRef: 'yz017',
    buttonRef: 'fk503202',
    sleeveHoleColorRef: 'yz017',
    sleeveWrist: 27,
    jacketShoulder: 'standand',
  },
}

const JacketSuitDefault = {
  orderCustomJacketBlazer: {
    suitStyle: '2_button_single_breasted',
    lapelStyle: 'notch',
    lapelWidth: '7',
    vents: 'side_vents',
    chestPocket: 'straigth_2.5_standard',
    ticketPocket: 'no',
    lowerPocket: 'straight_slim_double_bessom',
    liningCraft: 'full_lining',
    lapelButtonHolePosition: 'left',
    lapelButtonHoleSize: 'handmade_milanese',
    insideChestPocketFabric: 'same_as_piping',
    addMonogram: 'yes',
    monogramFont: 'monotype_corsiva_6mm',
    meltonRef: 'FN010031',
    sleeveButtonHole: '4_button_hole',
    sleeveButtonHoleStyle: 'separate',
    sleeveButtonHoleColor: 'no',
    sleeveButtonHoleColorWhich: '4th',
    monogramColor: 'gold_120',
    contrastColor: 'no',
    sleeveLiningRef: 'same_as_piping',
    canvaCraft: 'premium',
    fitPreference: 'standard_fit',

    contrastPositionMulti: ['collar'],
    lapelButtonHoleColorRef: 'yz017',
    buttonRef: 'fk503202',
    sleeveHoleColorRef: 'yz017',
    jacketShoulder: 'standand',
  },
}

const VestDefault = {
  orderCustomVest: {
    backFabric: 'same_front_fabric',
    vestStyle: 'single_breasted_4_buttons',
    vestLapelStyle: 'no_lapel',
    vestChestPocket: 'none',
    vestFrontPocket: '2cm_width',
    vestBottomShape: 'cross_arrow',
    contrastFabric: 'no',
    sidePockets: 'chest_pocket',

    buttonRef: 'fk503202',
    shape: 'v_shape',
    contrastFabricRef: 'fe029023',
  },
}

const VestSuitDefault = {
  orderCustomVest: {
    fabric: 'same_front_fabric',
    backFabric: 'same_front_fabric',
    lining: 'same_suit_lining',
    button: 'same_as_suit',
    vestStyle: 'single_breasted_4_buttons',
    vestLapelStyle: 'no_lapel',
    vestChestPocket: 'none',
    vestFrontPocket: '2cm_width',
    vestBottomShape: 'cross_arrow',
    contrastFabric: 'no',
    sidePockets: 'chest_pocket',

    fabricRefSuit: 'same_as_jacket',

    buttonRef: 'fk503202',
    shape: 'v_shape',
    contrastFabricRef: 'fe029023',
  },
}

const PantDefault = {
  orderCustomPant: {
    sidePockets: 'standard',
    pleats: 'no_pleats',
    pantCuff: 'no_pant_cuff',
    watchPocket: 'coin_pocket_6cm',
    suspenserButton: 'no',
    backPocket: 'left_right',
    backPocketStyle: 'double_besson_with_button',
    insidePhonePocket: 'none',
    seamSatin: 'no',
    waistbandExtension: 'standard',
    liningPant: 'half_leg',
    contrastColor: 'no',
    contrastPosition: 'no',
    waistOption1: 'std',
    waistOption2: 'belt',

    type: 'suit_pant',
    buttonRef: 'fk503202',
    length: 'long_pant',
  },
}

const PantSuitDefault = {
  orderCustomPant: {
    sidePockets: 'standard',
    pleats: 'no_pleats',
    pantCuff: 'no_pant_cuff',
    watchPocket: 'coin_pocket_6cm',
    suspenserButton: 'no',
    backPocket: 'left_right',
    backPocketStyle: 'double_besson_with_button',
    insidePhonePocket: 'none',
    waistOption: 'std_belt',
    seamSatin: 'no',
    waistbandExtension: 'standard',
    liningPant: 'half_leg',
    contrastColor: 'no',
    contrastPosition: 'no',

    fabricRefSuit: 'same_as_jacket',
    buttonRefSuit: 'same_as_jacket',

    type: 'suit_pant',
    buttonRef: 'fk503202',
    length: 'long_pant',
    waistOption1: 'std',
    waistOption2: 'belt',
  },
}

const ShirtDefault = {
  orderCustomShirt: {
    pocket: 'no',
    pocketOptions: 'triangle',
    placket: 'no',
    addMonogram: 'yes',
    monogramFont: 'lucida_handwritting_6mm',
    monogramColor: 'gold_120',
    monogramPosition: 'bottom_left_reqloi',
    addContrastPart: 'no',
    contrastPosition: 'inner_top_collar',
    contrastFabric: 'white_rmc90054',
    fitPreference: 'standard_fit',
    collarStyle: 'italian_reqclx',
    cuffStyle: '1_button_hole_rounded_style',
    addMore: 0,
    collection: 'af_collections',
    fabricRef: 'hw31510',
    collectionAddMore: 'af_collections',
    fabricRefAddMore: 'hw31510',
  },
}

const JeanDefault = {
  orderCustomJean: {
    initial: 'no',
    // fabricRef: '7308J2-IC2',
    colorRef: 'r1',
    initialPosition: 'both',
    initialThread: 'same_as_jean',
    initialHeight: 0.6,
    waistOption: 'belt_loop',
    washing: 'no',
    flyOptions: 'button',
    buttonSet: 'yes',
    threadColorRef: 'j01',
    pocketFabricRef: 'afj001',
    initialThreadRef: 'j01',
    buttonSetRef: 'logo_cooper',
    legOpening: 25,
    frontCrotch: 26,
  },
}

const CoatDefault = {
  orderCustomCoat: {
    style: 'single_breasted_3_buttons',
    lapelStyle: 'high_collar',
    vent: 'central',
    backExtension: 'no',
    belt: 'no',
    sleeveStrap: 'yes',
    shoulderStrap: 'no',
    pocket: '2',
    pocketStyle: 'straight',
    pocketDesign: 'pocket_flap',
    sleeveLining: 'same_as_piping',
    winterFinishing: 'yes',
    chestPocket: 'no',
    contrastColor: 'no',
    contrastPositionMulti: ['collar'],
    addMonogram: 'no',
    monogramColor: 'gold_120',
    monogramFont: 'monotype_corsiva_6mm',
    melton: 'black',
    lapelWidth: 6,
  },
}

const SkirtDefault = {
  orderCustomSkirt: {
    zipper: 'back',
    pleat: 'no_pleat',
    pocket: 'no',
    vents: 'back',
    waistband: '5cm',
  },
}

const BeltDefault = {
  orderCustomBelt: {
    material: 'leather',
    leatherColorRef: 'l1',
    suedeColorRef: 's1',
    buckle: 'logo',
    buckleColor: 'black',
  },
}

const ShoesDefault = {
  orderCustomShoes: {
    design: 'oxford_one_cut_pointed',
    material: 'leather',
    leatherPatternRef: 'original',
    soleConfection: 'blake',
    soleMaterial: 'leather',
    sole: 'welted',
    leatherSoleOption: 'plain',
    topReinforcement: 'no',
    frontTop: 'no',
    pipingRef: 'l9',
    heels: 'no',
    fur: 'no',
  },
}

const CashmereDefault = {
  orderCustomCashmere: {},
}

const TwoHundredSDefault = {
  orderCustomTwoHundredS: {},
}

const PersonaeTypeDefault = {
  personaeType: 'special_event',
}

export const formOptions = {
  collection: {
    name: 'Fixed Designs',
    forms: [{ form: undefined, key: 'orderCollection' }],
  },
  suit_3_pieces: {
    name: 'Suit 3 Pieces',
    priceList: {
      classic: { price: 5000, label: 'Classic' },
      premium: { price: 7500, label: 'Premium' },
      master: { price: 9500, label: 'Master' },
      vbc_150s: { price: 14500, label: 'Vbc 150s' },
      dormeuil_amadeus: { price: 19800, label: 'Dormeuil amadeus' },
      dormeuil_excel: { price: 25800, label: 'Dormeuil excel' },
      'dormeuil_15.7': { price: 34800, label: 'Dormeuil 15.7' },
      dormeuil_ambassador: { price: 60800, label: 'Dormeuil ambassador' },
    },
    forms: [
      { form: JacketSuit, key: 'orderCustom.orderCustomJacketBlazer' },
      { form: VestSuit, key: 'orderCustom.orderCustomVest' },
      { form: PantSuit, key: 'orderCustom.orderCustomPant' },
    ],
    default: {
      orderProductType: 'custom',
      orderCollection: null,
      orderAdjustment: null,
      orderCustom: {
        type: 'suit_3_pieces',
        // quality: 'classic',
        // price: 5000,
        personaeType: 'special_event',
        ...JacketSuitDefault,
        ...VestSuitDefault,
        ...PantSuitDefault,
      },
      skuMeasurements: [{}],
    },
    measureDefault: (bodyMeasure, tdlook, foot, fit, shoulderType) => {
      //
      let newPantWaist = bodyMeasure.waistPant
      let newPantLength = bodyMeasure.length
      let newPantHipGirth = bodyMeasure.hips
      let newPantWholeCrotch = bodyMeasure.wholeCrotch
      let newPantThigh = bodyMeasure.thigh
      let newPantLegWidth = bodyMeasure.knee
      let newPantCalf = bodyMeasure.calf
      let newPantLegOpening = 38

      let newJacketLengthFront = undefined
      let newJacketLengthBack = undefined
      let newJacketShoulderType = shoulderType
      let newJacketShoulder = bodyMeasure.shoulder
      let newJacketSleeve = undefined
      let newJacketChest = bodyMeasure.chest
      let newJacketWaist = bodyMeasure.waistJacket
      let newJacketStomach = bodyMeasure.stomach
      let newJacketLowerHerm = bodyMeasure.hips
      let newJacketBiceps = bodyMeasure.biceps
      let newJacketArmHole = bodyMeasure.armHole
      let newJacketSleeveWrist = 27

      let newVestLengthFrontStraight = bodyMeasure.sideNeckPoint
      let newVestLengthBackStraight = bodyMeasure.sideNeckPoint
      let newVestLengthFrontCross = bodyMeasure.sideNeckPoint
      let newVestLengthBackCross = bodyMeasure.sideNeckPoint
      let newVestChest = bodyMeasure.chest
      let newVestWaist = bodyMeasure.waistJacket
      let newVestHips = bodyMeasure.hips

      if (bodyMeasure.measurementSource !== 'af') {
        //Pant
        newPantWaist += 1
        newPantLength = roundHalf(
          get(tdlook, 'front_params.outseam_from_upper_hip_level', 0)
        )

        newPantWholeCrotch = roundHalf(
          get(tdlook, 'front_params.front_crotch_length', 0) -
            3 +
            get(tdlook, 'front_params.back_crotch_length', 0) -
            8
        )

        newPantLegOpening = roundHalf(
          get(tdlook, 'volume_params.ankle', 0) + 12
        )

        //Jacket
        newJacketLengthFront = roundHalf(
          get(tdlook, 'front_params.jacket_length', 0) + 1
        )
        newJacketLengthBack = roundHalf(
          get(tdlook, 'front_params.jacket_length', 0) - 3
        )

        newJacketShoulder += 1
        newJacketSleeve = roundHalf(
          get(tdlook, 'front_params.sleeve_length', 0) + 2
        )

        newJacketArmHole += 5
        newJacketSleeveWrist = roundHalf(
          get(tdlook, 'volume_params.wrist', 0) + 8
        )

        //Vest
        newVestLengthFrontStraight += 8
        newVestLengthBackStraight += 5
        newVestLengthFrontCross += 10
        newVestLengthBackCross += 2
      } else {
        newVestLengthFrontStraight += 10
        newVestLengthBackStraight += 9
        newVestLengthFrontCross += 11
        newVestLengthBackCross += 5
      }

      switch (fit) {
        case 'slim_fit':
          //Pant
          newPantCalf += 4
          newPantHipGirth += 2
          newPantThigh += 3
          newPantLegWidth += 2

          //Jacket
          newJacketChest += 4
          newJacketLowerHerm += 4
          newJacketWaist += 4
          newJacketStomach += 4
          newJacketBiceps += 4

          //Vest
          newVestChest += 2
          newVestWaist += 2
          newVestHips = newVestWaist + 3
          break
        case 'standard_fit':
          //Pant
          newPantCalf += 5
          newPantHipGirth += 4
          newPantThigh += 4
          newPantLegWidth += 3

          //Jacket
          newJacketChest += 6
          newJacketLowerHerm += 6
          newJacketWaist += 6
          newJacketStomach += 6
          newJacketBiceps += 6

          //Vest
          newVestChest += 3
          newVestWaist += 3
          newVestHips = newVestWaist + 4
          break
        case 'loose_fit':
          //Pant
          newPantCalf += 7
          newPantHipGirth += 5
          newPantThigh += 6
          newPantLegWidth += 4

          //Jacket
          newJacketChest += 8
          newJacketLowerHerm += 8
          newJacketWaist += 8
          newJacketStomach += 8
          newJacketBiceps += 8

          //Vest
          newVestChest += 4
          newVestWaist += 4
          newVestHips = newVestWaist + 6
          break

        default:
          break
      }

      return {
        skuMeasurementPant: {
          waist: newPantWaist,
          length: newPantLength,
          hipGirth: newPantHipGirth,
          wholeCrotch: newPantWholeCrotch,
          thigh: newPantThigh,
          legWidth: newPantLegWidth,
          calf: newPantCalf,
          legOpening: newPantLegOpening,
        },
        skuMeasurementJacketBlazer: {
          lengthFront: newJacketLengthFront,
          lengthBack: newJacketLengthBack,
          shoulderType: newJacketShoulderType,
          shoulder: newJacketShoulder,
          sleeve: newJacketSleeve,
          chest: newJacketChest,
          waist: newJacketWaist,
          stomach: newJacketStomach,
          lowerHerm: newJacketLowerHerm,
          biceps: newJacketBiceps,
          armHole: newJacketArmHole,
          sleeveWrist: newJacketSleeveWrist,
        },
        skuMeasurementVest: {
          lengthFrontStraight: newVestLengthFrontStraight,
          lengthBackStraight: newVestLengthBackStraight,
          lengthFrontCross: newVestLengthFrontCross,
          lengthBackCross: newVestLengthBackCross,
          chest: newVestChest,
          waist: newVestWaist,
          hips: newVestHips,
        },
      }
    },
    measuresForms: [
      {
        form: measureJacket,
        key: 'skuMeasurements.0.skuMeasurementJacketBlazer',
      },
      { form: measureVest, key: 'skuMeasurements.0.skuMeasurementVest' },
      { form: measurePant, key: 'skuMeasurements.0.skuMeasurementPant' },
    ],
  },
  suit_2_pieces: {
    name: 'Suit 2 Pieces',
    priceList: {
      classic: { price: 3800, label: 'Classic' },
      premium: { price: 5800, label: 'Premium' },
      master: { price: 6800, label: 'Master' },
      vbc_150s: { price: 9800, label: 'Vbc 150s' },
      dormeuil_amadeus: { price: 14800, label: 'Dormeuil amadeus' },
      dormeuil_excel: { price: 18800, label: 'Dormeuil excel' },
      'dormeuil_15.7': { price: 25800, label: 'Dormeuil 15.7' },
      dormeuil_ambassador: { price: 45800, label: 'Dormeuil ambassador' },
    },
    forms: [
      { form: JacketSuit, key: 'orderCustom.orderCustomJacketBlazer' },
      { form: PantSuit, key: 'orderCustom.orderCustomPant' },
    ],
    default: {
      orderProductType: 'custom',
      orderCollection: null,
      orderAdjustment: null,
      orderCustom: {
        type: 'suit_2_pieces',
        // quality: 'classic',
        // price: 3800,
        personaeType: 'special_event',
        ...JacketSuitDefault,
        ...PantSuitDefault,
      },
      skuMeasurements: [{}],
    },
    measureDefault: (bodyMeasure, tdlook, foot, fit, shoulderType) => {
      let newPantWaist = bodyMeasure.waistPant
      let newPantLength = undefined
      let newPantHipGirth = bodyMeasure.hips
      let newPantWholeCrotch = undefined
      let newPantThigh = bodyMeasure.thigh
      let newPantLegWidth = bodyMeasure.knee
      let newPantCalf = bodyMeasure.calf
      let newPantLegOpening = 38

      let newJacketLengthFront = undefined
      let newJacketLengthBack = undefined
      let newJacketShoulderType = shoulderType
      let newJacketShoulder = bodyMeasure.shoulder
      let newJacketSleeve = undefined
      let newJacketChest = bodyMeasure.chest
      let newJacketWaist = bodyMeasure.waistJacket
      let newJacketStomach = bodyMeasure.stomach
      let newJacketLowerHerm = bodyMeasure.hips
      let newJacketBiceps = bodyMeasure.biceps
      let newJacketArmHole = bodyMeasure.armHole
      let newJacketSleeveWrist = 27

      if (bodyMeasure.measurementSource !== 'af') {
        //Pant
        newPantWaist += 1
        newPantLength = roundHalf(
          get(tdlook, 'front_params.outseam_from_upper_hip_level', 0)
        )

        newPantWholeCrotch = roundHalf(
          get(tdlook, 'front_params.front_crotch_length', 0) -
            3 +
            get(tdlook, 'front_params.back_crotch_length', 0) -
            8
        )

        newPantLegOpening = roundHalf(
          get(tdlook, 'volume_params.ankle', 0) + 12
        )

        //Jacket
        newJacketLengthFront = roundHalf(
          get(tdlook, 'front_params.jacket_length', 0) + 1
        )
        newJacketLengthBack = roundHalf(
          get(tdlook, 'front_params.jacket_length', 0) - 3
        )

        newJacketShoulder += 1
        newJacketSleeve = roundHalf(
          get(tdlook, 'front_params.sleeve_length', 0) + 2
        )

        newJacketArmHole += 5
        newJacketSleeveWrist = roundHalf(
          get(tdlook, 'volume_params.wrist', 0) + 8
        )
      }

      switch (fit) {
        case 'slim_fit':
          //Pant
          newPantCalf += 4
          newPantHipGirth += 2
          newPantThigh += 3
          newPantLegWidth += 2

          //Jacket
          newJacketChest += 4
          newJacketLowerHerm += 4
          newJacketWaist += 4
          newJacketStomach += 4
          newJacketBiceps += 4
          break
        case 'standard_fit':
          //Pant
          newPantCalf += 5
          newPantHipGirth += 4
          newPantThigh += 4
          newPantLegWidth += 3

          //Jacket
          newJacketChest += 6
          newJacketLowerHerm += 6
          newJacketWaist += 6
          newJacketStomach += 6
          newJacketBiceps += 6
          break
        case 'loose_fit':
          //Pant
          newPantCalf += 7
          newPantHipGirth += 5
          newPantThigh += 6
          newPantLegWidth += 4

          //Jacket
          newJacketChest += 8
          newJacketLowerHerm += 8
          newJacketWaist += 8
          newJacketStomach += 8
          newJacketBiceps += 8
          break

        default:
          break
      }

      return {
        skuMeasurementPant: {
          waist: newPantWaist,
          length: newPantLength,
          hipGirth: newPantHipGirth,
          wholeCrotch: newPantWholeCrotch,
          thigh: newPantThigh,
          legWidth: newPantLegWidth,
          calf: newPantCalf,
          legOpening: newPantLegOpening,
        },
        skuMeasurementJacketBlazer: {
          lengthFront: newJacketLengthFront,
          lengthBack: newJacketLengthBack,
          shoulderType: newJacketShoulderType,
          shoulder: newJacketShoulder,
          sleeve: newJacketSleeve,
          chest: newJacketChest,
          waist: newJacketWaist,
          stomach: newJacketStomach,
          lowerHerm: newJacketLowerHerm,
          biceps: newJacketBiceps,
          armHole: newJacketArmHole,
          sleeveWrist: newJacketSleeveWrist,
        },
      }
    },
    measuresForms: [
      {
        form: measureJacket,
        key: 'skuMeasurements.0.skuMeasurementJacketBlazer',
      },
      { form: measurePant, key: 'skuMeasurements.0.skuMeasurementPant' },
    ],
  },
  vest: {
    name: 'Vest',
    priceList: {
      classic: { price: 1200, label: 'Classic' },
      premium: { price: 1800, label: 'Premium' },
      master: { price: 2400, label: 'Master' },
      vbc_150s: { price: 3800, label: 'Vbc 150s' },
      dormeuil_amadeus: { price: 5200, label: 'Dormeuil amadeus' },
      dormeuil_excel: { price: 6500, label: 'Dormeuil excel' },
      'dormeuil_15.7': { price: 8500, label: 'Dormeuil 15.7' },
      dormeuil_ambassador: { price: 15000, label: 'Dormeuil ambassador' },
    },
    forms: [{ form: Vest, key: 'orderCustom.orderCustomVest' }],
    default: {
      orderProductType: 'custom',
      orderCollection: null,
      orderAdjustment: null,
      orderCustom: {
        type: 'vest',
        // quality: 'classic',
        // price: 1200,
        personaeType: 'special_event',
        ...VestDefault,
      },
      skuMeasurements: [{}],
    },
    measureDefault: (bodyMeasure, tdlook, foot, fit) => {
      let newLengthFrontStraight = bodyMeasure.sideNeckPoint
      let newLengthBackStraight = bodyMeasure.sideNeckPoint
      let newLengthFrontCross = bodyMeasure.sideNeckPoint
      let newLengthBackCross = bodyMeasure.sideNeckPoint
      let newChest = bodyMeasure.chest
      let newWaist = bodyMeasure.waistJacket
      let newHips = newWaist

      if (bodyMeasure.measurementSource === 'af') {
        newLengthFrontStraight += 8
        newLengthBackStraight += 5
        newLengthFrontCross += 10
        newLengthBackCross += 2
      } else {
        newLengthFrontStraight += 10
        newLengthBackStraight += 9
        newLengthFrontCross += 11
        newLengthBackCross += 5
      }

      switch (fit) {
        case 'slim_fit':
          newChest += 2
          newWaist += 2
          newHips = newWaist + 3
          break
        case 'standard_fit':
          newChest += 3
          newWaist += 3
          newHips = newWaist + 4
          break
        case 'loose_fit':
          newChest += 4
          newWaist += 4
          newHips = newWaist + 6
          break

        default:
          break
      }

      return {
        skuMeasurementVest: {
          lengthFrontStraight: newLengthFrontStraight,
          lengthBackStraight: newLengthBackStraight,
          lengthFrontCross: newLengthFrontCross,
          lengthBackCross: newLengthBackCross,
          chest: newChest,
          waist: newWaist,
          hips: newHips,
        },
      }
    },
    measuresForms: [
      { form: measureVest, key: 'skuMeasurements.0.skuMeasurementVest' },
    ],
  },
  pant: {
    name: 'Pants',
    priceList: {
      classic: { price: 1200, label: 'Classic' },
      premium: { price: 1800, label: 'Premium' },
      master: { price: 2400, label: 'Master' },
      vbc_150s: { price: 3800, label: 'Vbc 150s' },
      dormeuil_amadeus: { price: 5200, label: 'Dormeuil amadeus' },
      dormeuil_excel: { price: 6500, label: 'Dormeuil excel' },
      'dormeuil_15.7': { price: 8500, label: 'Dormeuil 15.7' },
      dormeuil_ambassador: { price: 15000, label: 'Dormeuil ambassador' },
    },
    forms: [{ form: Pant, key: 'orderCustom.orderCustomPant' }],
    default: {
      orderProductType: 'custom',
      orderCollection: null,
      orderAdjustment: null,
      orderCustom: {
        type: 'pant',
        // quality: 'classic',
        // price: 1200,
        personaeType: 'special_event',
        ...PantDefault,
      },
      skuMeasurements: [{}],
    },
    measureDefault: (bodyMeasure, tdlook, foot, fit) => {
      let newWaist = bodyMeasure.waistPant
      let newLength = undefined
      let newHipGirth = bodyMeasure.hips
      let newWholeCrotch = undefined
      let newThigh = bodyMeasure.thigh
      let newLegWidth = bodyMeasure.knee
      let newCalf = bodyMeasure.calf
      let newLegOpening = 38

      if (bodyMeasure.measurementSource !== 'af') {
        newWaist += 1
        newLength = roundHalf(
          get(tdlook, 'front_params.outseam_from_upper_hip_level', 0)
        )

        newWholeCrotch = roundHalf(
          get(tdlook, 'front_params.front_crotch_length', 0) -
            3 +
            get(tdlook, 'front_params.back_crotch_length', 0) -
            8
        )

        newLegOpening = roundHalf(get(tdlook, 'volume_params.ankle', 0) + 12)
      }

      switch (fit) {
        case 'slim_fit':
          newCalf += 4
          newHipGirth += 2
          newThigh += 3
          newLegWidth += 2
          break
        case 'standard_fit':
          newCalf += 5
          newHipGirth += 4
          newThigh += 4
          newLegWidth += 3
          break
        case 'loose_fit':
          newCalf += 7
          newHipGirth += 5
          newThigh += 6
          newLegWidth += 4
          break

        default:
          break
      }

      return {
        skuMeasurementPant: {
          waist: newWaist,
          length: newLength,
          hipGirth: newHipGirth,
          wholeCrotch: newWholeCrotch,
          thigh: newThigh,
          legWidth: newLegWidth,
          calf: newCalf,
          legOpening: newLegOpening,
        },
      }
    },
    measuresForms: [
      { form: measurePant, key: 'skuMeasurements.0.skuMeasurementPant' },
    ],
  },
  skirt: {
    name: 'Skirt',
    priceList: {
      classic: { price: 1200, label: 'Classic' },
      premium: { price: 1800, label: 'Premium' },
      master: { price: 2400, label: 'Master' },
      vbc_150s: { price: 3800, label: 'Vbc 150s' },
      dormeuil_amadeus: { price: 5200, label: 'Dormeuil amadeus' },
      dormeuil_excel: { price: 6500, label: 'Dormeuil excel' },
      'dormeuil_15.7': { price: 8500, label: 'Dormeuil 15.7' },
      dormeuil_ambassador: { price: 15000, label: 'Dormeuil ambassador' },
    },
    key: ['orderCustomSkirt'],
    forms: [{ form: Skirt, key: 'orderCustom.orderCustomSkirt' }],
    default: {
      orderProductType: 'custom',
      orderCollection: null,
      orderAdjustment: null,
      orderCustom: {
        type: 'skirt',
        // quality: 'classic',
        // price: 1200,
        personaeType: 'special_event',
        ...SkirtDefault,
      },
      skuMeasurements: [{}],
    },
    measureDefault: (bodyMeasure, tdlook, foot, fit, shoulderType, product) => {
      let newSkirtLength = get(product, 'orderCustomSkirt.length', undefined)
      let newSkirtPosition = 'high'
      let newWaist = bodyMeasure.waistPant
      let newHips = bodyMeasure.hips

      if (bodyMeasure.measurementSource !== 'af') {
        newSkirtLength = roundHalf(
          get(tdlook, 'front_params.waist_to_knees', 0) + 1
        )

        newWaist += 2
        newHips = roundHalf(get(tdlook, 'volume_params.low_hips', 0) + 2)
      }

      return {
        skuMeasurementSkirt: {
          waist: newWaist,
          hips: newHips,
          skirtLength: newSkirtLength,
          skirtPosition: newSkirtPosition,
        },
      }
    },
    measuresForms: [
      { form: measureSkirt, key: 'skuMeasurements.0.skuMeasurementSkirt' },
    ],
  },
  jacket_blazer: {
    name: 'Jacket / Blazer',
    priceList: {
      classic: { price: 2800, label: 'Classic' },
      premium: { price: 4000, label: 'Premium' },
      master: { price: 4800, label: 'Master' },
      vbc_150s: { price: 6800, label: 'Vbc 150s' },
      dormeuil_amadeus: { price: 9800, label: 'Dormeuil amadeus' },
      dormeuil_excel: { price: 12800, label: 'Dormeuil excel' },
      'dormeuil_15.7': { price: 18000, label: 'Dormeuil 15.7' },
      dormeuil_ambassador: { price: 30800, label: 'Dormeuil ambassador' },
    },
    forms: [{ form: Jacket, key: 'orderCustom.orderCustomJacketBlazer' }],
    default: {
      orderProductType: 'custom',
      orderCollection: null,
      orderAdjustment: null,
      orderCustom: {
        type: 'jacket_blazer',
        // quality: 'classic',
        // price: 2800,
        personaeType: 'special_event',
        ...JacketDefault,
      },
      skuMeasurements: [{}],
    },
    measureDefault: (bodyMeasure, tdlook, foot, fit, shoulderType) => {
      let newLengthFront = undefined
      let newLengthBack = undefined
      let newShoulderType = shoulderType
      let newShoulder = bodyMeasure.shoulder
      let newSleeve = undefined
      let newChest = bodyMeasure.chest
      let newWaist = bodyMeasure.waistJacket
      let newStomach = bodyMeasure.stomach
      let newLowerHerm = bodyMeasure.hips
      let newBiceps = bodyMeasure.biceps
      let newArmHole = bodyMeasure.armHole
      let newSleeveWrist = bodyMeasure.sleeveWrist || 27

      if (bodyMeasure.measurementSource !== 'af') {
        newLengthFront = roundHalf(
          get(tdlook, 'front_params.jacket_length', 0) + 1
        )
        newLengthBack = roundHalf(
          get(tdlook, 'front_params.jacket_length', 0) - 3
        )

        newShoulder += 1
        newSleeve = roundHalf(get(tdlook, 'front_params.sleeve_length', 0) + 2)

        newArmHole += 5
        newSleeveWrist = roundHalf(get(tdlook, 'volume_params.wrist', 0) + 8)
      }

      switch (fit) {
        case 'slim_fit':
          newChest += 4
          newLowerHerm += 4
          newWaist += 4
          newStomach += 4
          newBiceps += 4
          break
        case 'standard_fit':
          newChest += 6
          newLowerHerm += 6
          newWaist += 6
          newStomach += 6
          newBiceps += 6
          break
        case 'loose_fit':
          newChest += 8
          newLowerHerm += 8
          newWaist += 8
          newStomach += 8
          newBiceps += 8
          break

        default:
          break
      }

      return {
        skuMeasurementJacketBlazer: {
          lengthFront: newLengthFront,
          lengthBack: newLengthBack,
          shoulderType: newShoulderType,
          shoulder: newShoulder,
          sleeve: newSleeve,
          chest: newChest,
          waist: newWaist,
          stomach: newStomach,
          lowerHerm: newLowerHerm,
          biceps: newBiceps,
          armHole: newArmHole,
          sleeveWrist: newSleeveWrist,
        },
      }
    },
    measuresForms: [
      {
        form: measureJacket,
        key: 'skuMeasurements.0.skuMeasurementJacketBlazer',
      },
    ],
  },
  shirt: {
    name: 'Shirt',
    priceList: {
      classic: { price: 800, label: 'Classic' },
      premium: { price: 1500, label: 'Premium' },
      master: { price: 1800, label: 'Master' },
    },
    forms: [{ form: Shirt, key: 'orderCustom.orderCustomShirt' }],
    default: {
      orderProductType: 'custom',
      orderCollection: null,
      orderAdjustment: null,
      orderCustom: {
        type: 'shirt',
        // quality: 'classic',
        // price: 800,
        personaeType: 'special_event',
        ...ShirtDefault,
      },
      skuMeasurements: [{}],
    },
    measureDefault: (bodyMeasure, tdlook, foot, fit) => {
      let newLengthFront = undefined
      let newLengthBack = undefined
      let newSleeveLength = undefined
      let newNeck = bodyMeasure.neck
      let newShoulder = bodyMeasure.shoulder
      let newChest = bodyMeasure.chest
      let newWaist = bodyMeasure.waistJacket
      let newStomach = bodyMeasure.stomach
      let newLowerHerm = bodyMeasure.hips
      let newBiceps = bodyMeasure.biceps
      let newArmHole = bodyMeasure.armHole
      let newSleeveWristRight = undefined
      let newSleeveWristLeft = undefined

      if (bodyMeasure.measurementSource !== 'af') {
        newLengthFront = roundHalf(
          get(tdlook, 'front_params.jacket_length', 0) + 4
        )
        newLengthBack = roundHalf(
          get(tdlook, 'front_params.jacket_length', 0) + 4
        )
        newSleeveLength = roundHalf(
          get(tdlook, 'front_params.sleeve_length', 0) + 3
        )
        newNeck += 1
        newShoulder += 1
        newArmHole += 5
        newSleeveWristRight = roundHalf(
          get(tdlook, 'volume_params.wrist', 0) + 5
        )
        newSleeveWristLeft = roundHalf(
          get(tdlook, 'volume_params.wrist', 0) + 5
        )
      }

      switch (fit) {
        case 'slim_fit':
          newChest += 8
          newWaist += 6
          newStomach += 4
          newLowerHerm += 6
          newBiceps += 6
          break
        case 'standard_fit':
          newChest += 10
          newWaist += 8
          newStomach += 6
          newLowerHerm += 8
          newBiceps += 6
          break
        case 'loose_fit':
          newChest += 12
          newWaist += 10
          newStomach += 8
          newLowerHerm += 10
          newBiceps += 8
          break

        default:
          break
      }

      return {
        skuMeasurementShirt: {
          lengthFront: newLengthFront,
          lengthBack: newLengthBack,
          sleeveLength: newSleeveLength,
          neck: newNeck,
          shoulder: newShoulder,
          chest: newChest,
          waist: newWaist,
          stomach: newStomach,
          lowerHerm: newLowerHerm,
          biceps: newBiceps,
          armHole: newArmHole,
          sleeveWristRight: newSleeveWristRight,
          sleeveWristLeft: newSleeveWristLeft,
        },
      }
    },
    measuresForms: [
      { form: measureShirt, key: 'skuMeasurements.0.skuMeasurementShirt' },
    ],
  },
  jean: {
    name: 'Jeans',
    forms: [{ form: Jean, key: 'orderCustom.orderCustomJean' }],
    default: {
      orderProductType: 'custom',
      orderCollection: null,
      orderAdjustment: null,
      orderCustom: {
        type: 'jean',
        // price: 1800,
        personaeType: 'special_event',
        ...JeanDefault,
      },
      skuMeasurements: [{}],
    },
    measureDefault: (bodyMeasure, tdlook, foot, fit) => {
      let newJeanWaist = bodyMeasure.waistPant
      let newLength = undefined
      let newInnerLength = undefined
      let newHips = bodyMeasure.hips
      let newCrotch = undefined
      let newFrontCrotch = 26
      let newBackCrotch = undefined
      let newThigh = bodyMeasure.thigh
      let newLegWidth = bodyMeasure.knee
      let newCalf = bodyMeasure.calf
      let newLegOpening = 36
      let newJeanPositionWaist = 'middle'

      if (bodyMeasure.measurementSource !== 'af') {
        newJeanWaist -= 8
        newLength = roundHalf(
          get(tdlook, 'front_params.outseam_from_upper_hip_level', 0) + 1
        )
        newInnerLength = roundHalf(get(tdlook, 'front_params.inseam', 0) + 2)
        newHips -= 1
        newCrotch = roundHalf(
          get(tdlook, 'front_params.front_crotch_length', 0) -
            3 +
            get(tdlook, 'front_params.back_crotch_length', 0) -
            8
        )
        newFrontCrotch = roundHalf(
          get(tdlook, 'front_params.front_crotch_length', 0) - 3
        )
        newBackCrotch = roundHalf(
          get(tdlook, 'front_params.back_crotch_length', 0) - 5
        )
        newThigh += 1
        newLegWidth += 1
        newCalf += 1
        newLegOpening = roundHalf(get(tdlook, 'volume_params.ankle', 0) + 10)
      } else {
        switch (fit) {
          case 'slim_fit':
            newJeanWaist -= 9
            newHips -= 2
            newThigh -= 2
            newLegWidth -= 2
            newCalf += 1
            break
          case 'standard_fit':
            newJeanWaist -= 8
            newHips -= 0
            newThigh -= 0
            newLegWidth -= 0
            newCalf += 2
            break
          case 'loose_fit':
            newJeanWaist -= 6
            newHips += 3
            newThigh += 4
            newLegWidth += 4
            newCalf += 4
            break

          default:
            break
        }
      }
      newCrotch = (newFrontCrotch || 0) + (newBackCrotch || 0)
      return {
        skuMeasurementJean: {
          jeanWaist: newJeanWaist,
          length: newLength,
          innerLength: newInnerLength,
          hips: newHips,
          crotch: newCrotch,
          frontCrotch: newFrontCrotch,
          backCrotch: newBackCrotch,
          thigh: newThigh,
          legWidth: newLegWidth,
          calf: newCalf,
          legOpening: newLegOpening,
          jeanPositionWaist: newJeanPositionWaist,
        },
      }
    },
    measuresForms: [
      { form: measureJean, key: 'skuMeasurements.0.skuMeasurementJean' },
    ],
  },
  shoes: {
    name: 'Shoes',
    priceList: {
      classic_range: { price: 2800, label: 'Classic range' },
      premium_range: { price: 6500, label: 'Premium range' },
      sneaker_classic: { price: 3800, label: 'Sneaker classic' },
      sneaker_premium: { price: 5800, label: 'Sneaker premium' },
      boots: { price: 3800, label: 'Boots' },
    },
    forms: [{ form: Shoes, key: 'orderCustom.orderCustomShoes' }],
    default: {
      orderProductType: 'custom',
      orderCollection: null,
      orderAdjustment: null,
      orderCustom: {
        type: 'shoes',
        // quality: 'classic_range',
        // price: 2800,
        personaeType: 'special_event',
        ...ShoesDefault,
      },
      skuMeasurements: [{}],
    },
    measureDefault: (bodyMeasure, tdlook, foot) => {
      return {
        skuMeasurementShoes: {
          footMeasure: foot.footMeasure,
          strongFoot: foot.strongFoot,
          leftFootLength: undefined,
          leftFootWidth: undefined,
          leftFootHeight: undefined,
          rightFootLength: undefined,
          rightFootWidth: undefined,
          rightFootHeight: undefined,
          picturesLeft: [...get(foot, 'picturesLeft', [])],
          picturesRight: [...get(foot, 'picturesRight', [])],
        },
      }
    },
    measuresForms: [
      { form: measureShoes, key: 'skuMeasurements.0.skuMeasurementShoes' },
    ],
  },
  coat: {
    name: 'Coat',
    priceList: {
      classic: { price: 5500, label: 'Classic' },
      premium: { price: 8000, label: 'Premium' },
      master: { price: 9800, label: 'Master' },
      dormeuil_amadeus: { price: 13800, label: 'Dormeuil amadeus' },
      dormeuil_excel: { price: 15800, label: 'Dormeuil excel' },
      'dormeuil_15.7': { price: 18800, label: 'Dormeuil 15.7' },
      dormeuil_ambassador: { price: 258000, label: 'Dormeuil ambassador' },
    },
    forms: [{ form: Coat, key: 'orderCustom.orderCustomCoat' }],
    // isPriceEditable: true,
    default: {
      orderProductType: 'custom',
      orderCollection: null,
      orderAdjustment: null,
      orderCustom: {
        type: 'coat',
        // quality: 'classic',
        // price: 5500,
        personaeType: 'special_event',
        ...CoatDefault,
      },
      skuMeasurements: [{}],
    },
    measureDefault: (bodyMeasure, tdlook, foot, fit) => {
      let newJacketLengthFront = undefined
      let newJacketLengthBack = undefined
      let newShoulder = bodyMeasure.shoulder
      let newSleeveLength = undefined
      let newChest = bodyMeasure.chest
      let newWaist = bodyMeasure.waistJacket
      let newStomach = bodyMeasure.stomach
      let newHips = bodyMeasure.hips
      let newBiceps = bodyMeasure.biceps
      let newArmHole = bodyMeasure.armHole
      let newSleeveWrist = bodyMeasure.sleeveWrist

      if (bodyMeasure.measurementSource !== 'af') {
        newJacketLengthFront = roundHalf(
          get(tdlook, 'side_params.shoulders_to_knees', 0) - 10
        )
        newJacketLengthBack = roundHalf(
          get(tdlook, 'side_params.shoulders_to_knees', 0) - 10
        )

        newSleeveLength = roundHalf(
          get(tdlook, 'front_params.sleeve_length', 0) + 5
        )

        newArmHole = roundHalf(
          get(tdlook, 'volume_params.armscye_girth', 0) + 8
        )
        newSleeveWrist = roundHalf(get(tdlook, 'volume_params.wrist', 0) + 12)
      }

      switch (fit) {
        case 'slim_fit':
          newShoulder += 1
          newChest += 8
          newWaist += 6
          newHips += 6
          newBiceps += 4
          if (bodyMeasure.measurementSource === 'af') newArmHole += 1
          break
        case 'standard_fit':
          newShoulder += 2
          newChest += 10
          newWaist += 8
          newHips += 8
          newBiceps += 6
          if (bodyMeasure.measurementSource === 'af') newArmHole += 2
          break
        case 'loose_fit':
          newShoulder += 4
          newChest += 12
          newWaist += 10
          newHips += 10
          newBiceps += 8
          if (bodyMeasure.measurementSource === 'af') newArmHole += 4
          break

        default:
          break
      }

      return {
        skuMeasurementCoat: {
          jacketLengthFront: newJacketLengthFront,
          jacketLengthBack: newJacketLengthBack,
          shoulder: newShoulder,
          sleeveLength: newSleeveLength,
          chest: newChest,
          waist: newWaist,
          stomach: newStomach,
          hips: newHips,
          biceps: newBiceps,
          armHole: newArmHole,
          sleeveWrist: newSleeveWrist,
        },
      }
    },
    measuresForms: [
      { form: measureCoat, key: 'skuMeasurements.0.skuMeasurementCoat' },
    ],
  },
  belt: {
    name: 'Belt',
    forms: [{ form: Belt, key: 'orderCustom.orderCustomBelt' }],
    default: {
      orderProductType: 'custom',
      orderCollection: null,
      orderAdjustment: null,
      orderCustom: {
        type: 'belt',
        // quality: 'classic',
        // price: 1200,
        personaeType: 'special_event',
        ...BeltDefault,
        ...PersonaeTypeDefault,
      },
      skuMeasurements: [{}],
    },
    measureDefault: () => ({
      skuMeasurementBelt: {},
    }),
    // measuresForms: [
    //   { form: measureBelt, key: 'skuMeasurements.0.skuMeasurementBelt' },
    // ],
  },
  sunglasses: {
    name: 'Sunglasses',
    forms: [{ form: Sunglasses, key: 'orderCustom.orderCustomSunglasses' }],
    default: {
      orderProductType: 'custom',
      orderCollection: null,
      orderAdjustment: null,
      orderCustom: {
        type: 'sunglasses',
        // quality: 'classic',
        // price: 1800,
        personaeType: 'special_event',
      },
      skuMeasurements: [{}],
    },
    measureDefault: () => ({
      skuMeasurementSunglasses: {},
    }),
    // measuresForms: [
    //   {
    //     form: measureSunglasses,
    //     key: 'skuMeasurements.0.skuMeasurementSunglasses',
    //   },
    // ],
  },
  // adjustment: {
  //   name: 'Adjustment',
  //   forms: [{ form: Adjustment, key: 'orderAdjustment' }],
  // },
  cashmere: {
    name: 'Cashmere',
    forms: [{ form: Cashmere, key: 'orderCustom.orderCustomCashmere' }],
    default: {
      orderProductType: 'custom',
      orderCollection: null,
      orderAdjustment: null,
      orderCustom: {
        type: 'cashmere',
        // price: 4800,
        personaeType: 'special_event',
        ...CashmereDefault,
      },
      skuMeasurements: [{}],
    },
    measureDefault: () => ({
      skuMeasurementCashmere: {},
    }),
  },
  two_hundred_s: {
    name: '200s',
    forms: [{ form: TwoHundredS, key: 'orderCustom.orderCustomTwoHundredS' }],
    default: {
      orderProductType: 'custom',
      orderCollection: null,
      orderAdjustment: null,
      orderCustom: {
        type: 'two_hundred_s',
        // price: 1800,
        personaeType: 'special_event',
        ...TwoHundredSDefault,
      },
      skuMeasurements: [{}],
    },
    measureDefault: () => ({
      skuMeasurementTwoHundredS: {},
    }),
  },
}
