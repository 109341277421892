import { Fragment, useEffect, useState } from 'react'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tabs,
} from 'antd'
import { useQueryClient } from 'react-query'

import { downloadCSV } from '../../utils/utils'

import LoadingSelect from '../LoadingSelect'

const { TabPane } = Tabs
const { Option } = Select
const { RangePicker } = DatePicker

const TableFilter = ({
  config,
  onFiltersChange,
  elemId,
  onTabChange,
  dataList,
  selectedRowKeys,
  requestKeys,
  resetSelected,
}) => {
  const [filters, setFilters] = useState({})
  const [tabFilters, setTabFilters] = useState({})
  const queryClient = useQueryClient()

  const sendLines = (request) => {
    request(selectedRowKeys).then(() => {
      queryClient.invalidateQueries('getOffersServices')
      queryClient.invalidateQueries('getInvoices')
      resetSelected()
    })
  }

  const handleChange = (key, value, type, noRegex) => {
    let tempFilters = { ...filters }
    if (value) {
      if (type === 'text') {
        tempFilters[key] = noRegex ? value : { $regex: value, $options: 'i' }
      } else {
        tempFilters[key] = value
      }
    } else {
      delete tempFilters[key]
    }
    setFilters(tempFilters)
    if (onFiltersChange) onFiltersChange(tempFilters)
  }

  const handleTabChange = (key, value, type) => {
    let tempTabFilters = { ...tabFilters }

    if (value) {
      if (type === 'daterange') {
        if (value[0] && value[1]) {
          tempTabFilters[key[0]] = new Date(
            value[0].toDate().setHours(0, 0, 0, 0)
          )

          tempTabFilters[key[1]] = new Date(
            value[1].toDate().setHours(23, 59, 59, 999)
          )
        } else {
          delete tempTabFilters[key[0]]
          delete tempTabFilters[key[1]]
        }
      } else {
        tempTabFilters[key] = type === 'date' ? value.toDate() : value
      }
    } else {
      if (type === 'daterange') {
        delete tempTabFilters[key[0]]
        delete tempTabFilters[key[1]]
      }
      delete tempTabFilters[key]
    }
    setTabFilters(tempTabFilters)
    onTabChange(tempTabFilters)
  }

  return (
    <>
      <Form layout={'vertical'}>
        <Space direction="vertical" size={0} className="table-filter">
          <Space className="table-filter-conditions" align="end">
            <Row>
              {config.fields?.map((cell) => (
                <Col className="table-filter-col" key={cell.key}>
                  <FieldType
                    key={cell.key}
                    field={cell}
                    onChange={(value) =>
                      handleChange(cell.key, value, cell.type, cell.noRegex)
                    }
                    def={cell.default}
                    onTabChange={(value) =>
                      handleTabChange(cell.key, value, cell.type)
                    }
                  />
                </Col>
              ))}
            </Row>
            <div />
            <Space className="table-filter-buttons" align="end">
              {config.actions?.map((btn, i) =>
                btn.component ? (
                  <Fragment key={i}>
                    {btn.component(elemId, requestKeys)}
                  </Fragment>
                ) : (
                  <Button
                    key={i}
                    disabled={!btn.isActive}
                    onClick={() =>
                      btn.action
                        ? btn.action()
                        : btn.lineAction
                        ? sendLines(btn.lineAction, requestKeys)
                        : downloadCSV(
                            btn.csv.header,
                            dataList.map((line) => btn.csv.rowFilter(line)),
                            btn.csv.name
                          )
                    }
                    type={btn.second ? 'secondary' : 'primary'}
                  >
                    {btn.label}
                  </Button>
                )
              )}
            </Space>
          </Space>
          {config.tabs && (
            <Tabs
              defaultActiveKey={config.tabs.default}
              className="TableTabsFilters"
              onChange={(value) => {
                handleTabChange(config.tabs.key, value, null)
                // onTabChange(
                //     value === '' ? {} : { [config.tabs.key]: value }
                // )
              }}
            >
              {config.tabs.options.map((tab) => (
                <TabPane key={tab.key} tab={tab.label} />
              ))}
            </Tabs>
          )}
        </Space>
      </Form>
    </>
  )
}

const FieldType = ({ field, onChange, onTabChange, def }) => {
  useEffect(() => {
    onChange(def)
  }, [])

  switch (field.type) {
    case 'selectSearch':
    case 'select':
      // check if select is static or dinamic
      return Array.isArray(field.options) ? (
        <Form.Item label={field.label} style={{ margin: 0 }}>
          <Select
            // showSearch
            // filterOption={(input, option) =>
            //     option.children
            //         .toLowerCase()
            //         .indexOf(input.toLowerCase()) >= 0
            // }
            defaultValue={field.default}
            onChange={(e) => (field.isTab ? onTabChange(e) : onChange(e))}
            style={{ width: 130 }}
          >
            <Option key={''} value={''}>
              {'All'}
            </Option>
            {field.options.map((option) => (
              <Option key={option.key} value={option.key}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      ) : (
        <LoadingSelect
          speKey={field.key}
          defaultValue={field.default}
          isSearch={field.type === 'selectSearch'}
          label={field.label}
          request={field.options}
          noAll={field.noAll}
          onChange={(e) => (field.isTab ? onTabChange(e) : onChange(e))}
        />
      )
    case 'date':
      return (
        <Form.Item label={field.label} style={{ margin: 0 }}>
          <DatePicker onChange={(e) => onTabChange(e)} style={{ width: 190 }} />
        </Form.Item>
      )
    case 'daterange':
      return (
        <Form.Item label={field.label} style={{ margin: 0 }}>
          <RangePicker
            onCalendarChange={(e) => onTabChange(e)}
            style={{ width: 285 }}
          />
        </Form.Item>
      )
    default:
      // text default
      let label = ''
      if (Array.isArray(field.label)) {
        for (var i = 0; i < field.label.length; i++) {
          label += field.label[i]
          if (i < field.label.length - 1) {
            label += ' / '
          }
        }
      } else {
        label = field.label
      }
      return (
        <Form.Item label={label} style={{ margin: 0 }}>
          <Input
            allowClear
            style={{ width: 175 }}
            // value={value}
            defaultValue={def}
            onChange={(e) => {
              field.isTab
                ? onTabChange(e.target.value)
                : onChange(e.target.value)
            }}
          />
        </Form.Item>
      )
  }
}

// const AutocompleteSelect = ({ field }) => {
//   const [text, setText] = useState(false);
//   const result = useQuery(
//     [
//       // format naming to find request in react query
//       `get${field.key.charAt(0).toUpperCase() + field.key.slice(1)}Select`,
//     ],
//     field.options
//   );
//
//   return (
//     <Form.Item label={field.label} style={{ margin: 0 }}>
//       <Select
//         labelInValue
//         onSelect={() => {}}
//         filterOption={false}
//         // showSearch={true}
//         // onSearch={(e) => console.log('e', e)}
//         notFoundContent={result.isLoading ? <Spin size="small" /> : null}
//         // {...props}
//         // options={result?.data}
//       />
//     </Form.Item>
//   );
// };

export default TableFilter
