export const ReciepForm = {
  title: 'Receipt picture',
  key: [],
  sections: [
    {
      columns: 2,
      fields: [
        {
          type: 'multiupload',
          // isRequired: true,
          requestURI: '/files/order-receipt',
          storageURI: '/storage/order-receipt',
          key: ['receipts'],
          pos: {
            col: 1,
            row: 2,
            size: 1,
          },
        },
      ],
    },
  ],
}

export const AddressForm = {
  title: 'Delivery address',
  isCollapse: true,
  key: [],
  sections: [
    {
      columns: 2,
      fields: [
        {
          label: 'Name',
          placeholder: '',
          key: ['address', 'name'],
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
        {
          label: 'City',
          placeholder: '',
          key: ['address', 'city'],
          pos: {
            col: 1,
            row: 2,
            size: 1,
          },
        },
        {
          label: 'Area',
          placeholder: '',
          key: ['address', 'area'],
          pos: {
            col: 1,
            row: 3,
            size: 1,
          },
        },
        {
          label: 'Zip Code',
          placeholder: '',
          key: ['address', 'zipCode'],
          pos: {
            col: 1,
            row: 4,
            size: 1,
          },
        },
        {
          label: 'Address',
          placeholder: '',
          key: ['address', 'address'],
          pos: {
            col: 1,
            row: 5,
            size: 1,
          },
        },
        {
          label: 'Phone',
          placeholder: '',
          key: ['address', 'phone'],
          pos: {
            col: 1,
            row: 6,
            size: 1,
          },
        },
        {
          label: 'Contact Person',
          placeholder: '',
          key: ['address', 'contactPerson'],
          pos: {
            col: 1,
            row: 7,
            size: 1,
          },
        },
        {
          label: 'Comment',
          placeholder: '',
          key: ['address', 'comment'],
          pos: {
            col: 1,
            row: 8,
            size: 1,
          },
        },
      ],
    },
  ],
}

export const PaymentForm = {
  title: 'Cost details',
  key: [],
  sections: [
    {
      columns: 2,
      fields: [
        {
          label: 'Total spend',
          type: 'number',
          placeholder: '-',
          isInactive: true,
          key: ['totalSpent'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Discount',
          type: 'number',
          placeholder: '-',
          key: ['discount'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Deposit 1',
          type: 'number',
          placeholder: '-',
          key: ['deposit'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Deposit 2',
          type: 'number',
          placeholder: '-',
          key: ['deposit2'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
        {
          label: 'Balance',
          type: 'number',
          placeholder: '-',
          isInactive: true,
          key: ['balance'],
          pos: {
            col: 1,
            // row: 1,
            size: 1,
          },
        },
      ],
    },
  ],
}

// export const EstimateDeliveryDateForm = {
//   title: 'Estimated delivery date',
//   key: [],
//   sections: [
//     {
//       columns: 1,
//       row: 1,
//       fields: [
//         {
//           label: 'Date',
//           type: 'date',
//           isRequired: true,
//           key: ['estimateDeliveryDate'],
//           pos: {
//             col: 1,
//             row: 3,
//             size: 1,
//           },
//         },
//       ],
//     },
//   ],
// }

export const OrderCommentForm = {
  title: 'Comment',
  key: [],
  sections: [
    {
      columns: 1,
      row: 1,
      fields: [
        {
          type: 'text',
          key: ['comment'],
          pos: {
            col: 1,
            size: 3,
          },
        },
      ],
    },
  ],
}
