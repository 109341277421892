import { PageHeader, Button } from 'antd'

import { getStores } from '../../network/API'
import TableBlock from '../../components/tables/TableBlock'
import { ROLE_PERMISSIONS } from '../../constants/common'
import { useAuth } from '../../hooks/useAuth'

export default function ProductFranchises() {
  const { user } = useAuth()
  const permissions = ROLE_PERMISSIONS[user.role]
    ? ROLE_PERMISSIONS[user.role]
    : []

  const filters = (history) => ({})

  const columns = (history) => [
    {
      title: 'Store name',
      key: 'name.en',
      render: (name) => name?.en || name?.zh,
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: 'Store ID',
      key: 'storeID',
      dataIndex: 'storeID',
      sorter: true,
    },
    {
      title: 'Products',
      key: 'products',
      render: (products) => {
        if (!products) return ''
        const displayedProducts = products
          .filter((product) => product.display !== false)
          .map((product) => product.name)
        return displayedProducts.join(', ')
      },

      dataIndex: 'products',
      sorter: true,
    },
    {
      title: '',
      key: 'action',
      width: 25,
      render: (e) => {
        return (
          <Button
            onClick={() => history.push(`product-franchise/${e.id}`)}
            type="primary"
          >
            Edit
          </Button>
        )
      },
    },
  ]

  return (
    <>
      <PageHeader className="site-page-header" title="Product Franchises" />
      <TableBlock
        filters={filters}
        columns={columns}
        name={'getStores'}
        request={getStores}
        infinite
      />
    </>
  )
}
