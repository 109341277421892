import React, { useEffect, useState } from 'react'
import { Button, PageHeader, Form, Input, Row, Col, notification } from 'antd'
import {
  getProductPrice,
  updateProductPrices,
} from '../../network/ProductPrices'
import { useParams } from 'react-router-dom'

// Render each field
const renderFormFields = (data, category) => {
  return data.map((item, index) => (
    <div key={`${category}-${index}`}>
      <Form.Item
        label={`${item.label}`}
        name={`${category}.${index}.price`}
        initialValue={item.price}
        rules={[
          {
            validator: (_, value) =>
              value >= 0
                ? Promise.resolve()
                : Promise.reject(new Error('The price cannot be negative')),
          },
        ]}
      >
        <Input type="number" placeholder={`Enter ${category} price`} />
      </Form.Item>
    </div>
  ))
}

const EditProductPrice = ({ history }) => {
  const [productData, setProductData] = useState(null)
  const [currency, setCurrency] = useState('')
  const [form] = Form.useForm()
  const { key, storeId } = useParams()

  useEffect(() => {
    getProductPrice(key, storeId)
      .then((data) => {
        const product = data.list.product
        if (data.list.currency) {
          switch (data.list.currency) {
            case 'eur':
              setCurrency(' (in euros)')
              break
            case 'usd':
              setCurrency(' (in dollars)')
              break
            case 'gbp':
              setCurrency(' (in pounds)')
              break
            case 'cny':
              setCurrency(' (in yuan)')
              break
            case 'jpy':
              setCurrency(' (in yen)')
              break
            case 'aud':
              setCurrency(' (in Australian dollars)')
              break
            case 'cad':
              setCurrency(' (in Canadian dollars)')
              break
            case 'chf':
              setCurrency(' (in Swiss francs)')
              break
            case 'hkd':
              setCurrency(' (in Hong Kong dollars)')
              break
            case 'nzd':
              setCurrency(' (in New Zealand dollars)')
              break
            default:
              setCurrency('')
          }
        }
        if (product) {
          setProductData(product)

          const initialValues = {
            pricing: product.pricing,
            adjustments: product.adjustments,
          }
          form.setFieldsValue(initialValues)
        } else {
          notification.error({ message: 'Product not found!' })
        }
      })
      .catch((error) => {
        console.error('Failed to fetch product prices:', error)
        notification.error({ message: 'Error fetching product prices!' })
      })
  }, [key, storeId, form])

  const handleSave = (values) => {
    // Rebuild pricing and adjustments objects
    const updatedPricing = productData.pricing.map((item, index) => ({
      ...item,
      price:
        values[`pricing.${index}.price`] !== undefined
          ? Number(values[`pricing.${index}.price`])
          : item.price,
    }))

    const updatedAdjustments = productData.adjustments.map((item, index) => ({
      ...item,
      price:
        values[`adjustments.${index}.price`] !== undefined
          ? Number(values[`adjustments.${index}.price`])
          : item.price,
    }))

    // Rebuild updatedProduct object
    const updatedProduct = {
      ...productData,
      pricing: updatedPricing,
      adjustments: updatedAdjustments,
    }

    if (JSON.stringify(updatedProduct) === JSON.stringify(productData)) {
      notification.info({ message: 'No changes detected!' })
      return
    }

    updateProductPrices(storeId, { [key]: updatedProduct })
      .then(() => {
        notification.success({ message: 'Product price updated successfully' })
        history.push('/product-prices')
      })
      .catch((error) => {
        console.error('Failed to update product prices:', error)
        notification.error({ message: 'Error updating product prices' })
      })
  }

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => history.push('/product-prices')}
        title={`Edit ${productData?.label || ''}`}
      />
      <Row style={{ padding: '24px' }}>
        <Col span={12} style={{ background: 'white', padding: '20px' }}>
          <Form form={form} layout="vertical" onFinish={handleSave}>
            {productData && (
              <>
                <h3 style={{ fontSize: '20px', margin: '20px 0px' }}>
                  Pricing {currency}
                </h3>
                {renderFormFields(productData.pricing, 'pricing')}

                {productData.adjustments.length > 0 && (
                  <h3 style={{ fontSize: '20px', margin: '30px 0px 20px 0px' }}>
                    Adjustments
                  </h3>
                )}
                {renderFormFields(productData.adjustments, 'adjustments')}
              </>
            )}
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col span={12} style={{ background: 'white', padding: '20px' }}></Col>
      </Row>
    </>
  )
}

export default EditProductPrice
