import { Form, PageHeader, Checkbox, Select, Row, Col, message } from 'antd'
import { useQuery } from 'react-query'
import FormWrap from '../../components/forms/FormWrap'
import FormBlock from '../../components/forms/FormBlock'
import { MembersDescriptionForm } from '../../forms/Users'
import { createUser } from '../../network/Users'
import { getStoresBasic } from '../../network/API'
import { useState } from 'react'
import { useAuth } from '../../hooks/useAuth'

const CreateMember = ({ history }) => {
  const { user } = useAuth()
  const [form] = Form.useForm()
  const { data: storesData } = useQuery('getStores', getStoresBasic)
  const allStoresList = storesData?.list || []
  const storesList = user.franchiseStore
    ? allStoresList.filter((store) => store.id === user.currentStore)
    : allStoresList
  const [selectedStores, setSelectedStores] = useState([])

  const handleStoreChange = (checkedValues) => {
    setSelectedStores(checkedValues)
    form.setFieldsValue({ stores: checkedValues })
  }

  const handleSubmit = async (values) => {
    const storesWithRoles = values.stores.map((store) => ({
      store,
      role: values[`role_${store}`],
    }))
    // Set the user role from the default store
    const defaultStoreRole = storesWithRoles.find(
      (store) => store.store === values.defaultStore
    )?.role

    try {
      await createUser({
        name: values.name,
        email: values.email,
        password: values.password,
        stores: storesWithRoles,
        currentStore: values.defaultStore,
        role: defaultStoreRole,
      })
      history.push('/members')
    } catch (error) {
      message.error(
        'An error occurred while creating the user. Please try again.'
      )
    }
  }

  return (
    <>
      <PageHeader
        className="site-page-header"
        title={'Create team member'}
        onBack={() => history.push('/members')}
        style={{ backgroundColor: '#fff' }}
      />
      <FormWrap
        form={form}
        title="create_client"
        request={handleSubmit}
        cancelText={'Cancel'}
        okText={'Save'}
        onSuccess={() => history.push('/members')}
        onCancel={() => history.push('/members')}
        invalidate={['getUsers']}
        checkValidity={(values) => {
          if (!values.stores || values.stores.length === 0) {
            return 'Please assign at least one store with a role.'
          }
          if (!values.password) {
            return 'Please define a password.'
          }
          if (!values.defaultStore) {
            return 'Please define a default store.'
          }
          const missingRoles = values.stores.filter(
            (store) => !values[`role_${store}`]
          )
          if (missingRoles.length > 0) {
            return 'Please assign a role to each selected store.'
          }
          return null
        }}
      >
        <div className="section-from-vertical">
          <FormBlock form={form} formTemplate={MembersDescriptionForm} />
          <div className="user-store">
            <Form.Item
              name="stores"
              label="Stores"
              className="user-store-item"
              style={{ marginBottom: '0px' }}
            >
              <Checkbox.Group
                onChange={handleStoreChange}
                className="user-store-checkbox-group"
              >
                <Row>
                  {storesList.map((store) => (
                    <Col className="user-store-col" span={24} key={store.id}>
                      <Form.Item
                        name={store.id}
                        valuePropName="checked"
                        style={{ display: 'inline-block', marginBottom: 0 }}
                      >
                        <Checkbox
                          value={store.id}
                          className="user-store-checkbox"
                        >
                          {store.name.en}
                        </Checkbox>
                      </Form.Item>
                      <Form.Item
                        className="user-store-selector"
                        name={`role_${store.id}`}
                        style={{
                          display: selectedStores.includes(store.id)
                            ? 'block'
                            : 'none',
                        }}
                      >
                        <Select
                          placeholder="Select Role"
                          style={{ width: '100%' }}
                        >
                          <Select.Option value="admin">Admin</Select.Option>
                          <Select.Option value="store_manager">
                            Store Manager
                          </Select.Option>
                          <Select.Option value="store_assistant">
                            Store Assistant
                          </Select.Option>
                          {!store.franchise && (
                            <>
                              <Select.Option value="marketing">
                                Marketing
                              </Select.Option>
                              <Select.Option value="production">
                                Production
                              </Select.Option>
                            </>
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Form.Item>
            <Form.Item
              name="defaultStore"
              label="Default Store"
              className="user-store-item"
            >
              <Select
                placeholder="Select Default Store"
                style={{ width: '100%' }}
                disabled={selectedStores.length === 0}
              >
                {selectedStores.map((storeId) => {
                  const store = storesList.find((s) => s.id === storeId)
                  return (
                    <Select.Option key={store.id} value={store.id}>
                      {store.name.en}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
          </div>
        </div>
      </FormWrap>
    </>
  )
}

export default CreateMember
