import React, { useEffect, useState } from 'react'
import { getProductPrice } from '../../network/ProductPrices'
import { useParams } from 'react-router-dom'
import { Form, Row, Col, notification, PageHeader } from 'antd'

const renderFormFieldsReadOnly = (data, category) => {
  return data.map((item, index) => (
    <div className="pricing-render" key={`${category}-${index}`}>
      <span>
        <strong>{item.label}:</strong>{' '}
      </span>
      <span>{item.price || 'N/A'}</span>
    </div>
  ))
}

const ViewProductPrices = ({ history }) => {
  const [productData, setProductData] = useState(null)
  const [currency, setCurrency] = useState('')
  const [form] = Form.useForm()
  const { key, storeId } = useParams()

  useEffect(() => {
    getProductPrice(key, storeId)
      .then((data) => {
        const product = data.list.product
        if (data.list.currency) {
          switch (data.list.currency) {
            case 'eur':
              setCurrency(' (in euros)')
              break
            case 'usd':
              setCurrency(' (in dollars)')
              break
            case 'gbp':
              setCurrency(' (in pounds)')
              break
            case 'cny':
              setCurrency(' (in yuan)')
              break
            case 'jpy':
              setCurrency(' (in yen)')
              break
            case 'aud':
              setCurrency(' (in Australian dollars)')
              break
            case 'cad':
              setCurrency(' (in Canadian dollars)')
              break
            case 'chf':
              setCurrency(' (in Swiss francs)')
              break
            case 'hkd':
              setCurrency(' (in Hong Kong dollars)')
              break
            case 'nzd':
              setCurrency(' (in New Zealand dollars)')
              break
            default:
              setCurrency('')
          }
        }
        if (product) {
          setProductData(product)

          const initialValues = {
            pricing: product.pricing,
            adjustments: product.adjustments,
          }
          form.setFieldsValue(initialValues)
        } else {
          notification.error({ message: 'Product not found!' })
        }
      })
      .catch((error) => {
        console.error('Failed to fetch product prices:', error)
        notification.error({ message: 'Error fetching product prices!' })
      })
  }, [key, storeId, form])

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => history.push('/product-prices')}
        title={`Pricing for ${productData?.label || ''}`}
      />
      <Row style={{ padding: '24px' }}>
        <Col span={12} style={{ background: 'white', padding: '20px' }}>
          <Form form={form} layout="vertical">
            {productData && (
              <>
                <h3 style={{ fontSize: '20px', margin: '20px 0px' }}>
                  Pricing {currency}
                </h3>
                {renderFormFieldsReadOnly(productData.pricing, 'pricing')}

                {productData.adjustments.length > 0 && (
                  <h3 style={{ fontSize: '20px', margin: '30px 0px 20px 0px' }}>
                    Adjustments
                  </h3>
                )}
                {renderFormFieldsReadOnly(
                  productData.adjustments,
                  'adjustments'
                )}
              </>
            )}
          </Form>
        </Col>
        <Col span={12} style={{ background: 'white', padding: '20px' }}></Col>
      </Row>
    </>
  )
}

export default ViewProductPrices
