import { PageHeader, Button } from 'antd'

import { getStores } from '../../network/API'
import TableBlock from '../../components/tables/TableBlock'
import { ROLE_PERMISSIONS } from '../../constants/common'
import { useAuth } from '../../hooks/useAuth'

export default function Stores() {
  const { user } = useAuth()
  const isFranchise = user.franchiseStore
  const permissions = ROLE_PERMISSIONS[user.role]
    ? ROLE_PERMISSIONS[user.role]
    : []

  const filters = (history) => ({
    actions: !isFranchise
      ? [
          {
            label: '+ New Store / Distributor',
            isActive: permissions.includes('new_stores'),
            action: () => history.push('/store/create'),
          },
        ]
      : [],
  })

  const columns = (history) => [
    {
      title: 'Store name',
      key: 'name.en',
      render: (name) => name?.en || name?.zh,
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: 'Store ID',
      key: 'storeID',
      dataIndex: 'storeID',
      sorter: true,
    },
    {
      title: 'City',
      key: 'city',
      dataIndex: 'city',
      sorter: true,
    },
    ...(!isFranchise
      ? [
          {
            title: 'Distributor',
            key: 'franchise',
            render: (franchise) => {
              const franchiseStatus = franchise.franchise ? 'Yes' : 'No'
              if (franchiseStatus) {
                return <div>{franchiseStatus}</div>
              }
              return 'Unknown'
            },
          },
        ]
      : []),
    {
      title: '',
      key: 'action',
      width: 25,
      render: (e) => {
        return (
          <Button
            onClick={() => history.push(`store/${e.id}`)}
            type="primary"
            disabled={permissions.includes('edit_stores') ? false : true}
          >
            Edit
          </Button>
        )
      },
    },
  ]

  return (
    <>
      <PageHeader className="site-page-header" title="Stores" />
      <TableBlock
        filters={filters}
        columns={columns}
        name={'getStores'}
        request={getStores}
        pageSize={100}
        infinite
        otherFilters={
          user.franchiseStore || !permissions.includes('can-see-franchises')
            ? { _id: user.currentStore }
            : {}
        }
      />
    </>
  )
}
