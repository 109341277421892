import { Form, PageHeader } from 'antd'

import FormBlock from '../../components/forms/FormBlock'
import FormWrap from '../../components/forms/FormWrap'
import {
  CategoriesFieldsFormCreate,
  CategoriesMediaForm,
  CategoriesPriorityWeightForm,
  CategoriesPublicationForm,
} from '../../forms/Categories'
import { createCategory as requestCat } from '../../network/Categories'
import { useParams } from 'react-router-dom'
import { getProductTypesSortByStore } from '../../network/API'
import { useAuth } from '../../hooks/useAuth'

const CreateCategory = ({ history }) => {
  const [form] = Form.useForm()
  const { storeId } = useParams()
  const { user } = useAuth()

  const CategoriesDescriptionForm = {
    title: 'Description',
    key: [],
    sections: [
      {
        columns: 2,
        fields: [
          {
            label: 'Product Family',
            type: 'select',
            isRequired: true,
            key: ['type'],
            requestOption: async ({ queryKey }) => {
              const filter = { store: storeId }

              return getProductTypesSortByStore({
                queryKey: [
                  'getProductTypesSortByStore',
                  {
                    filter,
                    sort: {},
                    range: {},
                    tabFilter: {},
                  },
                ],
              })
            },
            pos: {
              col: 1,
              row: 1,
              size: 1,
            },
          },
          {
            label: 'Category name (中文)',
            isRequired: true,
            key: ['name', 'zh'],
            hideIfFranchiseStore: true,
            pos: {
              col: 1,
              row: 3,
              size: 1,
            },
          },
          {
            label: 'Category name',
            isRequired: true,
            key: ['name', 'en'],
            pos: {
              col: 1,
              row: 2,
              size: 1,
            },
          },
          {
            label: 'Show on MP',
            type: 'checkbox',
            key: ['showOnMP'],
            hideIfFranchiseStore: true,
            pos: {
              col: 2,
              row: 1,
              size: 1,
            },
          },
          {
            label: 'Family',
            type: 'select',
            isRequired: true,
            hideIfFranchiseStore: true,
            key: ['genre'],
            options: [
              {
                label: 'Menswear',
                key: 'menswear',
              },
              {
                label: 'Fragrance',
                key: 'fragrance',
              },
            ],
            pos: {
              col: 2,
              row: 2,
              size: 1,
            },
          },
        ],
      },
    ],
  }

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Create collection"
        onBack={() => history.push('/categories')}
        style={{ backgroundColor: '#fff' }}
      />
      <FormWrap
        title="create_category"
        request={requestCat}
        cancelText={'Cancel'}
        okText={'Save'}
        onSuccess={() => history.push('/categories')}
        onCancel={() => history.push('/categories')}
        cleanBeforeSending={(oldData) => {
          oldData.store = storeId
          if (oldData.mainPicture) {
            let main = oldData.mainPicture.fileList
              ? oldData.mainPicture?.fileList
              : oldData.mainPicture

            if (!Array.isArray(main)) {
              main = [main]
            }
            const newPic = main.map((file) =>
              file.response ? file.response : file
            )

            oldData.mainPicture = newPic.length ? newPic[0] : {}
          } else {
            oldData.mainPicture = {}
          }
          return oldData
        }}
        invalidate={['getProductCategories']}
      >
        <div className="section-from-vertical">
          <FormBlock form={form} formTemplate={CategoriesDescriptionForm} />
          {!user.franchiseStore && (
            <FormBlock form={form} formTemplate={CategoriesMediaForm} />
          )}
          <FormBlock form={form} formTemplate={CategoriesFieldsFormCreate} />
          <FormBlock.Basic formTemplate={CategoriesPublicationForm} />
          <FormBlock.Basic formTemplate={CategoriesPriorityWeightForm} />
        </div>
      </FormWrap>
    </>
  )
}

export default CreateCategory
