import { Form, PageHeader, Button, Checkbox, Select, Row, Col } from 'antd'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import FormWrap from '../../components/forms/FormWrap'
import FormBlock from '../../components/forms/FormBlock'
import { MembersDescriptionForm } from '../../forms/Users'
import { getUserDetails, updateUser, deleteUser } from '../../network/Users'
import Loader from '../../components/Loader'
import Error from '../../components/Error'
import { getStoresBasic } from '../../network/API'
import { useState, useEffect } from 'react'
import { useAuth } from '../../hooks/useAuth'

const EditMember = ({ history }) => {
  let { memberId } = useParams()
  const { user } = useAuth()
  const [form] = Form.useForm()
  const queryClient = useQueryClient()
  const { refreshStore } = useAuth()

  const memberDetails = useQuery(
    ['getMemberDetails', { id: memberId }],
    getUserDetails,
    { refetchOnWindowFocus: false }
  )

  const deleteMember = useMutation((id) => deleteUser(id), {
    onSuccess: () => {
      queryClient.invalidateQueries('getUsers')
      history.push('/members')
    },
  })

  const { data: storesData } = useQuery('getStores', getStoresBasic)
  const allStoresList = storesData?.list || []
  const storesList = user.franchiseStore
    ? allStoresList.filter((store) => store.id === user.currentStore)
    : allStoresList
  const [selectedStores, setSelectedStores] = useState([])

  useEffect(() => {
    if (memberDetails.data) {
      const initialSelectedStores =
        memberDetails.data.stores.map((store) => store.store) || []
      setSelectedStores(initialSelectedStores)
      form.setFieldsValue({
        stores: initialSelectedStores,
        currentStore: memberDetails.data.currentStore,
      })
      initialSelectedStores.forEach((store) => {
        const role =
          memberDetails.data.stores.find((s) => s.store === store)?.role || ''
        form.setFieldsValue({ [`role_${store}`]: role })
      })
    }
  }, [memberDetails.data, form])

  const handleSubmit = async (values) => {
    const storesWithRoles = values.stores
      .map((store) => ({
        store,
        role: values[`role_${store}`] || null,
      }))
      .filter((item) => item.role) // Filter to include only those with roles

    // Set the user role from the default store
    const currentStoreRole = storesWithRoles.find(
      (store) => store.store === values.currentStore
    )?.role

    const payload = {
      name: values.name,
      email: values.email,
      password: values.password,
      stores: storesWithRoles,
      currentStore: values.currentStore,
      role: currentStoreRole,
    }
    await updateUser(payload, memberId)
    refreshStore()
    history.push('/members')
  }

  const handleStoreChange = (checkedValues) => {
    setSelectedStores(checkedValues)
    form.setFieldsValue({ stores: checkedValues }) // Update the form with selected stores
  }

  return (
    <>
      <PageHeader
        className="site-page-header"
        title={'Edit team member'}
        onBack={() => history.push('/members')}
        style={{ backgroundColor: '#fff' }}
        extra={[
          <Button onClick={() => deleteMember.mutate(memberId)} danger>
            Delete
          </Button>,
        ]}
      />
      {memberDetails.isError ? (
        <Error retry={() => memberDetails.refetch()} />
      ) : memberDetails.isLoading ? (
        <Loader />
      ) : (
        <FormWrap
          id={memberId}
          form={form}
          title="update_member"
          request={handleSubmit}
          cancelText={'Cancel'}
          okText={'Save'}
          data={memberDetails.data}
          onCancel={() => history.push('/members')}
          invalidate={['getUsers', 'getMemberDetails']}
          checkValidity={(values) => {
            if (!values.stores || values.stores.length === 0) {
              return 'Please assign at least one store with a role.'
            }
            if (!values.currentStore) {
              return 'Please define a current store.'
            }
            const missingRoles = values.stores.filter(
              (store) => !values[`role_${store}`]
            )
            if (missingRoles.length > 0) {
              return 'Please assign a role to each selected store.'
            }
            return null
          }}
        >
          <div className="section-from-vertical">
            <FormBlock form={form} formTemplate={MembersDescriptionForm} />
            <div className="user-store">
              <Form.Item
                name="stores"
                label="Stores"
                className="user-store-item"
              >
                <Checkbox.Group
                  onChange={handleStoreChange}
                  className="user-store-checkbox-group"
                >
                  <Row>
                    {storesList.map((store) => (
                      <Col className="user-store-col" span={24} key={store.id}>
                        <Form.Item
                          name={store.id}
                          valuePropName="checked"
                          style={{ display: 'inline-block', marginBottom: 0 }}
                        >
                          <Checkbox
                            value={store.id}
                            className="user-store-checkbox"
                          >
                            {store.name.en}
                          </Checkbox>
                        </Form.Item>
                        <Form.Item
                          className="user-store-selector"
                          name={`role_${store.id}`}
                          style={{
                            display: selectedStores.includes(store.id)
                              ? 'block'
                              : 'none',
                          }}
                        >
                          <Select
                            placeholder="Select Role"
                            style={{ width: '100%' }}
                          >
                            <Select.Option value="admin">Admin</Select.Option>
                            <Select.Option value="store_manager">
                              Store Manager
                            </Select.Option>
                            <Select.Option value="store_assistant">
                              Store Assistant
                            </Select.Option>
                            {!store.franchise && (
                              <>
                                <Select.Option value="marketing">
                                  Marketing
                                </Select.Option>
                                <Select.Option value="production">
                                  Production
                                </Select.Option>
                              </>
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                    ))}
                  </Row>
                </Checkbox.Group>
              </Form.Item>

              <Form.Item
                name="currentStore"
                label="Current Store"
                className="user-store-item"
              >
                <Select
                  placeholder="Select Current Store"
                  style={{ width: '100%' }}
                  disabled={selectedStores.length === 0} // Disable if no stores are selected
                >
                  {selectedStores.map((storeId) => {
                    const store = storesList.find((s) => s.id === storeId)
                    return store ? ( // Check if store exists
                      <Select.Option key={store.id} value={store.id}>
                        {store.name.en}
                      </Select.Option>
                    ) : null
                  })}
                </Select>
              </Form.Item>
            </div>
          </div>
        </FormWrap>
      )}
    </>
  )
}

export default EditMember
