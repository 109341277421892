import { Button, PageHeader } from 'antd'

import TableBlock from '../../components/tables/TableBlock'
import { getUniforms } from '../../network/B2B'
import { ROLE_PERMISSIONS } from '../../constants/common'
import { useAuth } from '../../hooks/useAuth'

const Uniforms = () => {
  const { user } = useAuth()
  const permissions = ROLE_PERMISSIONS[user.role]
    ? ROLE_PERMISSIONS[user.role]
    : []

  const filters = (history) => ({
    fields: [
      {
        type: 'text',
        label: 'Name',
        key: 'name',
        // noRegex: true,
      },
    ],
    actions: [
      {
        label: '+ Create an uniform',
        isActive: permissions.includes('new_uniforms'),
        action: () => history.push(`/uniforms/create/${user.currentStore}`),
      },
    ],
  })

  const columns = (history) => [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: '',
      key: 'action',
      width: 25,
      render: (e) => {
        return (
          <Button
            onClick={() => history.push(`/uniforms/${e.id}`)}
            type="primary"
            disabled={permissions.includes('edit_uniforms') ? false : true}
          >
            Edit
          </Button>
        )
      },
    },
  ]

  return (
    <>
      <PageHeader className="site-page-header" title="Uniforms" />
      <TableBlock
        columns={columns}
        filters={filters}
        name={'getUniforms'}
        request={getUniforms}
        infinite
        otherFilters={
          user.franchiseStore || !permissions.includes('can-see-franchises')
            ? { store: user.currentStore }
            : {}
        }
      />
    </>
  )
}

export default Uniforms
