const axios = require('axios')

export const SERVER = { baseURL: process.env.REACT_APP_API_URL }

export const INSTANCE = axios.create(SERVER)
export const INSTANCELIST = axios.create(SERVER)

INSTANCE.interceptors.response.use(async (response) => {
  console.log('Response:', response)

  return response.data
})

INSTANCELIST.interceptors.response.use(async (response) => {
  // console.log('Response:', response)

  return {
    range: response.headers['content-range']?.split('/')[1],
    list: response.data,
  }
})

export const upload = (file, uri) =>
  new Promise((resolve, reject) => {
    var formData = new FormData()
    formData.append('file', file)

    INSTANCE({
      method: 'post',
      url: uri,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: formData,
    })
      .then(resolve)
      .catch(reject)
  })

export const getProductTypesSortByStore = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const { sort, range, filter, tabFilter } = queryKey[1]
    INSTANCELIST({
      method: 'get',
      url: `/product-types/`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      params: {
        sort: JSON.stringify(sort),
        range: JSON.stringify(range),
        filter: JSON.stringify(filter),
        ...tabFilter,
      },
    })
      .then(resolve)
      .catch(reject)
  })

export const getProductCategoriesBasic = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const { sort, range, filter, tabFilter } = queryKey[1]
    INSTANCELIST({
      method: 'get',
      url: '/product-categories',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      params: {
        sort: JSON.stringify(sort),
        range: JSON.stringify(range),
        filter: JSON.stringify(filter),
        ...tabFilter,
      },
    })
      .then(resolve)
      .catch(reject)
  })

export const getProductsBasic = ({ collectionId }) => {
  const params = new URLSearchParams()
  collectionId && params.append('category', collectionId)
  params.append('targetAudience', 'public')

  return new Promise((resolve, reject) => {
    INSTANCELIST({
      method: 'get',
      url: `/products/details?${params.toString()}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(resolve)
      .catch(reject)
  })
}

export const getProductsForMP = ({ collectionId }) => {
  const params = new URLSearchParams()
  collectionId && params.append('category', collectionId)
  params.append('targetAudience', 'public')
  params.append('populate', 'category')

  return new Promise((resolve, reject) => {
    INSTANCELIST({
      method: 'get',
      url: `/products/details?${params.toString()}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(resolve)
      .catch(reject)
  })
}

export const getStoresBasic = () =>
  new Promise((resolve, reject) => {
    INSTANCELIST({
      method: 'get',
      url: '/stores',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(resolve)
      .catch(reject)
  })

export const getStores = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const { sort, range, filter, tabFilter } = queryKey[1]

    INSTANCELIST({
      method: 'get',
      url: '/stores',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      params: {
        sort: JSON.stringify(sort),
        range: JSON.stringify(range),
        filter: JSON.stringify(filter),
        ...tabFilter,
      },
    })
      .then(resolve)
      .catch(reject)
  })

export const getMetrics = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const { type, date, store } = queryKey[1]

    INSTANCE({
      method: 'get',
      url: '/orders/analytics',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      params: {
        type,
        month: date?.getMonth() + 1 || null,
        year: date?.getFullYear() || null,
        store,
      },
    })
      .then(resolve)
      .catch(reject)
  })
