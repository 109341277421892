import { Button, PageHeader } from 'antd'
import TableBlock from '../../components/tables/TableBlock'
import { ROLE_PERMISSIONS } from '../../constants/common'
import { useAuth } from '../../hooks/useAuth'
import { getProductTags } from '../../network/Tags'

export default function ProductTags() {
  const { user } = useAuth()
  const permissions = ROLE_PERMISSIONS[user.role]
    ? ROLE_PERMISSIONS[user.role]
    : []

  const columns = (history, keyRequest) => {
    const franchiseStore = user.franchiseStore
    return [
      {
        title: 'Name',
        key: 'name.en',
        dataIndex: 'name',
        render: (name) => name.en,
      },
      ...(franchiseStore
        ? []
        : [
            {
              title: 'Name (Chinese)',
              key: 'name.zh',
              dataIndex: 'name',
              render: (name) => name.zh,
            },
          ]),
      {
        title: '',
        key: 'action',
        fixed: 'right',
        render: (e) => {
          return (
            <div className={e.status}>
              <Button
                type="primary"
                onClick={() => {
                  history.push(`product-tags/${e.id}`)
                }}
              >
                Edit
              </Button>
            </div>
          )
        },
      },
    ]
  }

  const filters = (history) => ({
    actions: [
      {
        label: '+ New Tag',
        isActive: permissions.includes('product-tags'),
        action: () => {
          history.push(`/product-tags/create/${user.currentStore}`)
        },
      },
    ],
  })

  return (
    <>
      <PageHeader
        className={'site-page-header'}
        title={'Product Tags'}
        subTitle={'Manage product tags'}
      />
      <TableBlock
        columns={columns}
        filters={filters}
        name={'getProductTags'}
        request={getProductTags}
        otherFilters={{ store: user.currentStore }} // Sort the tag by store
      />
    </>
  )
}
