import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import get from 'lodash.get'
import set from 'lodash.set'
import merge from 'lodash.merge'
import moment from 'moment'
import { findLastIndex, threeDParsing } from '../../utils/utils'
import {
  Button,
  Card,
  Form as StyleForm,
  Input,
  InputNumber,
  message,
  PageHeader,
  Select,
  Space,
  Typography,
} from 'antd'
import { FieldArray, Form, Formik } from 'formik'
import {
  getOrderDetails,
  getOrdersMine,
  updateOrder,
} from '../../network/Orders'
import { getProductsBasic } from '../../network/API'
import {
  get3Dlook,
  getClientDetails,
  updateClient,
} from '../../network/Clients'
import FormContent from './FormContent'
import { formOptions, Payment } from '../../forms/blocks'

import {
  BirthdayFormOrder,
  ClientsBodyMeasurementFormExt,
  ClientsBodyMeasurementFormOrder,
  ClientsFootForm,
  OrderBodyPics,
  OrderComplexionFormP1,
  OrderComplexionFormP2,
  TDLookForm,
} from '../../forms/Clients'
import Loader from '../../components/Loader'
import Error from '../../components/Error'

const { Text, Title } = Typography

const getFromKey = (prod) =>
  prod.orderProductType === 'custom'
    ? prod.orderCustom?.type
    : prod.orderProductType

const CreateOrder = ({ history }) => {
  const [canDirectPayment, setCanDirectPayment] = useState(true)
  let { orderId } = useParams()
  const myRef = useRef(null)
  const [step, setStep] = useState(1)
  const [innerStep, setInnerStep] = useState(0)
  const [afterStep3, setAfterStep3] = useState(false)
  const queryClient = useQueryClient()

  const orderDetails = useQuery(
    [
      'getOrderDetails',
      {
        id: orderId,
        onSuccess: (data) => {
          if (get(data, 'validated', false)) {
            history.push(`/orders/${orderId}`)
          }
        },
      },
    ],
    getOrderDetails,
    {
      refetchOnWindowFocus: false,
    }
  )

  const clientDetails = useQuery(
    [
      'getClientDetails',
      {
        id: orderDetails.data?.customer,
      },
    ],
    getClientDetails,
    {
      disabled: !orderDetails.data?.customer,
      refetchOnWindowFocus: false,
    }
  )

  const client3DLDetails = useQuery(
    [
      '3DLook',
      {
        id: orderDetails.data?.customer,
      },
    ],
    () => get3Dlook(orderDetails.data?.customer),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      disabled: !orderDetails.data?.customer,
    }
  )

  const clientOrders = useQuery(
    [
      'getOrdersMine',
      {
        sort: ['createdAt', 'ASC'],
        filter: {
          customer: orderDetails.data?.customer,
        },
      },
    ],
    getOrdersMine,
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  )

  const udpateOrder = useMutation((e) => updateOrder(e, orderId), {
    onSuccess: () => history.push('/orders'),
    onError: (error) => {
      message.error(
        error.response?.data?.debugLog?.message ||
          error.response?.data?.message ||
          'An error occurs'
      )
    },
  })

  const saveUser = useMutation(
    (val) => updateClient(val, orderDetails.data?.customer)
    // {
    //   onSuccess: message.success('User info saved'),
    // }
  )

  const handleBack = () => {
    if (step === 1) {
      history.push(`/orders/${orderId}`)
    } else {
      myRef.current?.scrollIntoView()
      setStep(step - 1)
      // if (step === 4) setAfterStep3(false)
    }
  }

  const handleNext = () => {
    if (step === 5) {
      history.push('/orders')
    } else {
      myRef.current?.scrollIntoView()
      setStep(step + 1)
      // if (step === 3) setAfterStep3(true)
    }
  }

  const saveUserInfos = async (values) => {
    let clientInfos = get(clientDetails, 'data', {})

    let bodyMeasurements = undefined
    if (get(clientInfos, 'bodyMeasurements.measurementSource', '') === 'af') {
      bodyMeasurements = {
        ...get(clientInfos, 'bodyMeasurements', {}),
        ...get(values, 'bodyMeasures', {}),
      }
    }

    const newInfos = {
      ...clientInfos,
      complexion: {
        ...get(clientInfos, 'complexion', {}),
        ...get(values, 'complexion', {}),
      },
      bodyPictures: get(values, 'bodyPictures', []),
      bodyMeasurements,
      foot: { ...get(clientInfos, 'foot', {}), ...get(values, 'foot', {}) },

      birthDate: get(values, 'birthDate', undefined),
      country: get(values, 'country', undefined),
      city: get(values, 'city', undefined),
      nationality: get(values, 'nationality', undefined),
    }

    saveUser.mutate(newInfos)
  }

  const saveBeforeSwitch = (values) => {
    let clientInfos = get(clientDetails, 'data', {})

    const newInfos = {
      ...clientInfos,
      complexion: {
        ...get(clientInfos, 'complexion', {}),
        ...get(values, 'complexion', {}),
      },
      bodyPictures: get(values, 'bodyPictures', []),
      bodyMeasurements: get(values, 'bodyMeasures', {}),
      foot: { ...get(clientInfos, 'foot', {}), ...get(values, 'foot', {}) },

      birthDate: get(values, 'birthDate', undefined),
      country: get(values, 'country', undefined),
      city: get(values, 'city', undefined),
      nationality: get(values, 'nationality', undefined),
    }

    saveUser.mutate(newInfos)

    queryClient.setQueryData(
      [
        'getClientDetails',
        {
          id: orderDetails.data?.customer,
        },
      ],
      (oldData) => ({ ...oldData, ...newInfos })
    )
  }

  return (
    <div className="" ref={myRef}>
      <PageHeader
        className="site-page-header"
        title="Create order"
        onBack={() => history.push(`/orders/${orderId}`)}
        style={{ backgroundColor: '#fff' }}
      />
      {orderDetails.isError || clientDetails.isError ? (
        <Error retry={() => orderDetails.refetch()} />
      ) : orderDetails.isLoading || clientDetails.isLoading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={{
            ...get(orderDetails, 'data', {}),

            birthDate: clientDetails?.data?.birthDate
              ? moment(clientDetails?.data?.birthDate)
              : undefined,
            country: get(clientDetails, 'data.country', undefined),
            city: get(clientDetails, 'data.city', undefined),
            nationality: get(clientDetails, 'data.nationality', undefined),
          }}
          validate={(values) => {
            const errors = {}
            if (step === 1) {
              if (!values.orderProducts?.length) {
                errors.orderProducts = 'At least a product is required'
              } else {
                for (let i = 0; i < values.orderProducts.length; i++) {
                  const path = ['orderProducts', i]
                  const currentProduct = values.orderProducts[i]
                  if (
                    currentProduct.orderProductType !== 'collection' &&
                    currentProduct.orderProductType !== 'adjustment'
                  ) {
                    if (!get(currentProduct, `orderCustom.price`, null)) {
                      set(
                        errors,
                        `orderProducts.${i}.orderCustom.price`,
                        'Required'
                      )
                    }

                    formOptions[getFromKey(currentProduct)]?.forms?.forEach(
                      (form) =>
                        form.form.forEach((prodForm) =>
                          prodForm.sections.forEach((section) => {
                            if (section.canAdd) {
                              section.fields.forEach((field) => {
                                const type = getFromKey(currentProduct)
                                if (type === 'shirt') {
                                  const extraShirts =
                                    currentProduct.orderCustom.orderCustomShirt
                                      .extraShirts
                                  if (extraShirts) {
                                    //validation
                                    extraShirts.forEach((item, index) => {
                                      const fieldValue = get(
                                        values,
                                        [
                                          ...path,
                                          form.key,
                                          ...prodForm.key,
                                          ...section.key,
                                          index,
                                          ...field.key,
                                        ].join('.'),
                                        ''
                                      )

                                      const name =
                                        [
                                          ...path,
                                          form.key,
                                          ...prodForm.key,
                                          ...section.key,
                                          index,
                                        ].join('.') + '.'
                                      const fieldIsRequired =
                                        field.isConditionalRequired
                                          ? field.isConditionalRequired(
                                              values,
                                              name
                                            )
                                          : !!field.isRequired
                                      const valueIsOk =
                                        !!fieldValue || fieldValue === 0
                                      const valueIsDisplay =
                                        !field.isHiding ||
                                        (field.isHiding &&
                                          !field.isHiding(values, name))

                                      if (
                                        fieldIsRequired &&
                                        !valueIsOk &&
                                        valueIsDisplay
                                      ) {
                                        set(
                                          errors,
                                          [
                                            ...path,
                                            form.key,
                                            ...prodForm.key,
                                            ...section.key,
                                            index,
                                            ...field.key,
                                          ].join('.'),
                                          'This field is required'
                                        )
                                      }
                                    })
                                  }
                                }
                              })
                            } else {
                              section.fields.forEach((field) => {
                                const fieldValue = get(
                                  values,
                                  [
                                    ...path,
                                    form.key,
                                    ...prodForm.key,
                                    ...field.key,
                                  ].join('.'),
                                  ''
                                )
                                const name =
                                  [...path, form.key, ...prodForm.key].join(
                                    '.'
                                  ) + '.'
                                const fieldIsRequired =
                                  field.isConditionalRequired
                                    ? field.isConditionalRequired(values, name)
                                    : !!field.isRequired
                                const valueIsOk =
                                  !!fieldValue || fieldValue === 0
                                const valueIsDisplay =
                                  !field.isHiding ||
                                  (field.isHiding &&
                                    !field.isHiding(values, name))
                                if (
                                  fieldIsRequired &&
                                  !valueIsOk &&
                                  valueIsDisplay
                                ) {
                                  set(
                                    errors,
                                    [
                                      ...path,
                                      form.key,
                                      ...prodForm.key,
                                      ...field.key,
                                    ].join('.'),
                                    'This field is required'
                                  )
                                }
                              })
                            }
                          })
                        )
                    )
                  } else if (currentProduct.orderProductType === 'adjustment') {
                    const adjustmentProduct = get(
                      values,
                      `orderProducts.${i}.orderAdjustment`,
                      []
                    )
                    if (!adjustmentProduct.type) {
                      set(
                        errors,
                        `orderProducts.${i}.orderAdjustment.type`,
                        'Select a product type'
                      )
                    } else {
                      formOptions[adjustmentProduct.type].forms.forEach(
                        (form) =>
                          form.form.forEach((prodForm) =>
                            prodForm.sections.forEach((section) =>
                              section.fields.forEach((field) => {
                                const fieldValue = get(
                                  values,
                                  [
                                    ...path,
                                    form.key.replace(
                                      'orderCustom.',
                                      'orderAdjustment.'
                                    ),
                                    ...prodForm.key,
                                    ...field.key,
                                  ].join('.'),
                                  ''
                                )

                                const name =
                                  [
                                    ...path,
                                    form.key.replace(
                                      'orderCustom.',
                                      'orderAdjustment.'
                                    ),
                                    ...prodForm.key,
                                  ].join('.') + '.'
                                const fieldIsRequired =
                                  field.isConditionalRequired
                                    ? field.isConditionalRequired(values, name)
                                    : !!field.isRequired
                                const valueIsOk =
                                  !!fieldValue || fieldValue === 0
                                const valueIsDisplay =
                                  !field.isHiding ||
                                  (field.isHiding &&
                                    !field.isHiding(values, name))
                                if (
                                  fieldIsRequired &&
                                  !valueIsOk &&
                                  valueIsDisplay
                                ) {
                                  set(
                                    errors,
                                    [
                                      ...path,
                                      form.key.replace(
                                        'orderCustom.',
                                        'orderAdjustment.'
                                      ),
                                      ...prodForm.key,
                                      ...field.key,
                                    ].join('.'),
                                    'This field is required'
                                  )
                                }
                              })
                            )
                          )
                      )
                    }
                  } else {
                    const collectionProduct = get(
                      values,
                      `orderProducts.${i}.orderCollection`,
                      []
                    )

                    if (!collectionProduct.product) {
                      set(
                        errors,
                        `orderProducts.${i}.orderCollection.product`,
                        'Select product'
                      )
                    } else {
                      get(collectionProduct, 'optionsTemp', []).forEach(
                        (option, index) => {
                          if (!option) {
                            set(
                              errors,
                              `orderProducts.${i}.orderCollection.optionsTemp.${index}`,
                              'All options are required'
                            )
                          }
                        }
                      )
                    }

                    if (
                      !collectionProduct.price &&
                      collectionProduct.price !== 0
                    ) {
                      set(
                        errors,
                        `orderProducts.${i}.orderCollection.price`,
                        'The price is required'
                      )
                    }
                  }
                }
              }
            }

            if (step === 2) {
              if (!values.bodyMeasures?.height) {
                set(errors, 'bodyMeasures.height', 'Required')
              }
              if (!values.bodyMeasures?.weight) {
                set(errors, 'bodyMeasures.weight', 'Required')
              }
              if (!values.bodyMeasures?.shoulder) {
                set(errors, 'bodyMeasures.shoulder', 'Required')
              }
              if (!values.bodyMeasures?.chest) {
                set(errors, 'bodyMeasures.chest', 'Required')
              }
              // if (!values.bodyMeasures?.waist) {
              //   set(errors, 'bodyMeasures.waist', 'Required')
              // }

              if (!values.bodyMeasures?.waistJacket) {
                set(errors, 'bodyMeasures.waistJacket', 'Required')
              }

              if (!values.bodyMeasures?.stomach) {
                set(errors, 'bodyMeasures.stomach', 'Required')
              }
              if (!values.bodyMeasures?.hips) {
                set(errors, 'bodyMeasures.hips', 'Required')
              }
              if (!values.bodyMeasures?.biceps) {
                set(errors, 'bodyMeasures.biceps', 'Required')
              }
              if (!values.bodyMeasures?.forearm) {
                set(errors, 'bodyMeasures.forearm', 'Required')
              }
              if (!values.bodyMeasures?.wrist) {
                set(errors, 'bodyMeasures.wrist', 'Required')
              }
              if (!values.bodyMeasures?.armHole) {
                set(errors, 'bodyMeasures.armHole', 'Required')
              }
              if (!values.bodyMeasures?.thigh) {
                set(errors, 'bodyMeasures.thigh', 'Required')
              }
              if (!values.bodyMeasures?.knee) {
                set(errors, 'bodyMeasures.knee', 'Required')
              }
              if (!values.bodyMeasures?.calf) {
                set(errors, 'bodyMeasures.calf', 'Required')
              }
              if (!values.bodyMeasures?.neck) {
                set(errors, 'bodyMeasures.neck', 'Required')
              }

              if (!values.bodyMeasures?.waistPant) {
                set(errors, 'bodyMeasures.waistPant', 'Required')
              }
              if (!values.bodyMeasures?.sideNeckPoint) {
                set(errors, 'bodyMeasures.sideNeckPoint', 'Required')
              }

              if (!values.foot?.footMeasure && values.foot?.footMeasure !== 0) {
                set(errors, 'foot.footMeasure', 'Required')
              }
              // if (!values.foot?.strongFoot) {
              //   set(errors, 'foot.strongFoot', 'Required')
              // }

              if (!values.bodyMeasures?.ankle) {
                set(errors, 'bodyMeasures.ankle', 'Required')
              }
            }

            if (step === 3) {
              // innerStep
              const path = ['orderProducts', innerStep]
              const currentProduct = values.orderProducts[innerStep]

              if (!currentProduct) return

              if (currentProduct.orderProductType === 'collection') {
                // to do check collection errors
                const collectionType = get(
                  currentProduct,
                  'orderCollection.productType',
                  []
                )

                for (const prodKey of collectionType) {
                  formOptions[prodKey].measuresForms.forEach((form) =>
                    form.form.forEach((prodForm) =>
                      prodForm.sections.forEach((section) =>
                        section.fields.forEach((field) => {
                          const name = [
                            ...path,
                            form.key,
                            ...prodForm.key,
                          ].join('.')
                          const fieldValue = get(
                            values,
                            name + '.' + field.key.join('.'),
                            ''
                          )

                          const fieldIsRequired = field.isConditionalRequired
                            ? field.isConditionalRequired(values, name + '.')
                            : !!field.isRequired

                          if (
                            fieldIsRequired &&
                            !fieldValue &&
                            fieldValue !== 0
                          ) {
                            set(
                              errors,
                              name + '.' + field.key.join('.'),
                              'This field is required'
                            )
                          }
                        })
                      )
                    )
                  )
                }
              } else if (currentProduct.orderProductType === 'adjustment') {
                // formOptions[currentProduct.type].measuresForms.forEach((form) =>
                //   form.form.forEach((prodForm) =>
                //     prodForm.sections.forEach((section) =>
                //       section.fields.forEach((field) => {
                //         const fieldValue = get(
                //           values,
                //           [
                //             ...path,
                //             form.key.replace(
                //               'orderCustom.',
                //               'orderAdjustment.'
                //             ),
                //             ...prodForm.key,
                //             ...field.key,
                //           ].join('.'),
                //           ''
                //         )
                //         if (
                //           field.isRequired &&
                //           !fieldValue &&
                //           fieldValue !== 0
                //         ) {
                //           set(
                //             errors,
                //             [
                //               ...path,
                //               form.key.replace(
                //                 'orderCustom.',
                //                 'orderAdjustment.'
                //               ),
                //               ...prodForm.key,
                //               ...field.key,
                //             ].join('.'),
                //             'This field is required'
                //           )
                //         }
                //       })
                //     )
                //   )
                // )
              } else {
                get(
                  formOptions,
                  `${getFromKey(currentProduct)}.measuresForms`,
                  []
                ).forEach(
                  // formOptions[getFromKey(currentProduct)].measuresForms.forEach(
                  (form) =>
                    form.form.forEach((prodForm) =>
                      prodForm.sections.forEach((section) =>
                        section.fields.forEach((field) => {
                          const name = [
                            ...path,
                            form.key,
                            ...prodForm.key,
                          ].join('.')

                          const fieldValue = get(
                            values,
                            name + '.' + field.key.join('.'),
                            ''
                          )

                          const fieldIsRequired = field.isConditionalRequired
                            ? field.isConditionalRequired(values, name + '.')
                            : !!field.isRequired

                          if (
                            fieldIsRequired &&
                            !fieldValue &&
                            fieldValue !== 0
                          ) {
                            set(
                              errors,
                              name + '.' + field.key.join('.'),
                              'This field is required'
                            )
                          }
                        })
                      )
                    )
                )
              }
            }

            return errors
          }}
          onSubmit={async (values) => {
            let paymentStatus = 'unpaid'

            if (values.balance === 0) {
              paymentStatus = 'paid'
            } else if (values.deposit || values.deposit2) {
              paymentStatus = 'deposit'
            }

            const orderProducts = values.orderProducts.map((prod) =>
              prod.orderProductType === 'collection'
                ? {
                    ...prod,
                    orderCollection: {
                      ...prod.orderCollection,
                      options: prod.orderCollection.options.map((option) => ({
                        ...option,
                        subOptions: option.subOptions.map((sub) => ({
                          ...sub,
                          selected: prod.orderCollection.optionsTemp.includes(
                            sub._id
                          ),
                        })),
                      })),
                    },
                  }
                : prod
            )

            saveUserInfos({
              detailedAddress: get(values, 'detailedAddress', ''),
            })
            udpateOrder.mutate({ ...values, orderProducts, paymentStatus })
          }}
        >
          {({ values, errors, handleChange, validateForm }) => (
            <Form style={{ padding: 24 }}>
              <Space direction="vertical" style={{ width: '100%' }}>
                {step === 1 && (
                  <Step1
                    values={values}
                    errors={errors}
                    validateForm={validateForm}
                    handleChange={handleChange}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    goToPayment={() => setStep(5)}
                    canDirectPayment={canDirectPayment}
                    setCanDirectPayment={setCanDirectPayment}
                  />
                )}
                {step === 2 && (
                  <Step2
                    values={values}
                    errors={errors}
                    validateForm={validateForm}
                    handleChange={handleChange}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    saveUserInfos={() => saveUserInfos(values)}
                    saveBeforeSwitch={() => saveBeforeSwitch(values)}
                    clientDetails={{
                      af: clientDetails.data,
                      dlook: client3DLDetails.data,
                    }}
                    goToPayment={() => setStep(5)}
                    canDirectPayment={canDirectPayment}
                  />
                )}
                {step === 3 && (
                  <Step3
                    values={values}
                    errors={errors}
                    validateForm={validateForm}
                    innerStep={innerStep}
                    setInnerStep={setInnerStep}
                    afterStep3={afterStep3}
                    setAfterStep3={setAfterStep3}
                    handleChange={handleChange}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    goToPayment={() => setStep(5)}
                    clientOrders={clientOrders}
                    canDirectPayment={canDirectPayment}
                    setCanDirectPayment={setCanDirectPayment}
                  />
                )}
                {step === 4 && (
                  <Step4
                    values={values}
                    errors={errors}
                    // validateForm={validateForm}
                    handleChange={handleChange}
                  />
                )}
                {step === 5 && (
                  <Step5
                    values={values}
                    errors={errors}
                    // validateForm={validateForm}
                    handleChange={handleChange}
                    orderDetails={orderDetails}
                  />
                )}
                {step !== 1 && step !== 2 && step !== 3 && (
                  <Space>
                    <Button
                      disabled={udpateOrder.isLoading}
                      onClick={handleBack}
                      type="secondary"
                    >
                      Previous
                    </Button>
                    {step !== 5 && (
                      <Button
                        disabled={Object.keys(errors).length}
                        onClick={() => {
                          if (step === 4) saveUserInfos(values)
                          handleNext()
                        }}
                        type="primary" /*htmlType="submit"*/
                      >
                        {step === 4 ? 'Payment' : 'Next'}
                      </Button>
                    )}
                    {step === 5 && (
                      <Button
                        loading={udpateOrder.isLoading}
                        disabled={Object.keys(errors).length}
                        // onClick={handleNext}
                        type="primary"
                        htmlType="submit"
                      >
                        Finish order
                      </Button>
                    )}
                  </Space>
                )}
              </Space>
            </Form>
          )}
        </Formik>
      )}
    </div>
  )
}

export default CreateOrder

const Step1 = ({
  values,
  errors,
  handleChange,
  handleBack,
  handleNext,
  validateForm,
  goToPayment,
  canDirectPayment,
  setCanDirectPayment,
}) => {
  const [isValidated, setIsValidated] = useState(false)
  const goNextPage = () => {
    // for (let i = 0; i < values.orderProducts.length; i++) {
    //   const currentProduct = values.orderProducts[i]
    //   const type = getFromKey(currentProduct)
    //   const forms =
    //     formOptions[
    //       type === 'adjustment' ? currentProduct.orderAdjustment.type : type
    //     ].forms

    //   let total = 0
    //   for (let j = 0; j < forms.length; j++) {
    //     const curPrice = get(
    //       values,
    //       `orderProducts.${i}.${forms[j].key.replace(
    //         'orderCustom.',
    //         type === 'adjustment' ? 'orderAdjustment.' : 'orderCustom.'
    //       )}.price`
    //     )

    //     const addMore = get(
    //       values,
    //       `orderProducts.${i}.${forms[j].key}.addMore`,
    //       0
    //     )

    //     total += curPrice * (addMore + 1)
    //   }

    //   total = parseFloat(total.toFixed(2))
    //   if (type === 'collection') {
    //     handleChange({
    //       target: {
    //         name: `orderProducts.${i}.orderCollection.price`,
    //         value: total,
    //       },
    //     })
    //   } else if (type === 'adjustment') {
    //     handleChange({
    //       target: {
    //         name: `orderProducts.${i}.orderAdjustment.price`,
    //         value: total,
    //       },
    //     })
    //   } else {
    //     handleChange({
    //       target: {
    //         name: `orderProducts.${i}.orderCustom.price`,
    //         value: total,
    //       },
    //     })
    //   }
    // }
    handleNext()
  }

  useLayoutEffect(() => {
    var getMeTo = document.getElementById('button-zone')
    getMeTo.scrollIntoView({ behavior: 'smooth' }, true)
  }, [])

  const checkValidity = (toEnd) => {
    validateForm().then((res) => {
      if (Object.keys(res).length) {
        setIsValidated(true)

        let t = []
        while (typeof get(res, t.join('.')) === 'object' || !t.length) {
          let curr = !t.length ? res : get(res, t.join('.'))

          let kk = Object.keys(curr)

          t.push(kk[0])
        }

        var getMeTo = document.getElementById(t.join('.'))
        getMeTo.scrollIntoView({ behavior: 'smooth' }, true)
      } else {
        if (toEnd) {
          goToPayment()
        } else {
          goNextPage()
        }
      }
    })
  }

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Title level={3}>Choose persona type</Title>
        <Space style={{ marginBottom: 8 }}>
          <div
            className={`order-btn small${
              values.personaeType === 'special_event' ? ' selected' : ''
            }`}
            onClick={() =>
              handleChange({
                target: { name: 'personaeType', value: 'special_event' },
              })
            }
          >
            <Text type="secondary" strong>
              Special event
            </Text>
          </div>
          <div
            className={`order-btn small${
              values.personaeType === 'wardrobe_update' ? ' selected' : ''
            }`}
            onClick={() =>
              handleChange({
                target: {
                  name: 'personaeType',
                  value: 'wardrobe_update',
                },
              })
            }
          >
            <Text type="secondary" strong>
              Wardrobe update
            </Text>
          </div>
        </Space>
      </Space>
      <FieldArray name="orderProducts">
        {({ remove, push }) => (
          <Space direction="vertical" style={{ width: '100%' }}>
            {values.orderProducts?.length > 0 &&
              values.orderProducts?.map((friend, index) => (
                <>
                  <Space>
                    <Title level={3}>Product {index + 1}</Title>

                    <Button onClick={() => remove(index)} type="link">
                      Delete
                    </Button>
                  </Space>

                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(6, 1fr)',
                      width: 'fit-content',
                      columnGap: 12,
                      rowGap: 12,
                    }}
                  >
                    {Object.keys(formOptions).map((key) => (
                      <div
                        onClick={() => {
                          if (key === 'collection' || key === 'adjustment') {
                            handleChange({
                              target: {
                                name: `orderProducts.${index}`,
                                value: {
                                  orderProductType: key,
                                  [key === 'collection'
                                    ? 'orderCollection'
                                    : 'orderAdjustment']: {},
                                },
                              },
                            })
                          } else {
                            handleChange({
                              target: {
                                name: `orderProducts.${index}`,
                                value: formOptions[key].default,
                              },
                            })
                          }
                        }}
                        className={`order-btn big${
                          getFromKey(values.orderProducts[index]) === key
                            ? ' selected'
                            : ''
                        }`}
                      >
                        <Text type="secondary" strong>
                          {formOptions[key].name}
                        </Text>
                      </div>
                    ))}
                  </div>
                  {getFromKey(values.orderProducts[index]) === 'collection' ? (
                    <CollectionForm
                      values={values}
                      errors={errors}
                      hideErrors={!isValidated}
                      path={`orderProducts.${index}`}
                      handleChange={handleChange}
                    />
                  ) : getFromKey(values.orderProducts[index]) ===
                    'adjustment' ? (
                    <AdjustmentForm
                      values={values}
                      errors={errors}
                      hideErrors={!isValidated}
                      path={`orderProducts.${index}`}
                      handleChange={handleChange}
                    />
                  ) : (
                    <>
                      <StyleForm layout="vertical" style={{ width: '100%' }}>
                        <Card title={'Price'}>
                          <Space
                            size={0}
                            direction="vertical"
                            style={{ width: '100%' }}
                          >
                            {formOptions[
                              getFromKey(values.orderProducts[index])
                            ]?.priceList ? (
                              <StyleForm.Item>
                                <Select
                                  // name={`orderProducts.${index}.orderCustom.quality`}
                                  style={{ width: '50%' }}
                                  onChange={(e) => {
                                    handleChange({
                                      target: {
                                        name: `orderProducts.${index}.orderCustom.quality`,
                                        value: e,
                                      },
                                    })

                                    handleChange({
                                      target: {
                                        name: `orderProducts.${index}.orderCustom.price`,
                                        value:
                                          formOptions[
                                            getFromKey(
                                              values.orderProducts[index]
                                            )
                                          ]?.priceList[e]?.price,
                                      },
                                    })
                                  }}
                                  value={get(
                                    values,
                                    `orderProducts.${index}.orderCustom.quality`,
                                    ''
                                  )}
                                >
                                  {Object.keys(
                                    formOptions[
                                      getFromKey(values.orderProducts[index])
                                    ]?.priceList
                                  ).map((key) => (
                                    <Select.Option key={key} value={key}>
                                      {
                                        formOptions[
                                          getFromKey(
                                            values.orderProducts[index]
                                          )
                                        ]?.priceList[key]?.label
                                      }
                                    </Select.Option>
                                  ))}
                                </Select>
                              </StyleForm.Item>
                            ) : null}
                            <StyleForm.Item
                              validateStatus={
                                get(
                                  errors,
                                  `orderProducts.${index}.orderCustom.price`,
                                  false
                                )
                                  ? 'error'
                                  : null
                              }
                              help={get(
                                errors,
                                `orderProducts.${index}.orderCustom.price`,
                                null
                              )}
                            >
                              <Input
                                name={`orderProducts.${index}.orderCustom.price`}
                                type="text"
                                placeholder={'-'}
                                style={{ width: '50%' }}
                                onChange={handleChange}
                                value={get(
                                  values,
                                  `orderProducts.${index}.orderCustom.price`,
                                  ''
                                )}
                                disabled={
                                  !formOptions[
                                    getFromKey(values.orderProducts[index])
                                  ]?.isPriceEditable
                                }
                              />
                            </StyleForm.Item>
                          </Space>
                        </Card>
                      </StyleForm>

                      <FormContent
                        values={values}
                        errors={errors}
                        hideErrors={!isValidated}
                        path={`orderProducts.${index}`}
                        handleChange={handleChange}
                        forms={
                          formOptions[getFromKey(values.orderProducts[index])]
                            ?.forms
                        }
                      />
                    </>
                  )}
                </>
              ))}

            <Space id="button-zone">
              <Button onClick={handleBack} type="secondary">
                Previous
              </Button>
              <Button
                type="primary"
                // className=
                onClick={() => {
                  setCanDirectPayment(false)
                  push({})
                }}
                // danger={errors.orderProducts ? true : false}
              >
                Add Product
              </Button>
              <Button
                // disabled={Object.keys(errors).length}
                // onClick={() => {
                //   validateForm().then((res) =>
                //     Object.keys(res).length
                //       ? setIsValidated(true)
                //       : goNextPage()
                //   )
                // }}
                onClick={() => checkValidity(false)}
                // onClick={() => {
                //   validateForm().then((res) => {
                //     if (Object.keys(res).length) {
                //       setIsValidated(true)

                //       let t = []
                //       while (
                //         typeof get(res, t.join('.')) === 'object' ||
                //         !t.length
                //       ) {
                //         let curr = !t.length ? res : get(res, t.join('.'))

                //         let kk = Object.keys(curr)

                //         t.push(kk[0])
                //       }

                //       var getMeTo = document.getElementById(t.join('.'))
                //       getMeTo.scrollIntoView({ behavior: 'smooth' }, true)
                //     } else {
                //       goNextPage()
                //     }
                //   })
                // }}
                type="secondary"
              >
                Next
              </Button>
              <Button
                disabled={!canDirectPayment}
                type="primary"
                onClick={() => checkValidity(true)}
              >
                Payment
              </Button>
            </Space>
          </Space>
        )}
      </FieldArray>
    </>
  )
}

const AdjustmentForm = ({ values, errors, path, handleChange, hideErrors }) => {
  let productTypes = Object.keys(formOptions).map((key) => ({
    key: key,
    label: formOptions[key].name,
  }))

  const adjustmentForms = formOptions[
    get(values, `${path}.orderAdjustment.type`, '')
  ]?.forms.map((e) => ({
    ...e,
    key: e.key.replace('orderCustom.', ''),
  }))

  const errType = get(errors, `${path}.orderAdjustment.type`, null)

  return (
    <StyleForm layout="vertical" style={{ width: '100%' }}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Card title="Choose product">
          <StyleForm.Item
            label="Product type"
            validateStatus={errType && !hideErrors ? 'error' : null}
            help={hideErrors ? null : errType}
          >
            <Select
              value={get(values, `${path}.orderAdjustment.type`, '')}
              style={{ width: '50%' }}
              onChange={(id) =>
                handleChange({
                  target: {
                    name: `${path}.orderAdjustment`,
                    value: formOptions[id].default.orderCustom,
                  },
                })
              }
            >
              {productTypes.map((item) =>
                item.key === 'collection' ||
                item.key === 'adjustment' ? null : (
                  <Select.Option value={item.key}>{item.label}</Select.Option>
                )
              )}
            </Select>
          </StyleForm.Item>
        </Card>
        {get(values, `${path}.orderAdjustment.type`, false) && (
          <FormContent
            values={values}
            errors={errors}
            hideErrors={hideErrors}
            path={`${path}.orderAdjustment`}
            handleChange={handleChange}
            forms={adjustmentForms}
          />
        )}
      </Space>
    </StyleForm>
  )
}

const CollectionForm = ({ values, errors, path, handleChange, hideErrors }) => {
  const products = useQuery(['getProductsBasic'], getProductsBasic, {
    refetchOnWindowFocus: false,
  })

  const productName = `${path}.orderCollection.product`
  const productDisplayName = `${path}.orderCollection.productName`
  const productType = `${path}.orderCollection.productType`
  const productPrice = `${path}.orderCollection.price`
  const productSavedOptions = `${path}.orderCollection.options`
  const productTempOptions = `${path}.orderCollection.optionsTemp`
  const product = get(values, productName, null)
  const price = get(values, productPrice, null)
  const productSelected = (id) =>
    get(products, 'data.list', []).find((e) => e.id === id)

  const errProd = get(errors, `${path}.orderCollection.product`, null)
  const errPrice = get(errors, `${path}.orderCollection.price`, null)

  return (
    <StyleForm layout="vertical" style={{ width: '100%' }}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Card title="Choose product">
          <Space size={0} direction="vertical" style={{ width: '100%' }}>
            <StyleForm.Item
              label="Product name"
              validateStatus={
                errProd && !hideErrors
                  ? 'error'
                  : products.isLoading
                  ? 'validating'
                  : null
              }
              help={hideErrors ? null : errProd}
            >
              <Select
                value={product}
                style={{ width: '50%' }}
                onChange={(id) => {
                  handleChange({
                    target: {
                      name: productName,
                      value: id,
                    },
                  })

                  handleChange({
                    target: {
                      name: productType,
                      value: productSelected(id)?.category.blocks,
                    },
                  })

                  handleChange({
                    target: {
                      name: productDisplayName,
                      value: `${productSelected(id)?.sku} ${
                        productSelected(id)?.name?.en ||
                        productSelected(id)?.name?.zh
                      }`,
                    },
                  })

                  handleChange({
                    target: {
                      name: productPrice,
                      value: productSelected(id)?.retailPrice,
                    },
                  })

                  if (productSelected(id)?.options?.length) {
                    handleChange({
                      target: {
                        name: productSavedOptions,
                        value: productSelected(id).options,
                      },
                    })
                    handleChange({
                      target: {
                        name: productTempOptions,
                        value: new Array(
                          productSelected(id).options.length
                        ).fill(null),
                      },
                    })
                  }
                }}
              >
                {get(products, 'data.list', []).map((prod) => (
                  <Select.Option value={prod.id}>
                    {prod.name?.en || prod.name?.zh}
                  </Select.Option>
                ))}
              </Select>
            </StyleForm.Item>
            {product && (
              <StyleForm.Item
                label="Price"
                validateStatus={errPrice && !hideErrors ? 'error' : null}
                help={hideErrors ? null : errPrice}
              >
                <InputNumber
                  min={0}
                  type="tel"
                  name={productPrice}
                  style={{ width: '50%' }}
                  onChange={(e) =>
                    handleChange({
                      target: {
                        name: productPrice,
                        value: e,
                      },
                    })
                  }
                  value={price}
                  // disabled={field.isInactive}
                />
              </StyleForm.Item>
            )}
          </Space>
        </Card>
        {product && !!productSelected(product)?.options?.length && (
          <Card title="Options">
            <Space size={0} direction="vertical" style={{ width: '100%' }}>
              {productSelected(product)?.options.map((option, index) => {
                const err = get(errors, `${productTempOptions}.${index}`, null)

                const selectedOption = get(
                  values,
                  `${productSavedOptions}.${index}`
                )?.subOptions?.find(
                  (subOption) => subOption.selected === true
                )?._id
                if (selectedOption) {
                  set(values, `${productTempOptions}.${index}`, selectedOption)
                }

                return (
                  <StyleForm.Item
                    label={option?.name?.en || option?.name?.zh}
                    validateStatus={err && !hideErrors ? 'error' : null}
                    help={hideErrors ? null : err}
                  >
                    <Select
                      value={get(values, `${productTempOptions}.${index}`, '')}
                      style={{ width: '50%' }}
                      onChange={(id) => {
                        handleChange({
                          target: {
                            name: `${productTempOptions}.${index}`,
                            value: id,
                          },
                        })
                      }}
                    >
                      {option.subOptions?.map((subOption) => (
                        <Select.Option value={subOption._id}>
                          {subOption.name?.en || subOption.name?.zh}
                        </Select.Option>
                      ))}
                    </Select>
                  </StyleForm.Item>
                )
              })}
            </Space>
          </Card>
        )}
      </Space>
    </StyleForm>
  )
}

const Step2 = ({
  values,
  errors,
  handleChange,
  handleBack,
  handleNext,
  validateForm,
  saveUserInfos,
  clientDetails,
  saveBeforeSwitch,
  goToPayment,
  canDirectPayment,
}) => {
  const [isValidated, setIsValidated] = useState(false)

  const client3DLDetails = useQuery(
    [
      '3DLooks',
      {
        id: values.customer,
      },
    ],
    () => get3Dlook(values.customer),
    {
      initialData: clientDetails.dlook,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      onSuccess: (res) => {
        // console.log('resok', res)
        // if (first) {
        //   setFirst(false)
        //   return
        // }

        // const { back_neck_to_hip_length, neck, chest, bust_height } =
        //   res?.front_params

        // const {
        //   ankle,
        //   bicep,
        //   waist,
        //   calf,
        //   abdomen,
        //   low_hips,
        //   thigh,
        //   knee,
        //   armscye_girth,
        //   elbow_girth,
        //   wrist,
        //   high_hips,
        // } = res?.volume_params

        // const { side_neck_point_to_upper_hip } = res?.side_params
        if (res?.task_set?.is_ready === false) {
          message.warning('Measure not fully ready yet, refresh again later.')
        }

        handleChange({
          target: {
            name: 'bodyMeasures',
            value: {
              measurementSource: '3dlook',

              height: res?.height,
              weight: res?.weight,

              ...threeDParsing(res),
            },
          },
        })
      },
    }
  )

  const formsBody = [
    {
      form: [OrderComplexionFormP1],
      key: 'complexion',
    },
    {
      form: [BirthdayFormOrder],
      key: '',
    },
    {
      form: [
        ClientsBodyMeasurementFormExt,
        TDLookForm,
        ClientsBodyMeasurementFormOrder,
      ],
      key: 'bodyMeasures',
    },
    { form: [ClientsFootForm], key: '' },
  ]

  const handleGoNext = () => {
    saveUserInfos()
    values.orderProducts.forEach((product, index) => {
      if (product.orderProductType === 'custom') {
        // formOptions[getFromKey(product)].measuresForms.forEach((form) => {
        handleChange({
          target: {
            name: `orderProducts.${index}.skuMeasurements.0`,
            value: {
              ...formOptions[getFromKey(product)].measureDefault(
                get(values, `bodyMeasures`, {}),
                {},
                get(values, `foot`, {}),
                get(values, 'complexion.fitStyle', 'standard_fit'),
                get(values, 'complexion.shoulderType', 'normal'),
                get(values, `orderProducts.${index}.orderCustom`, {})
              ),
              ...get(values, `orderProducts.${index}.skuMeasurements.0`, {}),
            },
          },
          // })
        })
      } else if (product.orderProductType === 'collection') {
        let newDefaultMeasures = {}

        product.orderCollection.productType?.forEach((e) => {
          newDefaultMeasures = {
            ...formOptions[e].measureDefault(
              get(values, `bodyMeasures`, {}),
              {},
              get(values, `foot`, {}),
              get(values, 'complexion.fitStyle', 'standard_fit'),
              get(values, 'complexion.shoulderType', 'normal')
            ),
            ...newDefaultMeasures,
          }
        })

        newDefaultMeasures = merge(
          newDefaultMeasures,
          get(values, `orderProducts.${index}.skuMeasurements.0`, {})
        )

        handleChange({
          target: {
            name: `orderProducts.${index}.skuMeasurements.0`,
            value: newDefaultMeasures,
          },
        })
      }
    })

    handleNext()
  }

  useEffect(() => {
    if (values?.bodyMeasures?.measurementSource === 'af') {
      handleChange({
        target: {
          name: 'bodyMeasures',
          value: {
            ...get(clientDetails, 'af.bodyMeasurements', {}),
            measurementSource: 'af',
            height: values.bodyMeasures.height,
            weight: values.bodyMeasures.weight,
          },
        },
      })
    } else {
      saveBeforeSwitch(values)

      if (!!client3DLDetails?.data) {
        handleChange({
          target: {
            name: 'bodyMeasures',
            value: {
              measurementSource: '3dlook',

              height: values?.bodyMeasures?.height,
              weight: values?.bodyMeasures?.weight,

              ...threeDParsing(client3DLDetails?.data),
            },
          },
        })
      }
    }
  }, [values?.bodyMeasures?.measurementSource])

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <FormContent
        values={values}
        errors={errors}
        hideErrors={!isValidated}
        path=""
        handleChange={handleChange}
        forms={formsBody}
      />
      <Space>
        <Button onClick={handleBack} type="secondary">
          Previous
        </Button>
        <Button
          // disabled={Object.keys(errors).length}
          // onClick={handleGoNext}
          onClick={() => {
            validateForm().then((res) =>
              Object.keys(res).length ? setIsValidated(true) : handleGoNext()
            )
          }}
          type="primary" /*htmlType="submit"*/
        >
          Next
        </Button>
        <Button
          type="primary"
          disabled={!canDirectPayment}
          onClick={() =>
            validateForm().then((res) =>
              Object.keys(res).length ? setIsValidated(true) : goToPayment()
            )
          }
        >
          Payment
        </Button>
      </Space>
    </Space>
  )
}

const Step3 = ({
  values,
  errors,
  handleChange,
  handleBack,
  handleNext,
  afterStep3,
  setAfterStep3,
  innerStep,
  setInnerStep,
  validateForm,
  goToPayment,
  clientOrders,
  canDirectPayment,
  setCanDirectPayment,
}) => {
  const [isValidated, setIsValidated] = useState(false)
  const measureValues = values.orderProducts.map((prod, index) => ({
    product: prod,
    index,
    key: getFromKey(prod),
  }))
  // .filter(
  //   (e) =>
  //     e.product.orderProductType === 'custom' ||
  //     e.product.orderProductType === 'collection'
  // )
  const maxStep = measureValues.length - 1
  const [measureStep, setMeasureStep] = useState(afterStep3 ? maxStep : 0)

  useEffect(() => {
    validateForm()
  }, [measureStep])

  useEffect(() => {
    if (!measureValues.length) {
      if (afterStep3) {
        handleBack()
        setAfterStep3(false)
      } else {
        handleNext()
        setAfterStep3(true)
      }
    } else if (afterStep3) {
      setInnerStep(measureValues[maxStep].index)
    } else {
      setInnerStep(measureValues[0].index)
    }
  }, [])

  const handleMeasureNext = () => {
    if (measureStep >= maxStep) {
      setCanDirectPayment(true)
      handleNext()
      setAfterStep3(true)
    } else {
      setInnerStep(measureValues[measureStep + 1].index)
      setMeasureStep(measureStep + 1)
    }
  }

  const handleMeasureBack = () => {
    if (measureStep <= 0) {
      handleBack()
      setAfterStep3(false)
    } else {
      setInnerStep(measureValues[measureStep - 1].index)
      setMeasureStep(measureStep - 1)
    }
  }

  const stepKey = get(
    measureValues,
    `${measureStep}.product.orderProductType`,
    false
  )

  const checkValidity = (id) => {
    validateForm().then((res) => {
      if (Object.keys(res).length) {
        setIsValidated(true)
      } else {
        if (id === -1) {
          goToPayment()
        } else if (!!id || id === 0) {
          setMeasureStep(id)
        } else {
          handleMeasureNext()
        }
        setIsValidated(false)
      }
    })
  }

  const handleApplyPreviousSize = () => {
    let value = null
    if (lastMatching !== -1) {
      value = get(
        measureValues,
        `${lastMatching}.product.skuMeasurements.0`,
        {}
      )
    } else if (lastOrderMatching !== -1) {
      value = get(
        historySkuMeasurements,
        `${lastOrderMatching}.product.skuMeasurements.0`,
        {}
      )
    }

    handleChange({
      target: {
        name: `orderProducts.${innerStep}.skuMeasurements.0`,
        value,
      },
    })
  }

  const _isSameProduct = (e) => {
    let productType = get(e, 'product.orderCustom.type', '')
    if (productType === currentMeasureProductType) {
      return true
    }

    const suit3 = ['vest', 'jacket_blazer', 'pant', 'suit_2_pieces']
    const suit2 = ['jacket_blazer', 'pant']
    if (
      productType === 'suit_3_pieces' &&
      suit3.includes(currentMeasureProductType)
    ) {
      return true
    }
    if (
      productType === 'suit_2_pieces' &&
      suit2.includes(currentMeasureProductType)
    ) {
      return true
    }
    if (
      currentMeasureProductType === 'suit_3_pieces' &&
      suit3.includes(productType)
    ) {
      return true
    }
    return (
      currentMeasureProductType === 'suit_2_pieces' &&
      suit2.includes(productType)
    )
  }

  // befores is the products add before
  let befores = [...measureValues]
  befores.splice(measureStep, measureValues?.length - measureStep)

  // get product type
  let currentMeasureProductType = get(
    measureValues,
    `${measureStep}.product.orderCustom.type`,
    []
  )

  // get previous size
  const lastMatching = findLastIndex(befores, _isSameProduct)

  // No last matching size, find in the history orders
  let historySkuMeasurements = []
  if (lastMatching === -1) {
    // push the product ordered before into history
    if (clientOrders.data?.length > 0) {
      clientOrders.data.forEach((order) => {
        if (order.orderProducts.length > 0) {
          order.orderProducts.forEach((product) => {
            let currType = get(product, 'orderCustom.type', '')
            historySkuMeasurements.push({
              product: product,
              index: historySkuMeasurements.length,
              key: currType,
            })
          })
        }
      })
    }
  }
  // get previous size from history orders
  const lastOrderMatching = findLastIndex(
    historySkuMeasurements,
    _isSameProduct
  )

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          flexDirection: 'row',
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <Text strong>{formOptions[measureValues[measureStep].key].name}</Text>
          <div
            style={{
              color: '#1890ff',
            }}
          >
            {measureValues.map((e, idx) =>
              idx === measureStep ? (
                <span>&#9679;</span>
              ) : (
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => checkValidity(idx)}
                >
                  &#9675;
                </span>
              )
            )}
          </div>
        </div>
        <Button
          disabled={lastMatching === -1 && lastOrderMatching === -1}
          onClick={handleApplyPreviousSize}
          type="primary"
        >
          Use previous size
        </Button>
      </div>
      {measureValues.length > 0 &&
        (stepKey === 'collection' ? (
          get(
            measureValues,
            `${measureStep}.product.orderCollection.productType`,
            []
          ).map((e) =>
            formOptions[e]?.measuresForms ? (
              <FormContent
                values={values}
                errors={errors}
                hideErrors={!isValidated}
                path={`orderProducts.${measureValues[measureStep]?.index}`}
                handleChange={handleChange}
                forms={formOptions[e]?.measuresForms}
              />
            ) : (
              <div
                style={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: 21,
                  margin: 16,
                }}
              >
                No measurement.
              </div>
            )
          )
        ) : stepKey === 'adjustment' ? (
          <FormContent
            values={values}
            errors={errors}
            hideErrors={!isValidated}
            path={`orderProducts.${measureValues[measureStep]?.index}`}
            handleChange={handleChange}
            forms={
              formOptions[
                measureValues[measureStep]?.product?.orderAdjustment?.type
              ]?.measuresForms
            }
          />
        ) : formOptions[measureValues[measureStep]?.key]?.measuresForms ? (
          <FormContent
            values={values}
            errors={errors}
            hideErrors={!isValidated}
            path={`orderProducts.${measureValues[measureStep]?.index}`}
            handleChange={handleChange}
            forms={formOptions[measureValues[measureStep]?.key]?.measuresForms}
          />
        ) : (
          <div
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: 21,
              margin: 16,
            }}
          >
            No measurement.
          </div>
        ))}

      <Space>
        <Button onClick={handleMeasureBack} type="secondary">
          Previous
        </Button>
        <Button
          // disabled={Object.keys(errors).length}
          // onClick={handleMeasureNext}
          onClick={() => checkValidity(undefined)}
          type="primary" /*htmlType="submit"*/
        >
          Next
        </Button>
        <Button
          // disabled={Object.keys(errors).length}
          // onClick={handleMeasureNext}
          disabled={!canDirectPayment}
          onClick={() => checkValidity(-1)}
          type="primary" /*htmlType="submit"*/
        >
          Payment
        </Button>
      </Space>
    </Space>
  )
}

const Step4 = ({ values, errors, handleChange }) => {
  const formsComplexion = [
    { form: [OrderComplexionFormP2], key: 'complexion' },
    { form: [OrderBodyPics], key: '' },
  ]

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <FormContent
        values={values}
        errors={errors}
        path=""
        handleChange={handleChange}
        forms={formsComplexion}
      />
    </Space>
  )
}

const Step5 = ({ values, errors, handleChange, orderDetails }) => {
  const formsPayment = [{ form: Payment, keys: '' }]
  console.log('orderDetails', orderDetails)

  useEffect(() => {
    let total = 0

    for (const prod of values.orderProducts) {
      switch (prod.orderProductType) {
        case 'collection':
          total += get(prod, 'orderCollection.price', 0)
          break
        case 'custom': {
          let qty = 1

          for (var key of Object.keys(prod.orderCustom)) {
            qty += get(prod, `orderCustom.${key}.addMore`, 0)
          }

          total += get(prod, 'orderCustom.price', 0) * qty

          // extra shirts
          let extraShirts = get(
            prod,
            `orderCustom.orderCustomShirt.extraShirts`,
            []
          )
          if (extraShirts) {
            extraShirts.forEach((item) => {
              total += get(item, 'priceNum', 0)
            })
          }
          break
        }
        case 'adjustment':
          total += get(prod, 'orderAdjustment.price', 0)
          break

        default:
          break
      }
    }
    total = parseFloat(total.toFixed(2))

    handleChange({
      target: {
        name: 'totalSpent',
        value: total,
      },
    })
    handleChange({
      target: {
        name: 'balance',
        value:
          total -
          get(values, 'discount', 0) -
          get(values, 'deposit', 0) -
          get(values, 'deposit2', 0),
      },
    })
  }, [])

  useEffect(() => {
    let val = get(values, 'totalSpent', 0)
    val = parseFloat((val - get(values, 'discount', 0)).toFixed(2))
    val = parseFloat(
      (val - get(values, 'deposit', 0) - get(values, 'deposit2', 0)).toFixed(2)
    )

    handleChange({
      target: {
        name: 'balance',
        value: val,
      },
    })
  }, [values.totalSpent, values.discount, values.deposit, values.deposit2])

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Card title="Total Summary">
        {values.orderProducts.map((prod, index) => {
          const prodType = getFromKey(prod)
          let name
          let price = 0

          if (prodType === 'collection') {
            price = prod.orderCollection.price
            name = get(prod, `orderCollection.productName`, 'Collection')
          } else if (prodType === 'adjustment') {
            price = prod.orderAdjustment.price
            name = 'Adjustment'
          } else {
            price = prod.orderCustom.price
            //extra shirts
            let extraShirts = get(
              prod,
              'orderCustom.orderCustomShirt.extraShirts',
              []
            )
            if (extraShirts) {
              extraShirts.forEach((item) => {
                price += get(item, 'priceNum', 0)
              })
            }
            name = formOptions[prodType].name
          }

          return (
            <p key={index}>
              <strong>{`x${
                get(prod, 'orderCustom.orderCustomShirt.addMore', 0) + 1
              } ${name}:`}</strong>
              {` CNYbb ${price} ${orderDetails.currency}`}
            </p>
          )
        })}
      </Card>
      <FormContent
        values={values}
        errors={errors}
        path=""
        handleChange={handleChange}
        forms={formsPayment}
      />
    </Space>
  )
}
