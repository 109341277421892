import {
  AppstoreOutlined,
  BarChartOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  MacCommandOutlined,
  PlusCircleOutlined,
  ShopOutlined,
  TagsOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  UserAddOutlined,
  UserOutlined,
  WechatOutlined,
  StarOutlined,
  MoneyCollectOutlined,
} from '@ant-design/icons'
import { Layout, Menu } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { ROLE_PERMISSIONS, SCREEN_SIZE } from '../../constants/common'
import { useAuth } from '../../hooks/useAuth'
import Loader from '../../components/Loader'

const { Sider } = Layout

const NavigationDrawer = ({ logo, loading }) => {
  const menuRef = useRef(null)
  const { user } = useAuth()
  const [collapsed, setCollapsed] = useState(false)

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed)
  }

  const permissions = ROLE_PERMISSIONS[user.role]
    ? ROLE_PERMISSIONS[user.role]
    : []

  useEffect(() => {
    const listener = () => {
      if (window.innerWidth < SCREEN_SIZE.TABLET) {
        setCollapsed(true)
      }
    }
    window.addEventListener('resize', listener)

    return () => window.removeEventListener('resize', listener)
  }, [])

  function handleBlur(e) {
    if (
      menuRef.current === e.relatedTarget ||
      menuRef.current.contains(e.relatedTarget)
    )
      return

    onCollapse(true)
  }

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      className="menu-sidebar"
    >
      <div
        className="menu-sidebar-top"
        onClick={() => onCollapse(!collapsed)}
        style={{
          justifyContent: collapsed ? 'center' : 'flex-end',
          padding: collapsed ? 'auto' : '24px',
        }}
      >
        {logo && logo.uri ? (
          !collapsed ? (
            <div
              className="menu-sidebar-logo"
              style={{
                backgroundImage: `url(${process.env.REACT_APP_API_URL}/storage/store-logo/${logo.uri})`,
              }}
            />
          ) : null
        ) : (
          <span></span>
        )}

        {collapsed ? (
          <DoubleRightOutlined
            style={{
              paddingBottom: collapsed ? 12 : 0,
            }}
          />
        ) : (
          <DoubleLeftOutlined />
        )}
      </div>
      <div className="menu-sidebar-content" ref={menuRef} onBlur={handleBlur}>
        {loading === true ? (
          <Loader />
        ) : (
          <Menu
            defaultSelectedKeys={['1']}
            mode="inline"
            className="menu-sidebar-menu"
            style={{
              pointerEvents: 'auto',
            }}
          >
            <div
              className="menu-sidebar-separator"
              style={{
                paddingLeft: collapsed ? 10 : 15,
              }}
            >
              <span>B2C</span>
            </div>
            <Menu.Item key="home" icon={<StarOutlined />}>
              <Link to="/">Main Items</Link>
            </Menu.Item>

            <Menu.Item key="orders" icon={<UnorderedListOutlined />}>
              <Link to="/orders">Client Follow-up</Link>
            </Menu.Item>

            {permissions.includes('new_orders') && (
              <Menu.Item key="new_orders" icon={<PlusCircleOutlined />}>
                <Link to="/orders/create">Create New Order</Link>
              </Menu.Item>
            )}

            {permissions.includes('clients') && (
              <Menu.Item key="clients" icon={<TeamOutlined />}>
                <Link to="/clients">Clients</Link>
              </Menu.Item>
            )}

            {permissions.includes('new_clients') && (
              <Menu.Item key="new_clients" icon={<UserAddOutlined />}>
                <Link to={`/clients/create/${user.currentStore}`}>
                  Create New Client
                </Link>
              </Menu.Item>
            )}

            {permissions.includes('new_adjustments') &&
              user.franchiseStore === false && (
                <Menu.Item key="new_adjustment" icon={<PlusCircleOutlined />}>
                  <Link to="/adjustments/create">New Adjustment</Link>
                </Menu.Item>
              )}
            {(permissions.includes('products') ||
              permissions.includes('categories') ||
              permissions.includes('types') ||
              permissions.includes('product-tags') ||
              permissions.includes('product-prices')) && (
              <div
                className="menu-sidebar-separator"
                style={{
                  paddingLeft: collapsed ? 10 : 15,
                }}
              >
                <span>{collapsed ? 'PROD.' : 'PRODUCTS'}</span>
              </div>
            )}
            {permissions.includes('products') && (
              <Menu.Item key="products" icon={<UnorderedListOutlined />}>
                <Link to="/products">Product List</Link>
              </Menu.Item>
            )}

            {permissions.includes('product-prices') && (
              <Menu.Item key="product-prices" icon={<MoneyCollectOutlined />}>
                <Link to="/product-prices">Product Prices</Link>
              </Menu.Item>
            )}

            {permissions.includes('categories') && (
              <Menu.Item key="categories" icon={<AppstoreOutlined />}>
                <Link to="/categories">Collections</Link>
              </Menu.Item>
            )}

            {permissions.includes('types') && (
              <Menu.Item key="types" icon={<MacCommandOutlined />}>
                <Link to="/product-types">Product Families</Link>
              </Menu.Item>
            )}

            {permissions.includes('product-tags') && (
              <Menu.Item key="product-tags" icon={<TagsOutlined />}>
                <Link to="/product-tags">Product Tags</Link>
              </Menu.Item>
            )}

            {(permissions.includes('product-franchises') ||
              permissions.includes('stores') ||
              permissions.includes('members') ||
              permissions.includes('mp') ||
              permissions.includes('metrics')) && (
              <div
                className="menu-sidebar-separator"
                style={{
                  paddingLeft: collapsed ? 10 : 15,
                }}
              >
                <span>{collapsed ? 'COMP.' : 'COMPANY'}</span>
              </div>
            )}
            {/* Uncomment to allow the main BO store to choose wich type of product can sell the franchise*/}
            {/* {permissions.includes('product-franchises') &&
              user.franchiseStore === false && (
                <Menu.Item
                  key="product-franchises"
                  icon={<MoneyCollectOutlined />}
                >
                  <Link to="/product-franchises">Product Franchises</Link>
                </Menu.Item>
              )} */}
            {permissions.includes('stores') && (
              <Menu.Item key="stores" icon={<ShopOutlined />}>
                <Link to="/stores">
                  Stores {!user.franchiseStore ? '/ Distributors' : ''}
                </Link>
              </Menu.Item>
            )}

            {permissions.includes('members') && (
              <Menu.Item key="members" icon={<UserOutlined />}>
                <Link to="/members">Team Members</Link>
              </Menu.Item>
            )}

            {permissions.includes('metrics') && (
              <Menu.Item key="metrics" icon={<BarChartOutlined />}>
                <Link to="/metrics">Metrics</Link>
              </Menu.Item>
            )}

            {permissions.includes('mp') && user.franchiseStore === false && (
              <Menu.Item key="mp" icon={<WechatOutlined />}>
                <Link to="/mp">Landing Sections</Link>
              </Menu.Item>
            )}

            {(permissions.includes('companies') ||
              permissions.includes('uniforms') ||
              permissions.includes('companies_orders')) && (
              <div
                className="menu-sidebar-separator"
                style={{
                  paddingLeft: collapsed ? 10 : 15,
                }}
              >
                <span>B2B</span>
              </div>
            )}

            {permissions.includes('companies') && (
              <Menu.Item key="companies" icon={<BarChartOutlined />}>
                <Link to="/companies">Companies</Link>
              </Menu.Item>
            )}
            {permissions.includes('uniforms') && (
              <Menu.Item key="uniforms" icon={<BarChartOutlined />}>
                <Link to="/uniforms">Uniforms</Link>
              </Menu.Item>
            )}
            {permissions.includes('companies_orders') && (
              <Menu.Item key="companies_orders" icon={<BarChartOutlined />}>
                <Link to="/companies/orders">Companies Orders</Link>
              </Menu.Item>
            )}

            {/* <div style={{ flex: 1 }} />
        <Menu.Item onClick={signOut} key="logout" icon={<LogoutOutlined />}>
          <Tooltip title={timeBeforeExpire}>Logout</Tooltip>
        </Menu.Item> */}
          </Menu>
        )}
      </div>
    </Sider>
  )
}

export default NavigationDrawer
