import { Button, PageHeader } from 'antd'
import { useQueryClient } from 'react-query'

import TableBlock from '../../components/tables/TableBlock'
import { getProductCategories } from '../../network/Categories'
import { ROLE_PERMISSIONS } from '../../constants/common'
import { useAuth } from '../../hooks/useAuth'

const Categories = ({ history }) => {
  const queryClient = useQueryClient()
  queryClient.invalidateQueries('getCategoryDetails')

  const { user } = useAuth()
  const permissions = ROLE_PERMISSIONS[user.role]
    ? ROLE_PERMISSIONS[user.role]
    : []
  const filters = () => ({
    actions: [
      {
        label: '+ Create collection',
        isActive: permissions.includes('new_categories'),
        action: () => history.push(`/categories/create/${user.currentStore}`),
      },
    ],
  })

  const columns = (_keyRequest) => {
    const franchiseStore = user.franchiseStore
    return [
      {
        title: 'Name (English)',
        key: 'name.en',
        render: (name) => name?.en,
        dataIndex: 'name',
      },
      ...(franchiseStore
        ? []
        : [
            {
              title: 'Name (中文)',
              key: 'name.zh',
              render: (name) => name?.zh,
              dataIndex: 'name',
            },
          ]),

      {
        title: 'Product Family',
        key: 'type',
        render: (type) => type?.name?.en || type?.name?.zh,
        dataIndex: 'type',
      },
      ...(franchiseStore
        ? []
        : [
            {
              title: 'Family',
              key: 'genre',
              dataIndex: 'genre',
            },
          ]),
      {
        title: '',
        key: 'action',
        width: 25,
        render: (e) => {
          return (
            <Button
              onClick={() =>
                history.push(`/categories/${e.id}/${user.currentStore}`)
              }
              disabled={permissions.includes('edit_categories') ? false : true}
              type="primary"
            >
              Edit
            </Button>
          )
        },
      },
    ]
  }

  return (
    <>
      <PageHeader className="site-page-header" title="Collections" />
      <TableBlock
        columns={columns}
        filters={filters}
        name={'getProductCategories'}
        request={getProductCategories}
        infinite
        otherFilters={{ store: user.currentStore }} // Sort the categories by store
      />
    </>
  )
}

export default Categories
