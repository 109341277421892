import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ROLES } from '../../constants/common'

function AuthRoute(props) {
  const {
    user: { role: userRole },
    role: routeRole,
    backUrl,
    franchise,
    ...otherProps
  } = props

  const isAdmin = (userRole) => {
    const adminRoles = [ROLES.ADMIN, ROLES.SUPER_ADMIN]
    return userRole && adminRoles.indexOf(userRole) > -1
  }

  const canAccess = (userRole, allowRoles) => {
    return !!(userRole && allowRoles && allowRoles.indexOf(userRole) > -1)
  }

  if (franchise === true) {
    return <Redirect to={backUrl || '/'} />
  }

  if (isAdmin(userRole) || canAccess(userRole, routeRole)) {
    return <Route {...otherProps} />
  } else {
    return <Redirect to={backUrl || '/'} />
  }
}

export default AuthRoute
