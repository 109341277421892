import { Form, PageHeader } from 'antd'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import Error from '../../components/Error'
import Loader from '../../components/Loader'
import FormBlock from '../../components/forms/FormBlock'
import FormWrap from '../../components/forms/FormWrap'
import {
  CategoriesFieldsFormEdit,
  CategoriesMediaForm,
  CategoriesPriorityWeightForm,
  CategoriesPublicationForm,
} from '../../forms/Categories'
import { getCategoryDetails, updateCategory } from '../../network/Categories'
import { useAuth } from '../../hooks/useAuth'
import { getProductTypesSortByStore } from '../../network/API'

const EditCategory = ({ history }) => {
  const { storeId } = useParams()
  let { categoryId } = useParams()
  const [form] = Form.useForm()
  const { user } = useAuth()

  const categoryDetails = useQuery(
    [
      'getCategoryDetails',
      {
        id: categoryId,
      },
    ],
    getCategoryDetails
    // {
    //   // onSuccess: (data) => {
    //   //   form.setFieldsValue(data)
    //   // },
    //   // refetchOnWindowFocus: false,
    // }
  )

  const CategoriesDescriptionForm = {
    title: 'Description',
    key: [],
    sections: [
      {
        columns: 2,
        fields: [
          {
            label: 'Product Family',
            type: 'select',
            isRequired: true,
            key: ['type'],
            requestOption: async ({ queryKey }) => {
              const filter = { store: storeId }

              return getProductTypesSortByStore({
                queryKey: [
                  'getProductTypesSortByStore',
                  {
                    filter,
                    sort: {},
                    range: {},
                    tabFilter: {},
                  },
                ],
              })
            },
            pos: {
              col: 1,
              row: 1,
              size: 1,
            },
          },
          // Display only if the store is not a franchise
          ...(!user.franchiseStore
            ? [
                {
                  label: 'Category name (中文)',
                  isRequired: true,
                  key: ['name', 'zh'],
                  pos: {
                    col: 1,
                    row: 3,
                    size: 1,
                  },
                },
              ]
            : []),
          {
            label: 'Category name',
            isRequired: true,
            key: ['name', 'en'],
            pos: {
              col: 1,
              row: 2,
              size: 1,
            },
          },
          {
            label: 'Show on MP',
            type: 'checkbox',
            key: ['showOnMP'],
            hideIfFranchiseStore: true,
            pos: {
              col: 2,
              row: 1,
              size: 1,
            },
          },
          {
            label: 'Family',
            type: 'select',
            isRequired: true,
            hideIfFranchiseStore: true,
            key: ['genre'],
            options: [
              {
                label: 'Menswear',
                key: 'menswear',
              },
              {
                label: 'Fragrance',
                key: 'fragrance',
              },
            ],
            pos: {
              col: 2,
              row: 2,
              size: 1,
            },
          },
        ],
      },
    ],
  }

  return (
    <>
      <PageHeader
        className="site-page-header"
        title={'Edit collection'}
        onBack={() => history.push('/categories')}
        style={{ backgroundColor: '#fff' }}
      />
      {!storeId ? ( // Display loader until storeId is available
        <Loader />
      ) : categoryDetails.isError ? (
        <Error retry={() => categoryDetails.refetch()} />
      ) : categoryDetails.isLoading ? (
        <Loader />
      ) : (
        <FormWrap
          id={categoryId}
          form={form}
          title="update_category"
          request={updateCategory}
          cancelText={'Cancel'}
          okText={'Save'}
          data={categoryDetails.data}
          onSuccess={() => history.push('/categories')}
          onCancel={() => history.push('/categories')}
          cleanBeforeSending={(oldData) => {
            if (oldData.mainPicture) {
              let main = oldData.mainPicture.fileList
                ? oldData.mainPicture?.fileList
                : oldData.mainPicture

              if (!Array.isArray(main)) {
                main = [main]
              }
              const newPic = main.map((file) =>
                file.response ? file.response : file
              )

              oldData.mainPicture = newPic.length ? newPic[0] : {}
            } else {
              oldData.mainPicture = {}
            }
            return oldData
          }}
          invalidate={['getProductCategories', 'getCategoryDetails']}
        >
          <div className="section-from-vertical">
            <FormBlock form={form} formTemplate={CategoriesDescriptionForm} />
            {!user.franchiseStore && (
              <FormBlock form={form} formTemplate={CategoriesMediaForm} />
            )}
            <FormBlock form={form} formTemplate={CategoriesFieldsFormEdit} />
            <FormBlock.Basic formTemplate={CategoriesPublicationForm} />
            <FormBlock.Basic formTemplate={CategoriesPriorityWeightForm} />
          </div>
        </FormWrap>
      )}
    </>
  )
}

export default EditCategory
