import { INSTANCE, INSTANCELIST } from './API'

export const getProductTypes = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const { sort, range, filter, tabFilter } = queryKey[1]

    INSTANCELIST({
      method: 'get',
      url: '/product-types',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      params: {
        sort: JSON.stringify(sort),
        range: JSON.stringify(range),
        filter: JSON.stringify(filter),
        ...tabFilter,
      },
    })
      .then(resolve)
      .catch(reject)
  })

export const getProductType = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const id = queryKey[1]

    INSTANCE({
      method: 'get',
      url: `/product-types/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(resolve)
      .catch(reject)
  })

export const createProductType = (form) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'post',
      url: '/product-types',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: form,
    })
      .then(resolve)
      .catch(reject)
  })

export const updateProductType = (form, id) =>
  new Promise((resolve, reject) => {
    INSTANCE({
      method: 'put',
      url: `/product-types/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: form,
    })
      .then(resolve)
      .catch(reject)
  })

export const deleteProductType = ({ queryKey }) =>
  new Promise((resolve, reject) => {
    const { id } = queryKey[1]

    INSTANCE({
      method: 'delete',
      url: `/product-types/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(resolve)
      .catch(reject)
  })
