export const CategoriesMediaForm = {
  title: 'Main picture',
  key: [],
  sections: [
    {
      columns: 1,
      fields: [
        {
          type: 'upload',
          label: '109*109px (1:1 ratio)',
          isRequired: true,
          requestURI: '/files/product-category',
          storageURI: '/storage/product-category',
          key: ['mainPicture'],
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
      ],
    },
  ],
}

export const CategoriesFieldsFormCreate = {
  title: 'Fields blocs (This value cannot be changed later)',
  key: [],
  sections: [
    {
      columns: 1,
      fields: [
        {
          label: 'Select the blocs that will appear for this collection',
          type: 'multicheck',
          options: [
            { label: 'Jacket (cm)', key: 'jacket_blazer' },
            { label: 'Pants (cm)', key: 'pant' },
            { label: 'Jeans (cm)', key: 'jean' },
            { label: 'Vest (cm)', key: 'vest' },
            { label: 'Shirt (cm)', key: 'shirt' },
            { label: 'Coat (cm)', key: 'coat' },
            // { label: 'Trench (cm)', key: 'trench' },
            { label: 'Skirt (cm)', key: 'skirt' },
            { label: 'Foot (cm)', key: 'shoes' },
          ],
          key: ['blocks'],
        },
      ],
    },
  ],
}

export const CategoriesFieldsFormEdit = {
  title: 'Fields blocs',
  key: [],
  sections: [
    {
      columns: 1,
      fields: [
        {
          label: 'Select the blocs that will appear for this collection',
          type: 'multicheck',
          isInactive: true,
          options: [
            { label: 'Jacket (cm)', key: 'jacket_blazer' },
            { label: 'Pants (cm)', key: 'pant' },
            { label: 'Jeans (cm)', key: 'jean' },
            { label: 'Vest (cm)', key: 'vest' },
            { label: 'Shirt (cm)', key: 'shirt' },
            { label: 'Coat (cm)', key: 'coat' },
            // { label: 'Trench (cm)', key: 'trench' },
            { label: 'Skirt (cm)', key: 'skirt' },
            { label: 'Foot (cm)', key: 'shoes' },
          ],
          key: ['blocks'],
        },
      ],
    },
  ],
}

export const CategoriesPublicationForm = {
  key: [],
  sections: [
    {
      columns: 3,
      fields: [
        {
          label: 'Publication',
          type: 'select',
          key: ['status'],
          options: [
            { key: 'published', label: 'Published' },
            { key: 'unpublished', label: 'Unpublished' },
          ],
          isRequired: true,
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
      ],
    },
  ],
}

export const CategoriesPriorityWeightForm = {
  key: [],
  sections: [
    {
      columns: 3,
      fields: [
        {
          label: 'Priority Weight',
          type: 'number',
          key: ['priorityWeight'],
          isRequired: false,
          placeholder: '-',
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
      ],
    },
  ],
}
