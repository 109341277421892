export const ROLES = {
  ADMIN: 'admin',
  SUPER_ADMIN: 'super-admin',
  STORE_MANAGER: 'store_manager',
  STORE_ASSISTANT: 'store_assistant',
  MARKETING: 'marketing',
  PRODUCTION: 'production',
}

export const ROLE_PERMISSIONS = {
  admin: [
    'new_orders',
    'edit_orders',
    'new_adjustments',
    'clients',
    'new_clients',
    'edit_clients',
    'products',
    'new_products',
    'edit_products',
    'types',
    'stores',
    'new_stores',
    'edit_stores',
    'members',
    'metrics',
    'companies',
    'new_companies',
    'edit_companies',
    'uniforms',
    'new_uniforms',
    'edit_uniforms',
    'companies_orders',
    'new_companies_orders',
    'edit_companies_orders',
    'genres',
    'mp',
    'product-tags',
    'categories',
    'new_categories',
    'edit_categories',
    'types',
    'product-prices',
    'product-franchises',
    'can-see-franchises',
  ],
  'super-admin': [
    'new_orders',
    'edit_orders',
    'new_adjustments',
    'clients',
    'new_clients',
    'edit_clients',
    'products',
    'new_products',
    'edit_products',
    'types',
    'stores',
    'new_stores',
    'edit_stores',
    'members',
    'metrics',
    'companies',
    'new_companies',
    'edit_companies',
    'uniforms',
    'new_uniforms',
    'edit_uniforms',
    'companies_orders',
    'new_companies_orders',
    'edit_companies_orders',
    'genres',
    'mp',
    'product-tags',
    'categories',
    'new_categories',
    'edit_categories',
    'types',
    'product-prices',
    'product-franchises',
    'can-see-franchises',
  ],
  store_manager: [
    'new_orders',
    'edit_orders',
    'new_adjustments',
    'clients',
    'new_clients',
    'edit_clients',
    'products',
    'stores',
    'metrics',
    'companies',
    'uniforms',
    'companies_orders',
    'genres',
    'categories',
    'product-franchises',
    'can-see-franchises',
  ],

  store_assistant: [
    'new_orders',
    'products',
    'new_adjustments',
    'clients',
    'new_clients',
    'stores',
    'follow_up_store_assistant',
  ],
  marketing: [
    'stores',
    'mp',
    'product-tags',
    'clients',
    'products',
    'new_products',
    'edit_products',
    'types',
    'metrics',
    'categories',
    'new_categories',
    'edit_categories',
  ],
  production: [
    'new_orders',
    'edit_orders',
    'clients',
    'products',
    'new_products',
    'edit_products',
    'types',
    'metrics',
    'companies',
    'new_companies',
    'edit_companies',
    'uniforms',
    'new_uniforms',
    'edit_uniforms',
    'companies_orders',
    'new_companies_orders',
    'edit_companies_orders',
    'genres',
    'follow_up_production',
    'stores',
  ],
}

export const SCREEN_SIZE = {
  TABLET: 1100,
}
