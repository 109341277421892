import { PageHeader } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'

const CreateOrder = ({ history }) => {
  const location = useLocation()
  const { user } = useAuth()

  return (
    <>
      <PageHeader
        className="site-page-header"
        title={`Create ${
          location.pathname.includes('orders') ? 'order' : 'adjustment'
        }`}
        style={{ backgroundColor: '#fff' }}
        onBack={
          location.pathname.includes('orders')
            ? () => history.push('/orders')
            : null
        }
      />
      <div className="order-wrapper">
        <Link
          className={user.role === 'production' ? 'is-hidden' : 'order-tile'}
          to={`${location.pathname}/new/${user.currentStore}`}
        >
          New customer
        </Link>
        <Link className="order-tile" to={`${location.pathname}/select`}>
          Existing customer
        </Link>
      </div>
    </>
  )
}

export default CreateOrder
