import React, { useEffect, useState } from 'react'
import { Button, PageHeader, Table, Space } from 'antd'
import { getProductPrices } from '../../network/ProductPrices'
import { useAuth } from '../../hooks/useAuth'
import { EyeOutlined } from '@ant-design/icons'

const ProductPrices = ({ history }) => {
  const { user } = useAuth()
  const [productPrices, setProductPrices] = useState([])

  useEffect(() => {
    getProductPrices()
      .then((data) => {
        const productPriceList = data.list.products

        const productPriceArray = Object.entries(productPriceList).map(
          ([key, value]) => ({
            key,
            ...value,
          })
        )

        setProductPrices(productPriceArray)
      })
      .catch((error) => {
        console.error('Failed to fetch product prices:', error)
      })
  }, [])

  const handleEdit = (product) => {
    history.push(`/product-price/${product.type}/${user.currentStore}`)
  }

  const columns = [
    {
      title: 'Product Name',
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: 'Action',
      key: 'action',
      width: '50px',
      render: (text, product) => (
        <Space>
          <Button
            type="primary"
            shape="circle"
            icon={<EyeOutlined />}
            onClick={() => history.push(`/product-price/view/${product.type}`)}
          />
          <Button onClick={() => handleEdit(product)} type="primary">
            Edit
          </Button>
        </Space>
      ),
    },
  ]

  return (
    <>
      <PageHeader className="site-page-header" title="Product Prices" />
      <Table
        columns={columns}
        dataSource={productPrices}
        pagination={false}
        rowKey="key"
      />
    </>
  )
}

export default ProductPrices
