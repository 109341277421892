import { Form, PageHeader, message } from 'antd'

import FormWrap from '../../components/forms/FormWrap'
import FormBlock from '../../components/forms/FormBlock'
import { createCompany } from '../../network/B2B'
import { useParams } from 'react-router-dom'

const CreateCompanies = ({ history }) => {
  const [form] = Form.useForm()
  const { storeId } = useParams()

  const handleSubmit = async (values) => {
    const payload = {
      ...values,
      store: storeId,
    }
    try {
      await createCompany(payload)
      history.push('/companies')
    } catch (error) {
      message.error(
        'An error occurred while creating the company. Please try again.'
      )
    }
  }

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Create company"
        onBack={() => history.push('/companies')}
        style={{ backgroundColor: '#fff' }}
      />
      <FormWrap
        title="create_company"
        request={handleSubmit}
        cancelText={'Cancel'}
        okText={'Save'}
        onSuccess={() => history.push('/companies')}
        invalidate={['getCompanies']}
      >
        <div className="section-from-vertical">
          <FormBlock form={form} formTemplate={UniformDescriptionForm} />
          <FormBlock form={form} formTemplate={ProductsForm} />
        </div>
      </FormWrap>
    </>
  )
}

export default CreateCompanies

export const UniformDescriptionForm = {
  title: 'Description',
  key: [],
  sections: [
    {
      columns: 4,
      fields: [
        {
          label: 'Name',
          isRequired: true,
          key: ['name'],
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
        {
          label: 'City',
          isRequired: true,
          key: ['city'],
          pos: {
            col: 1,
            row: 2,
            size: 1,
          },
        },
        {
          label: 'Country',
          isRequired: true,
          key: ['country'],
          pos: {
            col: 2,
            row: 2,
            size: 1,
          },
        },
        {
          label: 'Zip Code',
          isRequired: true,
          key: ['zipCode'],
          pos: {
            col: 3,
            row: 2,
            size: 1,
          },
        },
        {
          label: 'Address',
          type: 'text',
          isRequired: true,
          key: ['address'],
          pos: {
            col: 1,
            row: 3,
            size: 2,
          },
        },
        {
          label: 'store',
          isRequired: true,
          isHiding: (values, name) => true,
          key: ['store'],
          pos: {
            col: 2,
            row: 2,
            size: 1,
          },
        },
      ],
    },
  ],
}

export const ProductsForm = {
  title: 'Themes collection',
  sections: [
    {
      canAdd: true,
      key: ['themes'],
      title: 'Theme collection',
      addSuffix: 'number',
      columns: 3,
      // noDivider: true,
      deleteBtn: { label: 'Delete', style: 'title' },
      addBtn: { label: 'Add theme', style: 'primary' },
      fields: [
        {
          label: 'Name',
          key: ['name'],
          isRequired: true,
          pos: {
            col: 1,
            row: 1,
            size: 1,
          },
        },
      ],
      sections: [
        {
          canAdd: true,
          key: ['roles'],
          // title: 'Role',
          // addSuffix: 'number',
          defaultOpen: true,
          columns: 6,
          noDivider: true,
          deleteBtn: {
            label: 'Delete',
            style: 'line',
            pos: {
              col: 2,
              row: 1,
              size: 1,
            },
          },
          addBtn: {
            label: 'Add position',
            style: 'dashed',
          },
          fields: [
            {
              label: 'Position',
              key: [],
              isRequired: true,
              pos: {
                col: 1,
                row: 1,
                size: 1,
              },
            },
          ],
        },
      ],
    },
  ],
}
